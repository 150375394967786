import React from "react";
import "../assets/css/loading-spinner.css";

export default function LoadingOverlay({ active, text, children }) {
  return (
    <div>
      <div className={active ? "spinner-container" : ""}>
        {active && (
          <div>
            <div className={"loading-spinner"}></div>
            <div className={"loading-text"}>{text}</div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
