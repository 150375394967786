import React, { useEffect } from "react";
import EditDealerDataPanel from "../../../dealerdata/EditDealerDataPanel";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import useScbForm from "../../../utils/form/ScbFormHook";
import { saveParticipationOffer } from "../../../backend/AktionenService";
import { formatDateForSave } from "../../../utils/FormatUtils";

const PromotionDealerDataStep = ({
  navigateToPreviousStep,
  navigateToNextStep,
  nrOfOffer,
}) => {
  const { nrOfOffers, contract, readonly } = useScbFormField(
    "nrOfOffers",
    "contract",
    "readonly"
  );

  const { configureForm, submitForm, addFormError } = useScbForm(
    "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal."
  );

  const saveOffers = async (formData) => {
    const offer1 = {
      id: formData.id1,
      dealerName: formData.dealerName,
      dealerStreetAndNr: formData.dealerStreetAndNr,
      dealerZipCode: formData.dealerZipCode,
      dealerCity: formData.dealerCity,
      dealerEmail: formData.dealerEmail,
      dealerTelephoneNr: formData.dealerTelephoneNr,
      dealerWebPage: formData.dealerWebPage,
      dealerLogo: formData.dealerLogo,
      dealerLogoPath: formData.dealerLogoPath,
      carName: formData.carName1,
      registrationDate: formatDateForSave(formData.registrationDate1),
      milage: formData.milage1,
      price: formData.price1,
      durationOfValidity: formatDateForSave(formData.durationOfValidity1),
      extraEquipment: formData.extraEquipment1,
      originalPicture: formData.originalPicture1,
      picture: formData.picture1,
      picturePath: formData.picturePath1,
      financingCalculation: formData.financingCalculation1,
    };

    if (nrOfOffers === 2) {
      const offer2 = {
        id: formData.id2,
        dealerName: formData.dealerName,
        dealerStreetAndNr: formData.dealerStreetAndNr,
        dealerZipCode: formData.dealerZipCod,
        dealerCity: formData.dealerCity,
        dealerEmail: formData.dealerEmail,
        dealerTelephoneNr: formData.dealerTelephoneNr,
        dealerWebPage: formData.dealerWebPage,
        dealerLogo: formData.dealerLogo,
        dealerLogoPath: formData.dealerLogoPath,
        carName: formData.carName2,
        registrationDate: formatDateForSave(formData.registrationDate2),
        milage: formData.milage2,
        price: formData.price2,
        durationOfValidity: formatDateForSave(formData.durationOfValidity2),
        extraEquipment: formData.extraEquipment2,
        originalPicture: formData.originalPicture2,
        picture: formData.picture2,
        picturePath: formData.picturePath2,
        financingCalculation: formData.financingCalculation2,
      };

      await saveParticipationOffer(contract.number, [offer1, offer2]);
    } else {
      await saveParticipationOffer(contract.number, [offer1]);
    }
  };

  const handleSaveError = (err) => {
    addFormError(
      "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal."
    );
  };
  useEffect(() => {
    configureForm(saveOffers, handleSaveError);
  }, [contract]);

  return (
    <>
      <div
        className="row no-gutters pt-2 pb-2"
        style={{ color: "gray", fontSize: "10px" }}
      >
        Ihre hier durchgeführten Änderungen werden individuell für dieses
        Angebot in das Kundenmailing eingedruckt. Ihre Autohaus-Stammdaten
        werden dadurch nicht verändert. Klicken Sie auf „Meine Daten“ im Menü am
        oberen Bildschirmrand, um die voreingestellten Daten Ihres Autohauses
        dauerhaft zu ändern.
      </div>
      <EditDealerDataPanel />
      <div className="row pt-3 bottom-row">
        <div className="col">
          <button
            type="submit"
            className="scb_button"
            onClick={() => navigateToPreviousStep(nrOfOffer)}
          >
            Zurück
          </button>
        </div>
        {!readonly && (
          <div className="col d-flex justify-content-end">
            <button
              type="submit"
              className="scb_button"
              onClick={() => submitForm()}
            >
              Speichern
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PromotionDealerDataStep;
