import { postToBackend, getFromBackend } from "../utils/ServiceUtils";
import axios from "axios";

export const loadUserProfile = async (user) => {
  return await getFromBackend(
    `/user/profile?user=${user}`
  );
};
export const loadUserInformation = async (user) => {
  return await getFromBackend(
    `/user/information?user=${user}`
  );
};
export const markAsAGBAccepted = async (userProfile) => {
  return await postToBackend(
    `/user/agbAccepted`,
    userProfile
  );
};
export const readEnv = async () => {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENV_URL);
    return result ? result.data : null;
};
