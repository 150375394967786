import React from 'react';
import { Link } from 'react-router-dom';
import PromotionsContractList from "./list/PromotionsContractList";

const PromotionsFrame = ({active}) => {
    return (
      <>
        <div className="pt-3">
          <ul className="nav nav-tabs">
            <li className={`nav-item`}>
              <Link
                className={`${active === "1" ? "active" : ""} nav-link title`}
                to="/aktionen/uebersicht"
              >
                Übersicht
              </Link>
            </li>
            <li className={`nav-item`}>
              <Link
                className={`${active === "2" ? "active" : ""} nav-link title`}
                to="/aktionen/archiv"
              >
                Archiv
              </Link>
            </li>
          </ul>
        </div>
        {active === "1" && <PromotionsContractList />}
        {active === "2" && <PromotionsContractList archive />}
      </>
    );
};

export default PromotionsFrame;