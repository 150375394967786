import React from "react";
import WizardStep from "../../../utils/components/WizardStep";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";

const ParticipationWizardSteps = ({ activeStepNr }) => {
  const {
    readonly
  } = useScbFormField(
    "readonly"
  );
  return (
      <div className="row pl-3 step-row mb-3">
        <WizardStep title="Teilnahme" active={activeStepNr === "1"} />
        <WizardStep title="Meine Daten" active={activeStepNr === "2"} />
      </div>
  );
};


export default ParticipationWizardSteps;
