import { useCallback, useContext } from "react";
import { get, set } from "lodash";
import { ScbFormContext } from "./ScbFormProvider";

const useScbForm = (submitErrorMessage = "Error") => {
  const {
    addError,
    resetErrors,
    markSuccess,
    setSuccessLabel,
    intl,
    setBlocking,
    addInfo,
    resetInfos,
    formData,
    setFormData,
    submitCallback,
    setSubmitCallback,
    submitErrorCallback,
    setSubmitErrorCallback,
    fields,
    setFields,
    fieldValidationErrors,
    setFieldValidationErrors,
    isSubmitted,
    setIsSubmitted,
    setIsSuccess,
  } = useContext(ScbFormContext);

  const configureForm = (paramSubmitCallback, paramErrorCallback) => {
    setSubmitErrorCallback(() => paramErrorCallback);
    setSubmitCallback(() => paramSubmitCallback);
    resetAll();
  };
  const resetFormData = () => {
    setFormData({});
  };
  const resetValidators = ()=> {
    setFields([]);
  }

  const resetAll = () => {
    setFieldValidationErrors([]);
    resetFormErrors();
    resetFormInfos();
  };
  const addFormError = (error, values) => {
    addError(error, values);
  };
  const addFormInfo = (info, values) => {
    addInfo(info, values);
  };
  const resetFormInfos = () => {
    resetInfos();
  };
  const resetFormErrors = () => {
    resetErrors();
  };
  const markFormSuccess = () => {
    markSuccess();
  };
  const addValidator = (fieldName, validationFunction) => {
    setFields((oldFields) => [...oldFields, { fieldName, validationFunction }]);
  };
  const addValidators = (fieldName, ...validationFunctions) => {
    validationFunctions.forEach((vf) => addValidator(fieldName, vf));
  };

  const getErrors = (fieldName) => {
    return fieldValidationErrors
      .filter((fvf) => fvf.fieldName === fieldName)
      .map((fvf) => fvf.error);
  };

  const getTranslatedErrors = (fieldName) => {
    return getErrors(fieldName)?.map((msgId) =>
      intl.formatMessage({ id: msgId })
    );
  };

  const validate = (additionalData, ...fieldsParam) => {
    const localValidationErrors = [];
    const fieldsToValidate =
      fieldsParam.length > 0
        ? fields.filter((field) => fieldsParam.includes(field.fieldName))
        : fields;
    fieldsToValidate.forEach((field) => {
      const valueToValidate = get(formData, field.fieldName);
      const validationResult = field["validationFunction"](
        valueToValidate,
        additionalData
      );
      if (validationResult) {
        localValidationErrors.push({
          fieldName: field.fieldName,
          error: validationResult,
        });
      }
    });
    setFieldValidationErrors(localValidationErrors);
    return localValidationErrors;
  };

  const setValue = (fieldName, value) => {
    setFormData((oldFormData) => set({ ...oldFormData }, fieldName, value));
  };

  const getValue = (fieldName) => {
    return get(formData, fieldName);
  };

  const submitForm = async (additionalData) => {
    resetAll();
    const errors = validate(additionalData);
    if ((!errors || errors.length === 0) && submitCallback) {
      setBlocking(true);
      try {
        await submitCallback(formData);
        setIsSubmitted(true);
        setIsSuccess(true);
      } catch (err) {
        console.error(err)
        if (submitErrorCallback) {
          submitErrorCallback(err, formData);
        } else addFormError(submitErrorMessage);
      } finally {
        setBlocking(false);
      }
    }
  };
  
  return {
    configureForm,
    addValidators,
    setValue: useCallback(setValue, [formData]),
    getValue: useCallback(getValue, [formData]),
    submitForm,
    getErrors,
    addFormError,
    addFormInfo,
    resetFormErrors,
    resetFormInfos,
    markFormSuccess,
    getTranslatedErrors,
    setBlocking,
    validate,
    resetAll,
    resetFormData,
    resetValidators,
    isSubmitted,
    setIsSubmitted,
    setSuccessLabel,
  };
};

export default useScbForm;
