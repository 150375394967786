import React from "react";
import WizardStep from "../utils/components/WizardStep";
import "./css/offer_wizard.css";
import useScbFormField from "../utils/form/ScbFormFieldHook";

const OfferWizardSteps = () => {
  
  const { activeStepNr,category } = useScbFormField("activeStepNr","category");
  return (
    <div>
      <div className="row no-gutters step-row mb-3">
        <WizardStep title="Angebot" active={activeStepNr === 1} />
        {category == 1 && <WizardStep title="Finanzierung" active={activeStepNr === 2} />}
        <WizardStep title="Meine Daten" active={activeStepNr === 3} />
        <WizardStep title="Vorschau" active={activeStepNr === 4} />
      </div>
    </div>
  );
};

export default OfferWizardSteps;
