import React, { useContext } from "react";
import { markAsAGBAccepted } from "../backend/UserService";
import { UserProfile } from "../backend/domain";

import { LoginContext } from "../DataStore";
import moment from "moment";

const AcceptAGB = ({ userNumber, handleAGBsAccepted }) => {
  const {loginSession} = useContext(LoginContext);

  const handleAccept = e => {
    markAsAGBAccepted(
      new UserProfile(userNumber, moment(new Date()).format("DD.MM.YYYY")),
      loginSession.token
    )
      .then(res => {
        handleAGBsAccepted();
      })
      .catch(error => {});
  };

  return (
    <div className="container h-100">
      <div className="row align-items-center h-100">
        <div className="col-12 mx-auto my-auto">
          <div className="row mt-3">
            <div className="col-auto" style={{ fontSize: "12px" }}>
              <h3>Nutzungsbedingungen</h3>
              <p>
                Für die Nutzung des Tools hat der Händler die nachfolgenden
                Bestimmungen sorgfältig durchzulesen und mittels Klicken des
                Buttons am Ende der Nutzungsbedingungen vollinhaltlich und
                ausdrücklich zu akzeptieren, andernfalls kann dem Händler kein
                Zugriff auf dieses Tool eingeräumt werden.
              </p>
              <p>
                Das gegenständliche Tool dient dazu unsere Kunden über die
                Möglichkeiten zu Vertragsende eines Finanzierungsvertrages zu
                informieren und bietet dem Händler in diesem Rahmen die
                Möglichkeit ein individuelles Angebot für ein Neufahrzeug an den
                Kunden zu senden.
              </p>
              <p>
                Dieses Service wird dem Händler von der Santander Consumer Bank
                GmbH freiwillig zur Verfügung gestellt. Die Santander Consumer
                Bank GmbH behält sich daher das Recht vor, die Nutzung und den
                Zugriff auf das Tool jederzeit - aus welchem Grund auch immer -
                zu widerrufen. Der Händler hat sohin keinen Rechtsanspruch auf
                Nutzung dieses Tools.
              </p>
              <p>
                Der Händler kann, in dem ihm zur Verfügung gestellten Ausmaß
                eigene Texte zur Beschreibung des Angebotes und der Fahrzeuge
                erstellen und Bilder hochladen. Er ist für die Richtigkeit und
                die Rechtmäßigkeit des Inhaltes allein verantwortlich und hält
                die Santander Consumer Bank GmbH diesbezüglich schad- und
                klaglos. Der Händler darf sich dabei nur seinem eigenem Inhalts
                bedienen und hat sämtliche Rechtsvorschriften (insbesondere
                Urheberrecht etc.) zu beachten.
              </p>
              <p>
                Die Angebote für Produkte und Dienstleistungen richten sich
                ausschließlich an Verbraucher, die ihren Wohnsitz in Österreich
                haben. Sie sind daher in deutscher Sprache zu verfassen.
              </p>
              <h5>Datenschutz:</h5>
              <p>
                Dem Händler werden - wie bereits oben ausgeführt - zwecks
                Kontaktierung der Kunden zur Unterbreitung eines neuen
                Finanzierungsangebots, folgende personenbezogenen Daten der
                Kunden zur Verfügung gestellt: Vorname, Nachname, Adresse,
                Kontaktdaten (Email, Telefonnummer) und Vertragsdaten
                (Vertragsnummer, Produkt, Fahrzeug, Fahrgestellnummer, Laufzeit,
                Kaufpreis, Anzahlung, Rate, Restwert, Vertragsende).
              </p>
              <p>
                Dem Händler wird weiters zur Kenntnis gebracht, ob Kunden über
                eine Werbesperre verfügen. Bei Kunden mit Werbesperre ist es dem
                Händler untersagt, diese zu Werbezwecken mittels Postversand,
                per Telefon oder per Email zu kontaktieren. Dem Händler wird
                über das Tool lediglich die Möglichkeit eingeräumt einen solchen
                Kunden zu servicieren, falls dieser sich von sich aus an den
                Händler zwecks Erstellung eines Angebots wenden sollte. Bei
                Kunden mit Werbesperre wird daher dem Händler technisch nicht
                die Möglichkeit eingeräumt Finanzierungsangebote zu erstellen.
                Ausschließlich für den Zweck der Servicierung jener Kunden mit
                Werbesperre werden dem Händler folgende personenbezogenen Daten
                der Kunden zur Verfügung gestellt: Vorname, Nachname und
                Vertragsdaten (Vertragsnummer, Produkt, Fahrzeug,
                Fahrgestellnummer, Laufzeit, Kaufpreis, Anzahlung, Rate,
                Restwert, Vertragsende).
              </p>
              <p>
                Die personenbezogenen Daten der Kunden werden 6 Monate vor
                geplantem Finanzierungsende im Tool zur Verfügung gestellt und
                werden im Monat des geplanten Finanzierungsendes wieder
                entfernt. Bei Entfernung der Daten aus dem Tool ist der Händler
                verpflichtet, diesbezügliche Ausdrucke, handschriftliche
                Aufzeichnungen, elektronisch gespeicherte Screenshots etc. der
                bereits aus dem Tool entfernten personenbezogenen Daten
                unwiderruflich zu vernichten.
              </p>
              <p>
                Im Übrigen gelten die Bestimmungen des Kooperationsvertrages
                bzw. der Zusatzvereinbarung zum Kooperationsvertrag,
                insbesondere hinsichtlich einzuhaltender
                Sicherheitsvorkehrungen, Überbindungsverpflichtung des Bank- und
                Datengeheimnisses an die Mitarbeiter, Beiziehung Dritter und
                Vorgehen bei Datenschutzverletzungen („data breach“).
              </p>
              <p>
                Bei Zuwiderhandeln wird dem Händler der Zugang zum Tool
                entzogen. Des Weiteren behält sich die Santander Consumer Bank
                GmbH das Recht vor, die mit dem Händler bestehende
                Kooperationsvereinbarung aufzukündigen.
              </p>
            </div>
            <div className="col">
              <button className="scb_button" onClick={handleAccept}>
                Akzeptieren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AcceptAGB;
