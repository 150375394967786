import React, { useState, useEffect } from "react";
import FinancingInputDataPanel from "./FinancingInputDataPanel";
import FinancialCalculationResult from "./FinancialCalculationResult";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import { doFinancingCalculation } from "../../../backend/FinancingService";
import useScbForm from "../../../utils/form/ScbFormHook";
import FinancingResultPreviewPanel from "./FinancingResultPreviewPanel";

const FinancinStep = ({
  navigateToNextStep,
  navigateToPreviousStep,
  nrOfOffer,
}) => {
  const {
    [`financingCalculation${nrOfOffer ? nrOfOffer : ""}`]: financingCalculation,
    [`setFinancingCalculation${
      nrOfOffer ? nrOfOffer : ""
    }`]: setFinancingCalculation,
    readonly,

  } = useScbFormField(
    `financingCalculation${nrOfOffer ? nrOfOffer : ""}`,
    "readonly"
  );
  const { setBlocking, resetFormErrors, addFormError } = useScbForm();
  const [calcResult, setCalcResult] = useState(financingCalculation);
  const [showCalculationInput, setShowCalculationInput] = useState(
    !financingCalculation
  );
  const onCalculate = async (calcData, selectedPosAccount, selectedDealer) => {
    setBlocking(true);
    resetFormErrors();
    try {
      const calcResult = await doFinancingCalculation(
        {
          ...calcData,
          eurotaxCode: calcData.selectedEurotaxType,
        },
        selectedPosAccount,
        selectedDealer
      );
      // calculate on server
      // set result in state
      const fullCalcResult = {
        ...calcResult,
        productType: calcData.productType,
        objektart: calcData.objektart,
        selectedMarke: calcData.selectedMarke,
        selectedModel: calcData.selectedModel,
        selectedPosAccount,
        selectedEurotaxType: calcData.selectedEurotaxType,
        selectedMarkeName: calcData.selectedMarkeName,
        selectedModelName: calcData.selectedModelName,
        selectedEurotaxTypeName: calcData.selectedEurotaxTypeName,
        productId:calcData.productId,
        vehicleDescription: calcData.vehicleDescription,
        price: calcData.price,
        accessoriesPrice: calcData.accessoriesPrice,
        kmPerYear: calcData.kmPerYear,
        initialPayment: calcData.initialPayment,
        depot: calcData.depot,
        residualValue: calcData.residualValue,
        duration: calcData.duration,
        repaymentFreeDuration: calcData.repaymentFreeDuration,
        varianteId: calcData.varianteId,
        anlageklasseId: calcData.anlageklasseId,
      };
      setCalcResult(fullCalcResult);
    } catch (err) {
      const errors = err.response.data;
      
      addFormError(`Kalkulation ist fehlgeschlagen.${errors? errors.join(" ") :""}`);
    } finally {
      setBlocking(false);
    }
  };

  useEffect(() => {
    setCalcResult(financingCalculation);
  }, [financingCalculation]);

  return (
    <div className="pl-3">
      <div className="row">
        {!readonly && showCalculationInput && (
          <div className={`col ${calcResult ? "financing-data-input-column" : ""}`}>
            <h5 className="pl-2">Berechnungsdaten</h5>
            <FinancingInputDataPanel
              nrOfOffer={nrOfOffer}
              readonly={readonly}
              onCalculate={onCalculate}
            />
          </div>
        )}
        {calcResult && (
          <div className="col-6">
            <div className="row">
              <div className="col">
                <h5 className="pl-2">Finanzierung</h5>
              </div>
            </div>
            <FinancingResultPreviewPanel calcResult={calcResult} nrOfOffer={nrOfOffer}/>
          </div>
        )}
      </div>
      <div className="row pt-3 bottom-row">
        <div className="col-auto">
          <button
            type="submit"
            className="scb_button"
            onClick={navigateToPreviousStep}
          >
            Zurück
          </button>
        </div>
        {!readonly && (
          <div className="col-auto">
            <button
              type="submit"
              className="scb_button"
              onClick={() => {
                setShowCalculationInput(true);
              }}
              disabled={showCalculationInput}
            >
              Neu berechnen
            </button>
          </div>
        )}
        {!readonly && (
          <div className="col-auto">
            <button
              type="submit"
              className="scb_button"
              onClick={() => {
                setFinancingCalculation();
                navigateToNextStep();
              }}
            >
              Weiter ohne Finanzierung
            </button>
          </div>
        )}
        <div className="col d-flex justify-content-end">
          <button
            type="submit"
            className="scb_button"
            onClick={() => {
              setFinancingCalculation(calcResult);
              navigateToNextStep();
            }}
            disabled={!calcResult}
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinancinStep;
