import React from "react";

const SelectWithLabel = ({
  id,
  label,
  options = [],
  disabled = false,
  placeholder,
  value,
  onChange,
  labelClass,
  selectClass,
}) => {
  return (
    <div className="row pt-1 financing-field">
      <div className={labelClass ?? "col-3 financing-label"}>
        <label htmlFor={id}>{label}</label>
      </div>
      <div className={selectClass ?? "col"}>
        <select
          className="financing-select"
          disabled={disabled}
          id={id}
          value={value ?? ""}
          onChange={onChange}
        >
          {placeholder && <option value="">{placeholder}</option> }
          {options
            ? options.map((option, idx) => {
                return (
                  <option key={idx} value={option.value}>
                    {option.text}
                  </option>
                );
              })
            : ""}
        </select>
      </div>
    </div>
  );
};

export default SelectWithLabel;