import React, { useEffect, useContext } from "react";
import CustomerData from "./info-panels/CustomerData";
import {
  loadContractService,
  loadCSVLineForContract,
} from "../backend/ContractService";
import ContractData from "./info-panels/ContractData";
import EditOfferTitle from "./EditOfferTitle";
import EditOfferStep from "./steps/EditOfferStep";
import OfferPreviewStep from "./steps/OfferPreviewStep";
import OfferDealerDataStep from "./steps/OfferDealerDataStep";
import { saveContractOffer } from "../backend/ContractService";
import { LoginContext } from "../DataStore";
import {
  formatDateForSave,
} from "../utils/FormatUtils";
import CarData from "./info-panels/CarData";
import { downloadCSVFile } from "../utils/Utils";
import moment from "moment";
import { csvDownloadHeader } from "../contractslist/ContractList";
import MailingInfoPanel from "./info-panels/MailingInfoPanel";
import FinancinStep from "./steps/financing/FinancinStep";
import { withScbForm } from "../utils/form/ScbFormProvider";
import { useParams } from "react-router-dom";
import useScbForm from "../utils/form/ScbFormHook";
import useScbFormField from "../utils/form/ScbFormFieldHook";
import OfferWizardSteps from "./OfferWizardSteps";

const EditOffer = ({ }) => {
  const { loginSession, selectedDealer } = useContext(LoginContext);

  const {
    readonly,
    setReadonly,
    currentStep,
    setCurrentStep,
    contract,
    setContract,
    activeStepNr,
    setActiveStepNr,
    category,
    setCategory
  } = useScbFormField("readonly", "currentStep", "contract", "activeStepNr","category");
  const {
    setId,
    setCarName,
    setRegistrationDate,
    setMilage,
    setPrice,
    setDurationOfValidity,
    setExtraEquipment,
    setOriginalPicture,
    setPicture,
    setPicturePath,
    setDealerNr,
    setDealerName,
    setDealerStreetAndNr,
    setDealerZipCode,
    setDealerCity,
    setDealerEmail,
    setDealerWebPage,
    setDealerTelephoneNr,
    setDealerLogo,
    setDealerLogoPath,
    setFinancingCalculation,
  } = useScbFormField(
    "id",
    "carName",
    "registrationDate",
    "milage",
    "price",
    "durationOfValidity",
    "extraEquipment",
    "originalPicture",
    "picture",
    "picturePath",
    "dealerNr",
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo",
    "dealerLogoPath",
    "financingCalculation"
  );

  const {
    addValidators,
    validate,
    configureForm,
    setSuccessLabel,
    setBlocking,
    addFormError,
  } = useScbForm();

  // take the contract number from the link parameter
  let { contractNr } = useParams();

  const loadContract = async () => {
    setBlocking(true);
    try {
      // load the contract with the number
      const c = await loadContractService(contractNr);
      // initialize set form data
      setReadonly(c.archived);
      setContract(c);
      setCategory(getCategory(c))
      // if not set than create a new one
      if (c.offer) {        
        setId(c.offer.id);
        setCarName(c.offer.carName);
        setRegistrationDate(c.offer.registrationDate ? moment(c.offer.registrationDate, "DD.MM.YYYY").toDate() : null);
        setMilage(c.offer.milage);
        setPrice(c.offer.price);
        setDurationOfValidity(c.offer.durationOfValidity ? moment(c.offer.durationOfValidity, "DD.MM.YYYY").toDate() : null);
        setExtraEquipment(c.offer.extraEquipment);
        setOriginalPicture(c.offer.originalPicture);
        setPicture(c.offer.picture);
        setPicturePath(c.offer.picturePath);
        setDealerNr(c.offer.dealerNumber);
        setDealerName(c.offer.dealerName);
        setDealerStreetAndNr(c.offer.dealerStreetAndNr);
        setDealerZipCode(c.offer.dealerZipCode);
        setDealerCity(c.offer.dealerCity);
        setDealerEmail(c.offer.dealerEmail);
        setDealerWebPage(c.offer.dealerWebPage);
        setDealerTelephoneNr(c.offer.dealerTelephoneNr);
        setDealerLogo(c.offer.dealerLogo);
        setDealerLogoPath(c.offer.dealerLogoPath);
        setFinancingCalculation(c.offer.financingCalculation);
      }
    } catch (err) {
      addFormError("Ein unerwartete Fehler ist aufgetretten.");
    } finally {
      setBlocking(false);
    }
  };

  const saveOffer = async (formData) => {
    const offer = {
      id: formData.id,
      dealerNumber: selectedDealer,
      dealerName: formData.dealerName,
      dealerStreetAndNr: formData.dealerStreetAndNr,
      dealerZipCode: formData.dealerZipCode,
      dealerCity: formData.dealerCity,
      dealerEmail: formData.dealerEmail,
      dealerTelephoneNr: formData.dealerTelephoneNr,
      dealerWebPage: formData.dealerWebPage,
      dealerLogo: formData.dealerLogo,
      dealerLogoPath: formData.dealerLogoPath,
      carName: formData.carName,
      registrationDate: formatDateForSave(formData.registrationDate),
      milage: formData.milage,
      price: formData.price,
      durationOfValidity: formatDateForSave(formData.durationOfValidity),
      extraEquipment: formData.extraEquipment,
      originalPicture: formData.originalPicture,
      picture: formData.picture,
      picturePath: formData.picturePath,
      financingCalculation: formData.financingCalculation,
    };
    await saveContractOffer(contractNr, offer);
  };

  const handleSaveError = (err) => {
    addFormError(
      "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal."
    );
  };

  useEffect(() => {
    configureForm(saveOffer, handleSaveError);
    setSuccessLabel("Das Angebot wurde erfolgreich gespeichert.");

    setActiveStepNr(1);

    loadContract();
    setCurrentStep("Angebot");
  }, []);

  const navigateToStep = (step, mustValidate = true) => {
    if (readonly || !mustValidate || validate().length === 0) {
      setCurrentStep(step);
    } 
  };

  const getCategory = (c) => {
    return c.customer.blockedForAdvertising
      ? 3
      : c.offerPossible
      ? 1
      : 2;
  };

  const doDownloadOffer = async () => {
    // add header line
    var csv = csvDownloadHeader;
    // add one row for each
    try {
      const csvLine = await loadCSVLineForContract(
        contract.number,
        loginSession.token
      );
      csv += csvLine;
    } catch (error) {}
    downloadCSVFile(`scb_offer_${contract.number}.csv`, csv);
  };

  return contract ? (
    <div className="pt-2 edit_offer">
      <EditOfferTitle />

      <div className="row pt-2 no-gutters edit_offer_panel">
        <div className="col-3">
          <CustomerData />
          <CarData />
          <ContractData/>
          <MailingInfoPanel/>
        </div>
        <div className="col ml-3 pl-3 edit_panel">
          <OfferWizardSteps/>

          {currentStep === "Angebot" && (
            <EditOfferStep
              navigateToNextStep={() => {
                if(category === 1) {
                  setActiveStepNr(2);
                  navigateToStep("Financing");
                } else {
                  setActiveStepNr(3);
                  navigateToStep("DealerData", false);
                }
              }}
            />
          )}
          {currentStep === "Financing" && (
            <FinancinStep
              navigateToNextStep={() => {
                setActiveStepNr(3);
                navigateToStep("DealerData");
              }}
              navigateToPreviousStep={() => {
                setActiveStepNr(1);
                navigateToStep("Angebot", false);
              }}
            />
          )}
          {currentStep === "DealerData" && (
            <OfferDealerDataStep
              navigateToNextStep={() => {
                setActiveStepNr(4);
                navigateToStep("Preview", false);
              }}
              navigateToPreviousStep={() => {
                setActiveStepNr(2);
                if(category === 1) {
                  setActiveStepNr(2);
                  navigateToStep("Financing");
                } else {
                  setActiveStepNr(1);
                  navigateToStep("Angebot", false);
                }
              }}
            />
          )}
          {currentStep === "Preview" && (
            <OfferPreviewStep
              downloadOffer={doDownloadOffer}
              navigateToPreviousStep={() => {
                setActiveStepNr(3);
                navigateToStep("DealerData", false);
              }}
              readonly={readonly}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    // create loading page!
    <div>LOADING</div>
  );
};

export default withScbForm(EditOffer);
