import moment from "moment";

// formats a date in like this: DD.MM.YYYY
export const formatDate = (date) => {
  return date instanceof Date
    ? `${padTo2(date.getDate())}.${padTo2(
        date.getMonth() + 1
      )}.${date.getFullYear()}`
    : date
    ? formatDate(moment(date, "DD.MM.YYYY").toDate())
    : null;
};

// format a decimal amount in euro to be printed
export const formatAmount = (amount) => {
  return !amount 
    ? ""
    : `${amount.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
};

export const formatFullName = (firstname, lastname) => {
  return `${firstname} ${lastname}`;
};
const padTo2 = function (number) {
  return ("0" + number).slice(-2);
};
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getLastDayOdPreviousMonth = () => {
 const endOfLastMonth = moment().date(1).subtract(1, "month").endOf("month");
 return formatDate(endOfLastMonth);
};

export const getLastDayOdPreviousQuarter = () => {
  const endOfLasQuarter = moment()
    .date(1)
    .subtract(1, "quarter")
    .endOf("quarter");
  return formatDate(endOfLasQuarter);
};

  export const getCondition = (condition) => {
    switch (condition) {
      case "NEW":
        return "Neu";
      case "USED":
        return "Gebraucht";
      default:
        return "Unbekant";
    }
  };

  export const formatDateForSave = (value)=> {
    return value ? moment(value).format("DD.MM.YYYY") : "";
  }