import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import "./assets/css/components.css";
import "./assets/css/global.css";
import MainWindow from "./frames/MainWindow";
import DataStore from "./DataStore";
import Keycloak from "keycloak-js";
import AcceptAGB from "./agb/AcceptAGB";
import {
  loadUserProfile,
  loadUserInformation,
  readEnv
} from "./backend/UserService";
import {
  loadAllDealersForDevisionHead,
  loadAllDealers
} from "./backend/DealerService";

const App = ({ setOverlayActive }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [agbsAccepted, setAgbsAccepted] = useState(false);

  // check if user is allowed to login
  const isUserAllowedToLogin = userInfo => {
    return (
      (userInfo.kfz || userInfo.gbl || userInfo.scbadmin) &&
      userInfo.dealerInformations !== null &&
      userInfo.dealerInformations.length > 0
    );
  };

  const getKeycloakConfig = env => {
    return {
      realm: env.keycloak.realm,
      authServerUrl: env.keycloak.url,
      url: env.keycloak.url,
      "ssl-required": "external",
      clientId: env.keycloak["client-id"],
      "verify-token-audience": true,
      credentials: {
        secret: env.keycloak["client-secret"]
      },
      "confidential-port": 0,
      "policy-enforcer": {},
      "enable-cors": true
    };
  };
  const getAccountTypes = (userInfo,dealerNumber) => {
    const dealerInfo = userInfo?.dealerInformations?.find(
      (dealer) => dealer.customerNumber === dealerNumber
    );
    return dealerInfo ? dealerInfo.eposAccounts : [];
  }

  // use the user info to fill the list with allowed users
  const mapDealersForUser = async (userName, userInfo, token) => {
    // for gbl load all dealers from local DB not dolphin
    const dealers = [];
    if (userInfo.gbl || userInfo.scbadmin) {
      const loadedDealers = userInfo.scbadmin
      ? await loadAllDealers()
      : await loadAllDealersForDevisionHead(userName);
      // put each dealer in the user info
      loadedDealers.map(dealer => {
        //dealers.number dealers.name
        dealers.push({
          name: dealer.name,
          number: dealer.number,
          accountTypes: getAccountTypes(userInfo,dealer.number),
        });
      });
    }
    // for normal users use what was loaded in the user information
    else if (userInfo.dealerInformations !== null)
      // add dealers for normal user
      userInfo.dealerInformations.map(dealer => {
        //dealers.number dealers.name
        dealers.push({
          name: dealer.customerLastName,
          number: dealer.customerNumber,
          accountTypes: dealer.eposAccounts,
        });
      });
    // set the dealers in user info object
    userInfo.dealers = dealers;
    return userInfo;
  };

  const doPostLogin = async keyclk => {
     window.keycloak = keyclk;
    // load user information from dp-authorization-service->dolphin
    const userInformation = await loadUserInformation(
      keyclk.tokenParsed.preferred_username
    );
    // check if the user is allowed to login
    if (isUserAllowedToLogin(userInformation)) {
      // fill the list with dealers
      await mapDealersForUser(
        keyclk.tokenParsed.preferred_username,
        userInformation,
        () => keyclk.token
      );
      setUserInfo(userInformation);
           // load user profile
      const userProfile = await loadUserProfile(
        keyclk.tokenParsed.preferred_username,
        () => keyclk.token
      );
      setAgbsAccepted(userProfile.agbAcceptedOn);
    } else keyclk.logout();
  };

  // init keycloak session
  const initSession = async () => {    
    const envVariables = await readEnv();   
    const keyclk = Keycloak(getKeycloakConfig(envVariables));
    keyclk
      .init({ onLoad: "login-required", checkLoginIframe: false })
      .success((loginResult) => {
        if (loginResult) {
          doPostLogin(keyclk)
            .then((res) => {
              setOverlayActive(false);
            })
            .catch((error) => {
              keyclk.logout();
            });
        }
      })
      .error((error) => {});
  };

  useEffect(() => {
    initSession();
  }, []);

  const handleAGBsAccepted = () => {
    setAgbsAccepted(true);
  };

  return (
    userInfo !== null && (
      <div className="container-fluid main-container">
        <DataStore userInfo={userInfo}>
          {agbsAccepted ? (
            <MainWindow />
          ) : (
            <AcceptAGB
              userNumber={window.keycloak.tokenParsed.preferred_username}
              handleAGBsAccepted={handleAGBsAccepted}
            ></AcceptAGB>
          )}
        </DataStore>
      </div>
    )
  );
};

export default App;
