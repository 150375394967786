import React from "react";

const PromotionsContractListHeader = ({ archive }) => {
  return (
    <div>
      {archive ? (
        ""
      ) : (
        <h5>Hier sehen Sie Ihre Kundenverträge, die an einer Aktion teilnehmen können.</h5>
      )}
    </div>
  );
};

export default PromotionsContractListHeader;
