import React from "react";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import { formatAmount } from "../../../utils/FormatUtils";
const FinancingResultPreviewPanel = ({ calcResult, nrOfOffer }) => {
  const isLeasing = () => {
    return calcResult?.productType === "LEASING";
  };

  const getAmountText = (amount) => {
    return `€ ${
      amount?.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) ?? 0
    }`;
  };
  const getFinanzierungsArt = () => {
    switch (calcResult?.productType) {
      case "LOAN":
        return "Ankaufskredit";
      case "LEASING":
        return "Leasing";
      default:
        return "";
    }
  };
  return (
    <div className="financing-result pr-3">
      <div className="row financing-resut-top-field align-items-center ml-2">
        <div className="col-6">Marke</div>
        <div className="col-6 financing-value">
          {calcResult?.selectedMarkeName?.toUpperCase()}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Modell</div>
        <div className="col-6 financing-value">
          {calcResult?.selectedModelName}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Spezifikation</div>
        <div className="col-6 financing-value">
          {calcResult?.selectedEurotaxTypeName}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Finanzierungsart</div>
        <div className="col-6 financing-value">{getFinanzierungsArt()}</div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Barzahlungspreis</div>
        <div className="col-6 financing-value">
          {getAmountText(calcResult?.price)}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Anzahlung</div>
        <div className="col-6 financing-value">
          {getAmountText(calcResult?.initialPayment)}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Restwert</div>
        <div className="col-6 financing-value">
          {getAmountText(calcResult?.residualValue)}
        </div>
      </div>
      {isLeasing() && (
        <>
          <div className="row financing-resut-normal-field align-items-center ml-2">
            <div className="col-6">Gesetzliche Rechtsgeschäftsgebühr</div>
            <div className="col-6 financing-value">
              {getAmountText(calcResult?.legalFee)}
            </div>
          </div>
          <div className="row financing-resut-normal-field align-items-center ml-2">
            <div className="col-6">Bearbeitungsgebühr</div>
            <div className="col-6 financing-value">
              {getAmountText(calcResult?.chargeFee)}
            </div>
          </div>
        </>
      )}
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Sollzinssatz p.a.</div>
        <div className="col-6 financing-value">
          {formatAmount(calcResult?.interest ?? 0)}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Effektivzinssatz p.a.</div>
        <div className="col-6 financing-value">
          {formatAmount(calcResult?.effInterest ?? 0)}
        </div>
      </div>
      {!isLeasing() && (
        <div className="row financing-resut-normal-field align-items-center ml-2">
          <div className="col-6">Gesamtkosten</div>
          <div className="col-6 financing-value">{getAmountText(calcResult?.totalLoanCosts)}</div>
        </div>
      )}
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Zu zahlender Gesamtbetrag</div>
        <div className="col-6 financing-value">
          {getAmountText(calcResult?.totalLoanAmount)}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Monatliche Rate</div>
        <div className="col-6 financing-value">
          {getAmountText(calcResult?.installment)}
        </div>
      </div>
      <div className="row financing-resut-normal-field align-items-center ml-2">
        <div className="col-6">Laufzeit</div>
        <div className="col-6 financing-value">{`${calcResult?.duration} Monate`}</div>
      </div>
    </div>
  );
};

export default FinancingResultPreviewPanel;
