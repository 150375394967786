import React from "react";

const StyledButton = ({ label, onClick }) => {
  return (
    <button
      style={{
        backgroundColor: "white",
        border: "solid 1px #ec0000",
        borderRadius: "10px",
        color: "#ec0000"
      }}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default StyledButton;
