import { postToBackend, getFromBackend } from "../utils/ServiceUtils";

export const loadDealerData = async (dealerNr) => {
  return await getFromBackend(`/dealer?number=${dealerNr}`);
};
export const loadAllDealersForDevisionHead = async (divisionHeadNumber) => {
  return await getFromBackend(
    `/dealer/division?divisionHeadNumber=${divisionHeadNumber}`
  );
};
export const loadAllDealers = async () => {
  const result = await getFromBackend(`/dealer/all`);
  return result;
};

export const loadDevisionHead = async (dealerNumber) => {
  return await getFromBackend(
    `/dealer/devisionhead?dealerNumber=${dealerNumber}`
  );
};

export const saveDealerData = async (dealer) => {
  return await postToBackend("/dealer", dealer);
};
