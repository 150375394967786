import React from 'react';
import { Link } from 'react-router-dom';
import ContractList from './ContractList';

const VertragsendeFrame = ({active}) => {
    return (
      <>
        <div className="pt-2">
          <ul className="nav nav-tabs">
            <li className={`nav-item`}>
              <Link
                className={`${active === "1" ? "active" : ""} nav-link title`}
                to="/vertragsende/uebersicht"
              >
                Übersicht
              </Link>
            </li>
            <li className={`nav-item`}>
              <Link
                className={`${active === "2" ? "active" : ""} nav-link title`}
                to="/vertragsende/archiv"
              >
                Archiv
              </Link>
            </li>
          </ul>
        </div>
        {active === "1" && <ContractList />}
        {active === "2" && <ContractList archive="true" />}
      </>
    );
};

export default VertragsendeFrame;