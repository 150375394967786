export const sortDatesAsc = (d1, d2) => {
  return (d1 > d2) - (d1 < d2);
};
export const sortDatesDesc = (d1, d2) => {
  return sortDatesAsc(d1, d2) * -1;
};
export const sortBooleanAsc = (b1, b2) => {
  return b1 > b2 ? 1 : -1;
};
export const sortBooleanDesc = (b1, b2) => {
  return sortBooleanAsc(b1, b2) * -1;
};
export const sortNumbersAsc = (n1, n2) => {
  return n1 > n2 ? 1 : n1 === n2 ? 0 : -1;
};
export const sortNumbersDesc = (n1, n2) => {
  return sortNumbersAsc(n1, n2) * -1;
};
