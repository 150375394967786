import React, { useState } from "react";
import { hasExtension } from "../Utils";
import "../../assets/css/components.css";
const ImageUploadPanel = ({
  uploadButtonLabel,
  imagePath,
  image,
  imageChanged,
  maxSize = 2000000,
  readonly,
  imageTypeName = "Logo",
}) => {
  const [error, setError] = useState(null);
  const validatePicture = (picture, picturePath) => {
    if (!hasExtension(picturePath, [".jpg", ".jpeg", ".png"])) {
      setError(`Achtung, nur JPEG oder PNG-Dateien sind erlaubt!`);
      return false;
    }
    // max 2MB
    if (picture.length > maxSize) {
      setError(
        `Achtung, das von Ihnen hochgeladene ${imageTypeName} ist zu groß. Bitte wählen Sie ein passendes Dokument zum Hochladen!`
      );
      return false;
    }

    setError(null);
    return true;
  };

  const localImageChanged = (event) => {
    event.persist();
    if (event.target.files[0]) {
      // set the image path
      var picturePath = event.target.files[0].name;
      // read the image in memory
      var reader = new FileReader();
      // add listener for when the image is loaded
      reader.onload = (event) => {
        var picture = event.target.result;
        if (!validatePicture(picture, picturePath)) return;
        imageChanged(picture, picturePath);
      };
      // load the image
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  return (
    <div className="row no-gutters image-panel">
      <div className="col">
        <div className="row no-gutters pt-2">
          <label htmlFor="uploadButton" className="upload-button">
            {uploadButtonLabel}
          </label>
          <input
            className="default-upload-button"
            type="file"
            name="picture"
            id="uploadButton"
            accept="image/jpg, image/jpeg, image/png"
            onChange={localImageChanged}
            disabled={readonly}
          />
        </div>
        {image && (
          <div className="row no-gutters">
            <label className="image-panel-path" placeholder="Foto auswählen">
              Preview: {imagePath}
            </label>
          </div>
        )}
        <div className="row no-gutters image-panel-error-message">
          <div className="col">{error}</div>
        </div>
        {image && (
          <div className="row no-gutters">
            <img className="image-panel-preview" src={image} alt="" />
          </div>
        )}
        <div className="image-panel-info-text">
          JPEG oder PNG-Dateien möglich (max. 2 MB)
        </div>
      </div>
    </div>
  );
};
export default ImageUploadPanel;
