import React from "react";
import { Link } from "react-router-dom";
import "./css/contract_list_table.css";
import { ContractListItemHelper } from "../backend/domain";
import { formatAmount, getCondition } from "../utils/FormatUtils";
import { PRODUCT_TYPE_OL } from "../utils/Constants";

const ContractListTableRow = ({
  contract,
  handleSelectForDownload,
  contractsForDownload,
  archive,
}) => {
  const {
    number,
    productType /*Produkt*/,
    endDate,
    residualValue,
    blockedForAdvertising,
    offerAvailable,
    salesPersonName,
    offerPossible,
    productName,
    condition,
  } = contract;
  const isChecked = () => {
    var tr =
      contractsForDownload && contractsForDownload.includes("" + number)
        ? "checked"
        : "";
    return tr;
  };
  const getCategory = () => {
    return contract.blockedForAdvertising ? 3 : contract.offerPossible ? 1 : 2;
  };
  const getCategoryTitle = () => {
    if (contract === null || contract === undefined) return "";
    switch (getCategory()) {
      case 1:
        return "Angebot";
      case 2:
        return "Information";
      case 3:
        return "Werbesperre";
      default:
        return "UNKNOWN!!";
    }
  };
  return (
    <tr className="table_row">
      <td>
        <input
          className="download-select"
          type="checkbox"
          value={number}
          checked={isChecked()}
          onChange={handleSelectForDownload}
        />
      </td>
      <td>
        <Link to={"/vertragsende/angebot/" + number}>
          {ContractListItemHelper.getCustomerFullName(contract)}
        </Link>
      </td>
      <td align="center">{number}</td>
      <td>{salesPersonName}</td>
      <td>
        <div>{productName}</div>
        <div className="contract-list-table-row-second-line">
          {getCondition(condition)}
        </div>
      </td>
      <td align="center">
        <div>{endDate}</div>
        {(productType !== PRODUCT_TYPE_OL) ? (
          <div className="contract-list-table-row-second-line">
            {"Restwert " + residualValue ? formatAmount(residualValue) : "0"}
          </div>
        ) : ""}
      </td>
      <td align="center">{getCategoryTitle()}</td>
      <td align="center">
        {blockedForAdvertising || !offerPossible
          ? "-"
          : offerAvailable
          ? "gespeichert"
          : archive
          ? "kein Angebot"
          : "offen"}
      </td>
    </tr>
  );
};

export default ContractListTableRow;
