import http from "./http";
const globalURLPrefix = process.env.REACT_APP_BACKEND_URL_PREFIX;
const getFullUrl = (url) => {
  return globalURLPrefix + url;
};

export const getFromBackend = async (url) => {
  const result = await http.get(getFullUrl(url), { timeout :1000000});
  return result ? result.data : null;
};
export const getFromBackendRaw = async (url) => {
  const result = await http.get(getFullUrl(url), {
    responseType: "blob",
  });
  return result;
};

export const postToBackend = async (url, data) => {
  const result = await http.post(getFullUrl(url), data);
  return result.data ? result.data : result;
};

export const deleteToBackend = async (url, data) => {
  const result = await http.delete(getFullUrl(url), data);
  return result.data;
};
