import React, { useState, useRef, useEffect } from "react";
import ValidationInputField from "../utils/components/ValidationInputField";
import NewValidationInputField from "../utils/components/new/NewValidationInputField";
import { saveMailingConfiguration } from "../backend/AdminService";

const AdminMailingConfigNewPanel = ({
  mailing,
  callbackEditFinished,
  callbackEditCanceled,
}) => {
  const [mailingId, setMailingId] = useState("");
  const [id, setId] = useState();
  const [mailingName, setMailingName] = useState("");
  const [mailingUrl, setMailingUrl] = useState("");

  const mailinIdRef = useRef();
  const mailinNameRef = useRef();
  const mailinUrlRef = useRef();

  const handleHasErrors = () => {};

  useEffect(() => {
    if (mailing) {
      setMailingId(mailing.mailingId);
      setMailingName(mailing.description);
      setMailingUrl(mailing.url);
      setId(mailing.id);
    }
  }, []);

  const saveMailing = async () => {
    // revalidate all fields
    if (
      !mailinIdRef.current.isValueValid(mailingId) ||
      !mailinNameRef.current.isValueValid(mailingName) ||
      !mailinUrlRef.current.isValueValid(mailingUrl)
    )
      return;
    // call save service
    try {
      await saveMailingConfiguration(id, mailingId, mailingName, mailingUrl);
      callbackEditFinished();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-2">
          <NewValidationInputField
            ref={mailinIdRef}
            handleHasErrors={handleHasErrors}
            name="mailingID"
            label="Id"
            defaultValue={mailingId}
            valueChanged={(value) => setMailingId(value)}
            required="true"
          />
        </div>
        <div className="col-5">
          <NewValidationInputField
            ref={mailinNameRef}
            handleHasErrors={handleHasErrors}
            name="carName"
            label="Anzeigebezeichnung"
            defaultValue={mailingName}
            valueChanged={(value) => setMailingName(value)}
            required="true"
          />
        </div>
        <div className="col">
          <NewValidationInputField
            ref={mailinUrlRef}
            handleHasErrors={handleHasErrors}
            name="Link"
            label="Url"
            defaultValue={mailingUrl}
            valueChanged={(value) => setMailingUrl(value)}
            required="true"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            className="pl-3 scb_button"
            type="button"
            onClick={() => callbackEditCanceled()}
          >
            Abrechen
          </button>
        </div>
        <div className="col-6"></div>
        <div className="col-auto">
          <button
            className="pl-3 scb_button"
            type="button"
            onClick={() => saveMailing()}
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminMailingConfigNewPanel;
