export const downloadCSVFile = (filename, data) => {
  dowloadFile(filename, data, "text/csv", true);
};
export const downloadZIPFile = (filename, data) => {
  dowloadFile(filename, data, "application/zip");
};
const dowloadFile = (filename, data, type, useUTF8_BOM = false) => {
  var blob = useUTF8_BOM
    ? new Blob(["\ufeff", data], { type: type })
    : new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const isValueSet = values => {
  for (var i = 0; i < values.length; i++) {
    if (values[i] && values[i] !== null) return true;
  }
};

export const hasExtension = (fileName, exts) => {
  return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
    fileName
  );
};
export const capital = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};