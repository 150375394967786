import React from "react";
import "./css/contract_list_table.css";
import "../assets/css/components.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerField from "../utils/components/DatePickerField";

const ContractListTableHeader = ({
  currentSortKey,
  currentSortDirection,
  sortBy,
  handleFilterChanged,
  handleEndDateFilterChanged,
  dateFilter,
  handleDownloadAllChange,
  contractsForDownloadChecked
}) => {
  return (
    <thead>
      <tr className="table_header">
        <th scope="col">
          <input
            className="download-select"
            type="checkbox"
            name="downloadAll"
            value="all"
            onChange={handleDownloadAllChange}
            checked={contractsForDownloadChecked ? "checked" : ""}
          />
        </th>
        <th scope="col">
          <div className="row no-gutters ">
            <ListHeaderitem
              name="Name"
              sortKey="name"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div className="row no-gutters">
            <input
              className="flex-grow-1 table-filter-field"
              type="text"
              name="nameFilter"
              onChange={handleFilterChanged}
              placeholder="Alle"
            />
          </div>
        </th>

        <th scope="col">
          <div>
            <ListHeaderitem
              name="Vertragsnr."
              sortKey="contractNr"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div>
            <input
              className="table-filter-field"
              type="text"
              name="contractNrFilter"
              onChange={handleFilterChanged}
              placeholder="Alle"
            />
          </div>
        </th>
        <th scope="col">
          <div className="row no-gutters">
            <ListHeaderitem
              name="Name Verkäufer "
              sortKey="salesPersonName"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div className="row no-gutters">
            <input
              className=" table-filter-field"
              type="text"
              name="salesPersonNameFilter"
              onChange={handleFilterChanged}
              placeholder="Alle"
            />
          </div>
        </th>
        <th scope="col">
          <div className="row no-gutters">
            <ListHeaderitem
              name="Produkt"
              sortKey="product"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div className="row no-gutters">
            <input
              className=" table-filter-field"
              type="text"
              name="productFilter"
              onChange={handleFilterChanged}
              placeholder="Alle"
            />
          </div>
        </th>
        <th scope="col">
          <div className="row no-gutters">
            <ListHeaderitem
              name="Vertragsende bis"
              sortKey="endDate"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div className="row no-gutters">
            <DatePickerField
              name="endDateFilter"
              value={dateFilter}
              onChange={handleEndDateFilterChanged}
              datePickerClassName="table-sort-datepicker"
            />
          </div>
        </th>
        <th scope="col">
          <div className="row no-gutters">
            <ListHeaderitem
              name="Kategorie"
              sortKey="category"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div className="row no-gutters">
            <select
              className="flex-grow-1 table-filter-select-field"
              name="categoryFilter"
              onChange={handleFilterChanged}
            >
              <option value="alle">Alle</option>
              <option value="angebot">Angebot</option>
              <option value="info">Information</option>
              <option value="advBlock">Werbesperre</option>
            </select>
          </div>
        </th>
        <th scope="col">
          <div className="row no-gutters">
            <ListHeaderitem
              name="Status Angebot"
              sortKey="offerAvailable"
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
            />
          </div>
          <div className="row no-gutters">
            <select
              className="table-filter-select-field"
              name="filterOfferStatus"
              onChange={handleFilterChanged}
            >
              <option value="alle">Alle</option>
              <option value="offen">offen</option>
              <option value="gespeichert">gespeichert</option>
            </select>
          </div>
        </th>
      </tr>
    </thead>
  );
};

// header item component
const ListHeaderitem = ({
  name,
  sortKey,
  currentSortKey,
  currentSortDirection,
  sortBy
}) => {
  return (
    <button
      className={
        sortKey === currentSortKey
          ? "table_header_item_sorted"
          : "table_header_item"
      }
      onClick={e => (sortKey ? sortBy(sortKey) : null)}
    >
      {name +
        (sortKey === currentSortKey
          ? currentSortDirection === "ASC"
            ? "▲"
            : "▼"
          : "")}
    </button>
  );
};
export default ContractListTableHeader;
