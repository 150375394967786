import React from "react";
const StyledTextArea = ({
  name,
  nrOfRows,
  nrOfCols,
  label,
  placeholder,
  value,
  onChange,
  maxLength = 500,
  readonly
}) => {
  return (
    <div>
      <div className="row no-gutters">
        <label className="label-input">{label}</label>
      </div>
      <div className="row no-gutters d-flex flex-grow-1">
        <div className="col d-flex flex-grow-1">
          <textarea
            name={name}
            rows={nrOfRows}
            cols={nrOfCols}
            type="text"
            className="d-flex flex-grow-1 text-area"
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            maxLength={maxLength}
            disabled={readonly}
          />
        </div>
      </div>
    </div>
  );
};

export default StyledTextArea;
