import React, { useEffect } from "react";
import EditDealerDataPanel from "../../../dealerdata/EditDealerDataPanel";
import ParticipationWizardSteps from "./ParticipationWizardSteps";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import { saveParticipationOffer } from "../../../backend/AktionenService";
import useScbForm from "../../../utils/form/ScbFormHook";

const ParticipationDealerDataStep = () => {
  const { setCurrentStep, readonly, contract } = useScbFormField(
    "currentStep",
    "readonly",
    "contract"
  );

  const { submitForm, configureForm, formData, setSuccessLabel } = useScbForm(
    "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal."
  );

  const saveOffer = async (formData) => {
    const participationOffer = {
      id: formData.id,
      dealerName: formData.dealerName,
      dealerStreetAndNr: formData.dealerStreetAndNr,
      dealerZipCode: formData.dealerZipCode,
      dealerCity: formData.dealerCity,
      dealerEmail: formData.dealerEmail,
      dealerTelephoneNr: formData.dealerTelephoneNr,
      dealerWebPage: formData.dealerWebPage,
      dealerLogo: formData.dealerLogo,
      dealerLogoPath: formData.dealerLogoPath,
    };
    await saveParticipationOffer(contract.number, [participationOffer]);
  };
  useEffect(() => {
    configureForm(saveOffer);
    setSuccessLabel("Die Teilnahme wurde erfolgreich gespeichert.");
  }, [contract]);

  return (
    <>
      <ParticipationWizardSteps activeStepNr="2" />
      <div
        className="row no-gutters pt-2 pb-2"
        style={{ color: "gray", fontSize: "10px" }}
      >
        Ihre hier durchgeführten Änderungen werden individuell für dieses
        Angebot in das Kundenmailing eingedruckt. Ihre Autohaus-Stammdaten
        werden dadurch nicht verändert. Klicken Sie auf „Meine Daten“ im Menü am
        oberen Bildschirmrand, um die voreingestellten Daten Ihres Autohauses
        dauerhaft zu ändern.
      </div>
      <EditDealerDataPanel />
      <div className="row pt-3 bottom-row">
        <div className="col">
          <button
            type="submit"
            className="scb_button"
            onClick={() => setCurrentStep("Participation")}
          >
            Zurück
          </button>
        </div>
        <div className="col"></div>
        {!readonly && (
          <div className="col-auto">
            <button type="submit" className="scb_button" onClick={submitForm}>
              Speichern
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ParticipationDealerDataStep;
