import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../DataStore";
import Select from "react-dropdown-select";
import { useHistory } from "react-router-dom";

const DealersNavItem = () => {


  const { loginSession, selectedDealer, setSelectedDealer } = useContext(
    LoginContext
  );

  const [dealer, setDealer] = useState(selectedDealer);
  const [dealerObject, setDealerObject] = useState();

  const getDealerLabel = (dealer) => {
    if (dealer && dealer.name)
      return `${dealer.name === null ? "" : dealer.name.substring(0, 17)}(${
        dealer.number
      })`;
    return "";
  };
  useEffect(() => {
    setDealer(selectedDealer)
    setDealerObject(
      loginSession?.dealers.find((d) => d.number === selectedDealer)
    );
  }, [selectedDealer]);

  const dealerOptions =
    loginSession && loginSession.dealers
      ? loginSession.dealers.map((dealer) => {
          return {
            value: dealer.number,
            label: getDealerLabel(dealer),
          };
        })
      : [];

  return (
    <div
      className="row no-gutters pr-1 justify-content-end dealer-nav-item float-right" >
      <div className="col">
        <div className="row no-gutters pt-2 pb-1 justify-content-end">
          <div className="col">
            <div className="login-as">
              <span>
                {`Sie sind als ${
                  loginSession && loginSession.isSCBAdmin
                    ? "SCB Administrator "
                    : loginSession && loginSession.isGBL
                    ? "Gebietsleiter "
                    : ""
                }
                ${loginSession && loginSession.userFirstName} ${
                  loginSession && loginSession.userLastName
                } angemeldet`}
              </span>
            </div>
          </div>
        </div>
        <div className="row no-gutters justify-content-end align-items-center">
          <div className="col-auto pr-1 handler-label ">Händler:</div>
          <div className="col">
            <Select
              className="dealer-search-box"
              options={dealerOptions}
              values={[{ value: dealer, label: getDealerLabel(dealerObject) }]}
              onChange={(values) => {
                setSelectedDealer(values[0].value);
              }}
              placeholder="Händler auswählen..."
              addPlaceholder="Händler suchen..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealersNavItem;
