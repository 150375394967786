import React, { useState } from "react";
import LoadingOverlay from "./LoadingOverlay";

export const LoadingOverlayContext = React.createContext(null);

const LoadingOverlayContextProvider = ({ children }) => {
  
  const [blocking, setBlocking] = useState(false);
  const [overlayText,setOverlayText] = useState("Daten werden geladen...");

  return (
    <LoadingOverlayContext.Provider value={{ setBlocking, setOverlayText }}>
      <LoadingOverlay active={blocking} text={overlayText}>
        {children}
      </LoadingOverlay>
    </LoadingOverlayContext.Provider>
  );
};

export const withLoadingOverlay = (WrappedElement) => (props) => (
  <LoadingOverlayContextProvider>
    <WrappedElement {...props} />
  </LoadingOverlayContextProvider>
);
