import React, { useState, useEffect, useContext } from "react";
import "./financing.css";
import SelectWithLabel from "./SelectWithLabel";
import SingleAmountInputWithLabel from "./SingleAmountInputWithLabel";
import AmountAndPercentInputWithLabel from "./AmountAndPercentInputWithLabel";
import { getBackgroundData } from "../../../backend/FinancingService";
import { LoginContext } from "../../../DataStore";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import useScbForm from "../../../utils/form/ScbFormHook";
import { get, set } from "lodash";
import FinancingEurotaxPanel from "./FinancingEurotaxPanel";
import { formatDateForSave } from "../../../utils/FormatUtils";
const Options_Finanzierungsarten = [
  {
    value: "LOAN",
    text: "Ankaufskredit",
  },
  {
    value: "LEASING",
    text: "Leasing",
  },
];
const FinancingInputDataPanel = ({ onCalculate, nrOfOffer }) => {
  const { selectedDealer, getSelectedDealerData } = useContext(LoginContext);
  const { contract } = useScbFormField("contract");
  const {
    addValidators,
    validate,
    configureForm,
    setSuccessLabel,
    setBlocking,
    resetFormErrors,
    addFormError,
  } = useScbForm();
  const {
    [`registrationDate${nrOfOffer ? nrOfOffer : ""}`]: registrationDate,
    [`financingCalculation${nrOfOffer ? nrOfOffer : ""}`]: financingCalculation,
    [`price${nrOfOffer ? nrOfOffer : ""}`]: price,
    [`milage${nrOfOffer ? nrOfOffer : ""}`]: milage,
  } = useScbFormField(
    `registrationDate${nrOfOffer ? nrOfOffer : ""}`,
    `financingCalculation${nrOfOffer ? nrOfOffer : ""}`,
    `price${nrOfOffer ? nrOfOffer : ""}`,
    `milage${nrOfOffer ? nrOfOffer : ""}`
  );
  // loaded calculation data
  const [backgroundData, setBackgroundData] = useState({});
  // calculation data to be used for calculation
  const [calcData, setCalcData] = useState({
    registrationDate: formatDateForSave(registrationDate),
    price,
    milage,
  });

  const [posAccounts, setPosAccounts] = useState([]);
  const [selectedPosAccount, setSelectedPosAccount] = useState();
  const [variantenOptions, setVariantenOptions] = useState([]);
  const [produkteOptions, setProdukteOptions] = useState([]);
  const [anlageKlassenOptions, setAnlageKlassenOptions] = useState([]);

  const onLocalCalculate = () => {
    if(!calcData.residualValue) {
      addFormError(`Bitte um Eingabe eines ${isLeasing() ? "Restwerts" : "Schlussrate"} größer Euro 0,-`);
    } else {
      resetFormErrors();
      onCalculate(calcData, selectedPosAccount, selectedDealer);    
    }
  }
  const isLeasing = () => {
    return calcData?.productType === "LEASING";
  };

  const getSelectedObjektArtId = () => {
    switch (calcData?.objektart) {
      case "MOTORRAD":
        return "K";
      case "LCV":
        return "L";
      default:
        return "P";
    }
  };
  const getPkwObjektArt = () => {
    return backgroundData && backgroundData.objektarten
      ? backgroundData.objektarten.find(
          (oa) => oa.id === getSelectedObjektArtId()
        )
      : {};
  };
  const getProdukte = () => {
    if (!calcData?.productType) return [];
    const oa = getPkwObjektArt();
    if (isLeasing() && oa.leasing && oa.leasing.produkte) {
      return oa.leasing.produkte;
    }
    if (!isLeasing() && oa.kredit && oa.kredit.produkte) {
      return oa.kredit.produkte;
    }
    return [];
  };

  const getPosAccountsOptions = () => {
    return posAccounts.map((pa) => {
      return { value: pa.id, text: pa.name };
    });
  };
  const resetAnlageKlassenOptions = () => {
    if (!calcData?.productType) return [];
    const oa = getPkwObjektArt();
    setAnlageKlassenOptions(
      oa.leasing && oa.leasing.anlageklassen
        ? oa.leasing.anlageklassen.map((ak) => {
            return { value: ak.id, text: ak.bezeichnung };
          })
        : []
    );
  };

  const resetProdukteOptions = () => {
    if (!calcData?.productType) return [];
    let produkte = getProdukte(isLeasing());
    setProdukteOptions(
      produkte
        .filter((prod) => prod.varianten && prod.varianten.length > 0)
        .map((pr) => {
          return { value: pr.id, text: pr.bezeichnung };
        })
    );
  };

  const resetVariantenOptions = () => {
    if (!calcData?.productId) return [];
    let produkte = getProdukte(isLeasing());
    const produkt = produkte.find((prod) => prod.id == calcData.productId);
    setVariantenOptions(
      !produkt || !produkt.varianten
        ? []
        : produkt.varianten.map((vr) => {
            return { value: vr.id, text: vr.bezeichnung };
          })
    );
  };

  const handleChangeFinancingType = (productType) => {
    setCalcData({
      ...calcData,
      productType,
      anlageklasseId: undefined,
      productId: undefined,
      varianteId: undefined,
    });
  };

  const handleChangeAnlageKlassen = (anlageklasseId) => {
    setCalcData({
      ...calcData,
      anlageklasseId,
      productId: undefined,
      varianteId: undefined,
    });
  };

  const handleChangeProdukt = (productId) => {
    setCalcData({ ...calcData, productId, varianteId: undefined });
  };

  const handleChangeVariante = (varianteId) => {
    setCalcData({ ...calcData, varianteId });
  };
  const handleTypeChange = async (selectedEurotaxType) => {
    if (selectedEurotaxType) await loadBackgroundData(selectedEurotaxType);
  };

  const loadData = async () => {
    try {
      setBlocking(true);
      resetFormErrors();
      const selectedDealerdata = getSelectedDealerData();
      if (
        selectedDealerdata &&
        selectedDealerdata.number &&
        selectedDealerdata.accountTypes &&
        selectedDealerdata.accountTypes.length > 0
      ) {
        if (selectedDealerdata.accountTypes.length === 1) {
          setSelectedPosAccount(
            financingCalculation?.selectedPosAccount ??
              selectedDealerdata.accountTypes[0].id
          );
        } else {
          setSelectedPosAccount(financingCalculation?.selectedPosAccount);
        }
        setPosAccounts(selectedDealerdata.accountTypes);
      } else {
        addFormError("Diese Händler hat keine ePOS Accounts konfiguriert!");
      }

      setCalcData({
        ...calcData,
        selectedEurotaxType: financingCalculation?.selectedEurotaxType,
        selectedMarke: financingCalculation?.selectedMarke,
        selectedModel: financingCalculation?.selectedModel,
        selectedEurotaxTypeName: financingCalculation?.selectedEurotaxTypeName,
        selectedMarkeName: financingCalculation?.selectedMarkeName,
        selectedModelName: financingCalculation?.selectedModelName,
        productType: financingCalculation?.productType,
        anlageklasseId: financingCalculation?.anlageklasseId,
        objektart: financingCalculation?.objektart,
        productId: financingCalculation?.productId,
        varianteId: financingCalculation?.varianteId,
      });
    } catch (err) {
      addFormError("Berechnungsdaten wurden nicht erfolgreich geladen!");
    } finally {
      setBlocking(false);
    }
  };
  const loadBackgroundData = async (selectedEurotaxType) => {
    try {
      setBlocking(true);
      if (selectedPosAccount && selectedEurotaxType) {
        resetFormErrors();
        const data = await getBackgroundData(
          selectedPosAccount,
          selectedDealer
        );
        setBackgroundData(data);
      }
    } catch (err) {
      const errors = err.response.data;
      addFormError(
        `Berechnungsdaten wurden nicht erfolgreich geladen.${
          errors ? errors.join() : ""
        }`
      );
      setBackgroundData({});
      handleChangeFinancingType();
    } finally {
      setBlocking(false);
    }
  };

  useEffect(() => {
    loadBackgroundData(calcData?.selectedEurotaxType);
  }, [selectedPosAccount]);

  useEffect(() => {
    loadData();
  }, [financingCalculation]);

  useEffect(() => {
    resetAnlageKlassenOptions();
    resetProdukteOptions();
    resetVariantenOptions();
  }, [backgroundData]);

  useEffect(() => {
    if(backgroundData) {
      resetAnlageKlassenOptions();
      resetProdukteOptions();
      resetVariantenOptions();
    }
  }, [calcData]);
  return (
    <div>
      <SelectWithLabel
        id="finanzierungsArten"
        options={getPosAccountsOptions()}
        value={selectedPosAccount}
        placeholder="Bitte wählen"
        label="POS"
        onChange={(e) => setSelectedPosAccount(e.target.value)}
        disabled={!posAccounts || posAccounts.length === 1}
      />
      <FinancingEurotaxPanel
        calcData={calcData}
        setCalcData={setCalcData}
        nrOfOffer={nrOfOffer}
        handleTypeChange={handleTypeChange}
      />

      <SelectWithLabel
        id="finanzierungsArten"
        options={Options_Finanzierungsarten}
        placeholder="Bitte wählen"
        label="Finanzierungsart"
        value={calcData?.productType}
        onChange={(e) => handleChangeFinancingType(e.target.value)}
        disabled={!selectedPosAccount || !calcData?.selectedEurotaxType}
      />

      {isLeasing() && (
        <SelectWithLabel
          id="anlageKlassen"
          options={anlageKlassenOptions}
          placeholder="Bitte wählen"
          label="Anlageklasse:"
          value={calcData?.anlageklasseId}
          onChange={(e) => handleChangeAnlageKlassen(e.target.value)}
          disabled={!calcData?.productType}
        />
      )}
      <SelectWithLabel
        id="product"
        options={produkteOptions}
        placeholder="Bitte wählen"
        label="Produkt:"
        onChange={(e) => handleChangeProdukt(e.target.value)}
        value={calcData?.productId}
        disabled={
          isLeasing() ? !calcData?.anlageklasseId : !calcData?.productType
        }
      />

      <SelectWithLabel
        id="variante"
        options={variantenOptions}
        placeholder="Bitte wählen"
        label="Variante:"
        value={calcData?.varianteId}
        onChange={(e) => handleChangeVariante(e.target.value)}
        disabled={!calcData?.productType || !calcData?.productId}
      />
      <SingleAmountInputWithLabel
        label={isLeasing() ? "Barzahlungspreise" : "Kaufpreis"}
        id="price"
        postfix={isLeasing() ? "Bto" : ""}
        value={price}
        disabled
        fixedDecimalScale
      />
      {isLeasing() && (
        <>
          <SingleAmountInputWithLabel
            label="Zubehörpreis NoVa frei"
            id="accessoriesPrice"
            postfix={"Bto"}
            value={calcData.accessoriesPrice}
            onChange={(value) =>
              setCalcData({ ...calcData, accessoriesPrice: value })
            }
            disabled={!price}
            fixedDecimalScale
          />
          <SingleAmountInputWithLabel
            label="Kilometer / Jahr"
            id="kmPerYear"
            postfix={"000"}
            prefix=""
            value={calcData.kmPerYear}
            onChange={(value) => setCalcData({ ...calcData, kmPerYear: value })}
            disabled={!calcData?.varianteId}
          />
        </>
      )}
      <AmountAndPercentInputWithLabel
        id="initialPayment"
        label="Anzahlung"
        postfixAmount={isLeasing() ? "Bto" : ""}
        percentBaseValue={price}
        value={calcData.initialPayment}
        onChange={(value) =>
          setCalcData({ ...calcData, initialPayment: value })
        }
        disabled={!price}
        fixedDecimalScale
      />
      {isLeasing() && (
        <AmountAndPercentInputWithLabel
          id="depot"
          label="Depot"
          postfixAmount="Nto"
          percentBaseValue={price}
          value={calcData.depot}
          onChange={(value) => setCalcData({ ...calcData, depot: value })}
          disabled={!price}
        />
      )}
      <AmountAndPercentInputWithLabel
        id="residualValue"
        label={isLeasing() ? "Restwert" : "Schlussrate"}
        postfixAmount={isLeasing() ? "Bto" : ""}
        percentBaseValue={price}
        value={calcData.residualValue}
        onChange={(value) => setCalcData({ ...calcData, residualValue: value })}
        disabled={!price}
      />
      <SingleAmountInputWithLabel
        label="Laufzeit"
        id="duration"
        postfix={"Mo"}
        prefix=""
        value={calcData.duration}
        onChange={(value) => setCalcData({ ...calcData, duration: value })}
        disabled={!calcData?.varianteId}
      />
      {!isLeasing() && (
        <SingleAmountInputWithLabel
          label="rückzahlungsfreie Monate"
          id="repaymentFreeDuration"
          value={calcData.repaymentFreeDuration}
          postfix={"Mo"}
          prefix=""
          onChange={(value) =>
            setCalcData({ ...calcData, repaymentFreeDuration: value })
          }
          disabled={!calcData?.varianteId}
        />
      )}
      <div className="row pt-1">
        <div className="col d-flex justify-content-end">
          <button
            type="submit"
            className="scb_button"
            onClick={onLocalCalculate}
            disabled={!calcData?.varianteId || !price || !calcData.duration}
          >
            Berechnen
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinancingInputDataPanel;
