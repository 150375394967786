import React, { useEffect } from "react";
import { loadAktionContract } from "../../backend/AktionenService";
import ParticipationPromotionDetail from "./participation/ParticipationPromotionDetail";
import CustomerData from "../../offer/info-panels/CustomerData";
import CarData from "../../offer/info-panels/CarData";
import ContractData from "../../offer/info-panels/ContractData";
import EditOfferTitle from "../../offer/EditOfferTitle";
import { withScbForm } from "../../utils/form/ScbFormProvider";
import { useParams } from "react-router-dom";
import useScbForm from "../../utils/form/ScbFormHook";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import PromotionOffers from "./offer/PromotionOffers";

const PromotionOfferDetail = () => {

  let { promotionId, contractNumber } = useParams();

  const {
    setReadonly,
    contract,
    setContract,
    setActiveStepNr,
    setNrOfOffers,
    setCategory
  } = useScbFormField(
    "readonly",
    "currentStep",
    "contract",
    "activeStepNr",
    "nrOfOffers",
    "category"
  );

  const {
    configureForm,
    setSuccessLabel,
    setBlocking,
    addFormError,
  } = useScbForm();
  const loadContract = async () => {
    setBlocking(true);
    try {
      // load the contract with the number
      const c = await loadAktionContract(contractNumber, promotionId);
      // initialize set form data
      setReadonly(c.archived);
      setContract(c);
      setNrOfOffers(c.promotion.type === "OFFER_2" ? 2 : 1);
      setActiveStepNr(1);
      setCategory(1);
      // if not set than create a new one
    } catch (err) {
      addFormError("Ein unerwartete Fehler ist aufgetretten.");
    } finally {
      setBlocking(false);
    }
  };

  useEffect(() => {
    configureForm();
    setSuccessLabel("Das Angebot wurde erfolgreich gespeichert.");
    loadContract();
  }, [contractNumber]);

  return (
    <div>
      <div className="pt-4">
        <EditOfferTitle />
        {contract && (
          <div className="row pt-2 ">
            <div className="col-3">
              <CustomerData customer={contract.customer} category={1} />
              <CarData contract={contract} category={1} />
              <ContractData contract={contract} category={1} />
            </div>
            <div className="col ml-3 pl-3 edit_panel">
              {contract.promotion.type === "PARTICIPATION" && (
                <ParticipationPromotionDetail />
              )}
              {contract.promotion.type === "OFFER_1" && (
                <PromotionOffers nrOfOffers={1} />
              )}
              {contract.promotion.type === "OFFER_2" && (
                <PromotionOffers nrOfOffers={2} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withScbForm(PromotionOfferDetail);
