import React from "react";
import "./css/contract_list_table.css";

const ContractListTableFooter = ({
  numberOfElements,
  pageSize,
  pageNumber
}) => {
  const startElementNr = (pageNumber - 1) * pageSize;
  const endElementNumber =
    pageNumber * pageSize > numberOfElements
      ? numberOfElements
      : pageNumber * pageSize;

  return (
    <div className="row no-gutters table-footer-1">
      <span className="pl-3">
        {`${startElementNr} - ${endElementNumber} von ${numberOfElements} Verträgen werden angezeigt`}
      </span>
    </div>
  );
};

export default ContractListTableFooter;
