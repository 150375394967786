import React, { useState, useEffect } from "react";

export const LoginContext = React.createContext();

const refreshToken = async (minValidity) => {
  return new Promise((resolve, reject) => {
    window.keycloak
      .updateToken(minValidity)
      .success(function () {
        resolve();
      })
      .error(function () {
        reject();
      });
  });
};
export const getAccessToken = async () => {
  if (!window.keycloak) return null;
  if (window.keycloak.isTokenExpired()) await refreshToken();
  return window.keycloak.token;
};

const DataStore = ({ userInfo, children }) => {
  // loginSession {userNumber, isGBL, isSCBAdmin, dealers.number dealers.name}
  const getNewLoginSession = () => {
    if (window.keycloak == null || userInfo == null) return null;
    const newLoginSession = {
      token: getAccessToken,
      userNumber: window.keycloak.tokenParsed.preferred_username,
      userFirstName: userInfo.customerFirstName,
      userLastName: userInfo.customerLastName,
      logout: window.keycloak.logout,
      isGBL: userInfo.gbl,
      isSCBAdmin: userInfo.scbadmin,
      dealers: userInfo.dealers,
    };
    return newLoginSession;
  };
  const [loginSession, setLoginSession] = useState(getNewLoginSession());
  const [selectedDealer, setSelectedDealer] = useState(null);

  useEffect(() => {
    // if loginSession.dealers has at least one element select first
    if (
      loginSession !== null &&
      loginSession.dealers &&
      loginSession.dealers.length > 0
    ) {
      loginSession.dealers.sort((d1, d2) =>
        d1.name && d1.name !== null ? d1.name.localeCompare(d2.name) : -1
      );
      setSelectedDealer(loginSession.dealers[0].number);
    }
  }, [loginSession]);
  const getSelectedDealerData = () => {
    return selectedDealer && loginSession && loginSession.dealers
      ? loginSession.dealers.find(dealer=>dealer.number === selectedDealer)
      : {};
  }
  return (
    <LoginContext.Provider
      value={{
        loginSession,
        setLoginSession,
        selectedDealer,
        setSelectedDealer,
        getSelectedDealerData,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default DataStore;
