import React from "react";
import EditOfferPanel from "./EditOfferPanel";
import useScbFormField from "../../utils/form/ScbFormFieldHook";

const EditOfferStep = ({ navigateToNextStep }) => {
  const { readonly, id, category } = useScbFormField("readonly", "id","category");

  return (
    <div className="pl-3">
      <EditOfferPanel />
      {category !== 3 && (
        <div className="row justify-content-end mb-3 mt-2 bottom-row">
          <div className="col-auto">
            <button
              type="submit"
              className="scb_button"
              onClick={navigateToNextStep}
            >
              Weiter
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditOfferStep;
