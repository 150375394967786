import { getFromBackend, postToBackend } from "../utils/ServiceUtils";

export const loadAktionenContractsList = async (selectedDealer, archive) => {
  return await getFromBackend(
    `/promotion/contractList?dealerNumber=${selectedDealer}&archived=${archive}`
  );
};
export const loadAktionContract = async (contractNumber, promotionId) => {
  return await getFromBackend(
    `/promotion/contract?contractNumber=${contractNumber}&promotionId=${promotionId}`
  );
};
export const loadCSVLineForAktionenContract = async (contractNr) => {};

export const denyParticipation = async (contractNumber) => {
  return postToBackend(
    `/promotion/denyParticipation?contractNumber=${contractNumber}`
  );
};
export const saveParticipationOffer = async (contractNr, offers) => {
  return postToBackend(`/promotion/offer`, {
    contractNr,
    offers: [...offers],
  });
};
