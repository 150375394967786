import React from "react";
import "./css/contract_list_header.css";

const ContractListHeader = ({ archive }) => {
  return (
    <div>
      {/* <h3 className="title">{archive ? "Archiv" : "Übersicht"}</h3> */}
      {archive ? (
        ""
      ) : (
        <h5>Hier sehen Sie Ihre Kundenverträge, die in 6 Monaten auslaufen.</h5>
      )}
    </div>
  );
};

export default ContractListHeader;
