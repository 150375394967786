import axios from "axios";
import { getAccessToken } from "../DataStore";

const http = axios.create({
  withCredentials: false,
  timeout: 90000,
  params: {},
});

// Add a request interceptor
http.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers = {
      ...config.headers,
      "Access-Control-Allow-Methods": "DELETE, POST, PUT, GET, OPTIONS",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
http.interceptors.response.use(
  function (response) {  
    return response;
  },
  function (error) {
    console.log(error);
    throw error;
  }
);
export default http;
