import React from "react";
import { Link } from "react-router-dom";
import { ContractListItemHelper } from "../../backend/domain";
import { formatAmount, getCondition } from "../../utils/FormatUtils";
import { PRODUCT_TYPE_OL } from "../../utils/Constants";

const PromotionsContractListTableRow = ({
  contract,
  handleSelectForDownload,
  contractsForDownload,
  archive,
}) => {
  const {
    number,
    productType /*Produkt*/,
    promotion,
    salesPersonName,
    productName,
    condition,
  } = contract;

  const getStatus = ()=> {
    if(contract.promotion.type === "PARTICIPATION"){
      switch (contract.participation) {
        case "OK":
          return "Teilnahme";
        case "NOK":
          return "Keine Teilnahme";
        default:
          return "Offen";
      }
    }
    else {
      return contract.offerAvailable ? "Gespeichert":"Offen";
    }
  }
  const isChecked = () => {
    var tr =
      contractsForDownload && contractsForDownload.includes("" + number)
        ? "checked"
        : "";
    return tr;
  };

  return (
    <tr className="table_row">
      <td>
        <input
          className="download-select"
          type="checkbox"
          value={number}
          checked={isChecked()}
          onChange={handleSelectForDownload}
        />
      </td>
      <td>
        <Link to={`/aktionen/detail/${number}/${promotion.id}`}>{promotion.name}</Link>
      </td>
      <td className="date-column">{promotion.validUntil}</td>
      <td>{ContractListItemHelper.getCustomerFullName(contract)}</td>
      <td align="center">{number}</td>
      <td>{salesPersonName}</td>
      <td>
        <div>{productName}</div>
        <div className="contract-list-table-row-second-line">
          {getCondition(condition)}
        </div>
      </td>
      <td align="center">{getStatus()}</td>
    </tr>
  );
};

export default PromotionsContractListTableRow;
