import React, { useContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ContractList from "../contractslist/ContractList";
import NavigationMenu from "./navigation/NavigationMenu";
import EditOffer from "../offer/EditOffer";
import DealerDataFrame from "../dealerdata/DealerDataFrame";
import HelpFrame from "../help/HelpFrame";
import ContactFrame from "../contact/ContactFrame";
import { LoginContext } from "../DataStore";
import AdminFrame from "../admin/AdminFrame";
import VertragsendeFrame from "../contractslist/VertragsendeFrame";
import PromotionOfferDetail from "../promotions/detail/PromotionOfferDetail";
import PromotionsFrame from "../promotions/PromotionsFrame";

const MainWindow = () => {
  const {loginSession} = useContext(LoginContext);

  const isAdmin = () => {
    return loginSession && loginSession !== null && loginSession.isSCBAdmin;
  };

  return (
    <div>
      <Router basename="/ichundmeinekunden">
        <NavigationMenu isAdmin={isAdmin()} />
        <div className="h-100">
          <Route
            exact
            path="/"
            render={(props) => <VertragsendeFrame active="1" />}
          />
          <Route
            path="/vertragsende/uebersicht"
            exact
            render={(props) => <VertragsendeFrame active="1" />}
          />
          <Route
            path="/vertragsende/archiv"
            exact
            render={(props) => <VertragsendeFrame active="2" />}
          />
          <Route
            path="/vertragsende/angebot/:contractNr"
            exact
            component={EditOffer}
          />
          <Route
            path="/meinedaten"
            exact
            render={(props) => <DealerDataFrame />}
          />
          <Route
            path="/aktionen/uebersicht"
            exact
            render={(props) => <PromotionsFrame active="1" />}
          />
          <Route
            path="/aktionen/archiv"
            exact
            render={(props) => <PromotionsFrame active="2" />}
          />
          <Route
            path="/aktionen/detail/:contractNumber/:promotionId"
            exact
            component={PromotionOfferDetail}
          />
          <Route path="/hilfe" exact render={(props) => <HelpFrame />} />
          <Route path="/kontakt" exact render={(props) => <ContactFrame />} />
          {isAdmin ? (
            <Route path="/admin" exact render={(props) => <AdminFrame />} />
          ) : (
            ""
          )}
        </div>
      </Router>
    </div>
  );
};

export default MainWindow;
