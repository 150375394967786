import React from "react";
import WizardStep from "../../../utils/components/WizardStep";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";

const PromotionsOfferWizardSteps = () => {
  const { activeStepNr, nrOfOffers } = useScbFormField(
    "activeStepNr",
    "nrOfOffers"
  );

  return (
    <div>
      <div className="row pl-3 no-gutters step-row mb-3">
        <WizardStep
          title={nrOfOffers === 2 ? "Angebot 1" : "Angebot"}
          active={activeStepNr === 1}
        />
        <WizardStep
          title={nrOfOffers === 2 ? "Finanzierung 1" : "Finanzierung"}
          active={activeStepNr === 2}
        />
        {nrOfOffers === 2 ? (
          <>
            <WizardStep title={"Angebot 2"} active={activeStepNr === 3} />
            <WizardStep title={"Finanzierung 2"} active={activeStepNr === 4} />
            <WizardStep title={"Meine Daten"} active={activeStepNr === 5} />
          </>
        ) : (
          <WizardStep title={"Meine Daten"} active={activeStepNr === 3} />
        )}
      </div>
    </div>
  );
};

export default PromotionsOfferWizardSteps;
