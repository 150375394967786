import React, { useState } from "react";

import NumberFormat from "react-number-format";
import AmountEditField from "./AmountEditField";

const ValidationNumericField = ({
  handleHasErrors,
  name,
  label,
  value,
  placeholder = "-",
  onChange,
  required,
  maxLength = "100",
  readonly = false,
  decimalPlaces = 2,
  suffix = "",
  prefix = "",
  vertical = true,
  fixedDecimalScale,
  validationErrors=[]
}) => {
  return (
    <div className="pb-2">
      <div className="row no-gutters">
        <label className="label-input">
          {label}
          {required ? <span className="required-input">*</span> : ""}:
        </label>
      </div>
      <div className="row no-gutters d-flex flex-grow-1">
        <div className="col d-flex flex-grow-1">
          <AmountEditField
            id={name}
            value={value}
            postfix={suffix}
            prefix={prefix}
            onChange={onChange}
            disabled={readonly}
            fixedDecimalScale={fixedDecimalScale}
            validationErrors={validationErrors}
          />
        </div>
      </div>
      {validationErrors &&
        validationErrors.map((validationError, idx) => (
          <div key={idx} className="edit-field-validation-text">
            {validationError}
          </div>
        ))}
    </div>
  );
};

export default ValidationNumericField;
