import AmountEditField from "../../../utils/components/AmountEditField";
import React from "react";

const SingleAmountInputWithLabel = ({
  id,
  label,
  postfix,
  prefix = " €",
  onChange,
  value,
  disabled = false,
  fixedDecimalScale,
}) => {
  return (
    <div className="row pt-1 financing-field">
      <div className="col-3 financing-label">
        <label htmlFor={id}>{label}</label>
      </div>
      <div className="col">
        <AmountEditField
          id={id}
          valueChanged={() => {}}
          value={value}
          postfix={postfix}
          prefix={prefix}
          onChange={onChange}
          disabled={disabled}
          fixedDecimalScale={fixedDecimalScale}
        />
      </div>
    </div>
  );
};
export default SingleAmountInputWithLabel;