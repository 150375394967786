import React, { useEffect } from "react";
import PromotionsOfferWizardSteps from "./PromotionsOfferWizardSteps";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import PromotionOfferSteps from "./PromotionOfferSteps";

const PromotionOffers = () => {
  const { nrOfOffers, setCurrentStep, contract } = useScbFormField(
    "currentStep",
    "contract",
    "nrOfOffers"
  );

  useEffect(() => {
    setCurrentStep("Offer_1");
  }, [contract]);

  return (
    <>
      <PromotionsOfferWizardSteps />
      <PromotionOfferSteps nrOfOffer={1} />
      {nrOfOffers === 2 && <PromotionOfferSteps nrOfOffer={2} />}
    </>
  );
};

export default PromotionOffers;
