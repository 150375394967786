import React, { useEffect, useContext } from "react";
import ParticipationStep from "./ParticipationStep";
import ParticipationDealerDataStep from "./ParticipationDealerDataStep";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import { loadDealerData } from "../../../backend/DealerService";
import { LoginContext } from "../../../DataStore";

const ParticipationPromotionDetail = () => {
  const { selectedDealer } = useContext(LoginContext);

  const { currentStep, setCurrentStep, contract, readonly } = useScbFormField(
    "currentStep",
    "contract",
    "readonly"
  );
  const {
    setDealerName,
    setDealerStreetAndNr,
    setDealerZipCode,
    setDealerCity,
    setDealerEmail,
    setDealerWebPage,
    setDealerTelephoneNr,
    setDealerLogo,
    setDealerLogoPath,
  } = useScbFormField(
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo",
    "dealerLogoPath"
  );

  const initOffer = async () => {
    if (contract && contract.offers.length > 0) {
      const offer = contract.offers[0];
      if (offer) {
        setDealerName(offer.dealerName);
        setDealerStreetAndNr(offer.dealerStreetAndNr);
        setDealerZipCode(offer.dealerZipCode);
        setDealerCity(offer.dealerCity);
        setDealerEmail(offer.dealerEmail);
        setDealerWebPage(offer.dealerWebPage);
        setDealerTelephoneNr(offer.dealerTelephoneNr);
        setDealerLogo(offer.dealerLogo);
        setDealerLogoPath(offer.dealerLogoPath);
      }
    } else {
      const dealer = await loadDealerData(selectedDealer);
      setDealerName(dealer.name);
      setDealerStreetAndNr(dealer.streetAndNr);
      setDealerZipCode(dealer.zipCode);
      setDealerCity(dealer.city);
      setDealerEmail(dealer.email);
      setDealerWebPage(dealer.webPage);
      setDealerTelephoneNr(dealer.telephoneNr);
      setDealerLogo(dealer.logo);
      setDealerLogoPath(dealer.logoPath);
    }
  };
  useEffect(() => {
    setCurrentStep("Participation");
    initOffer();
  }, [contract]);

  return (
    <>
      {currentStep === "Participation" && <ParticipationStep />}
      {currentStep === "MeineDaten" && <ParticipationDealerDataStep />}
    </>
  );
};

export default ParticipationPromotionDetail;
