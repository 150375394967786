import React from "react";
import EditOfferPanel from "../../../offer/steps/EditOfferPanel";

const PromotionEditOffer = ({
  nrOfOffer,
  navigateToNextStep,
  navigateToPreviousStep,
}) => {
  return (
    <>
      <EditOfferPanel nrOfOffer={nrOfOffer} />
      <div className="row justify-content-end mb-3 mt-2 bottom-row">
        {nrOfOffer === 2 && (
          <div className="col">
            <button
              type="submit"
              className="scb_button"
              onClick={navigateToPreviousStep}
            >
              Zurück
            </button>
          </div>
        )}
        <div className="col-auto">
          <button
            type="submit"
            className="scb_button"
            onClick={() => {
              navigateToNextStep(nrOfOffer);
            }}
          >
            Weiter
          </button>
        </div>
      </div>
    </>
  );
};
export default PromotionEditOffer;
