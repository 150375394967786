import React from "react";
import "./css/offer_title.css";

const EditOfferTitle = () => {
  return (
    <div className="row offer_title">
      <div className="col">
        <h4>Vertragsdetails</h4>
      </div>
    </div>
  );
};

export default EditOfferTitle;
