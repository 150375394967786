import React from "react";
import useScbFormField from "../../utils/form/ScbFormFieldHook";

const MailingInfoPanel = () => {
  const { contract } = useScbFormField("contract");

  const { mailingName, mailingUrl } = contract;
  return (
    <>
      <div className="contract-data-panel">
        <div className="row  no-gutters pt-1">
          <span className="info-title">Mailing an den Kunden:</span>
        </div>
        <div className="row">
          <div className="col">
            {mailingName && mailingUrl ? (
              <a target="_blank" rel="noopener noreferrer" href={mailingUrl}>
                {mailingName}
              </a>
            ) : (
              "Kein Mailing"
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MailingInfoPanel;
