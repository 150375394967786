import React, { useState, useEffect } from "react";
import AmountEditField from "../../../utils/components/AmountEditField";

const AmountAndPercentInputWithLabel = ({
  id,
  label,
  postfixAmount,
  prefixAmount = " €",
  percentBaseValue,
  value,
  onChange,
  disabled = false,
}) => {
  const [focusedComponent, setFocusedComponent] = useState("value");

  const calculatePercent = () => {
    return Math.round((value / percentBaseValue) * 100);
  };
  const calculateValueFromPercent = (percentValue) => {
    return Math.round((percentBaseValue * percentValue) / 100);
  };

  const handlePercentChange = (percentValue) => {
    if (focusedComponent === "percent") {
      onChange(calculateValueFromPercent(percentValue));
    }
  };

  const handleAmountChange = (newValue) => {
    if (focusedComponent === "value") {
      onChange(newValue);
    }
  };

  return (
    <div className="row pt-1 financing-field">
      <div className="col-3 financing-label">
        <label htmlFor={`${id}Percent`}>{label}</label>
      </div>
      <div className="col-4">
        <AmountEditField
          id={`${id}Percent`}
          value={calculatePercent()}
          decimalPlaces={0}
          postfix={"%"}
          prefix={undefined}
          maxLength={3}
          onChange={handlePercentChange}
          disabled={disabled}
          onFocus={(e) => setFocusedComponent("percent")}
        />
      </div>
      <div className="col-5 pl-1">
        <AmountEditField
          id={`${id}Amount`}
          value={value}
          postfix={postfixAmount}
          prefix={prefixAmount}
          onChange={handleAmountChange}
          disabled={disabled}
          fixedDecimalScale
          onFocus={(e) => setFocusedComponent("value")}
        />
      </div>
    </div>
  );
};

export default AmountAndPercentInputWithLabel;
