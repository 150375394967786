import React, { useState } from "react";
import image_angebot_erstellen_logo_hochladen from "./images/hilfe_angebot_erstellen_logo_hochladen.jpg";
import image_angebot_erstellen_uebersicht from "./images/hilfe_angebot_erstellen_uebersicht.jpg";
import image_angebot_erstellen_vertragsdetails from "./images/hilfe_angebot_erstellen_vertragsdetails.jpg";
import image_angebot_erstellen from "./images/hilfe_angebot_erstellen.jpg";
import image_archiv from "./images/hilfe_archiv.jpg";
import image_datendownload from "./images/hilfe_datendownload.jpg";
import image_meine_daten from "./images/hilfe_meine_daten.jpg";
import image_uebersicht from "./images/hilfe_uebersicht.jpg";
import PopupLink from "../utils/components/PopupLink";

const HelpItem = ({ title, image = null, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="row no-gutters">
      <div className="col">
        <a
          href="#"
          className="btn"
          style={{ fontSize: "16px", color: isOpen ? "#ec0000" : "black" }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          &#9658; {title}
        </a>
        {isOpen && (
          <div className="row">
            {image === null ? (
              ""
            ) : (
              <div className="col-6">
                <img className="" width="100%" src={image} />
              </div>
            )}
            <div className="col" style={{ fontSize: "14px" }}>
              <div className="card card-body">{children}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const HelpFrame = () => {
  return (
    <div>
      <div className="row no-gutters pt-4 title">
        <p>Hilfe</p>
      </div>
      <HelpItem title="Übersicht" image={image_uebersicht}>
        <p>
          Die Übersicht zeigt Ihnen alle in{" "}
          <b>6 Monaten auslaufenden Verträge</b> und gibt Ihnen die
          Möglichkeiten, mit den Kunden zu interagieren.
        </p>
        <p>
          Neben der Möglichkeit, bestimmte Verträge zu suchen, können Sie durch
          Klick auf die Überschrift im roten Balken die Einträge auch
          alphabetisch oder numerisch sortieren.
        </p>
        <p>
          Die Verträge unterteilen sich in 3 Kategorien: Angebot, Information
          und Werbesperre. Für die Kunden der Kategorie <b>Angebot</b> können
          Sie ein persönliches Angebot erstellen, welches wir in das Postmailing
          an den Kunden in Ihrem Namen eindrucken.
          <br /> In der Kategorie <b>Information</b> sind jene Verträge, denen
          Sie kein persönliches Angebot über das Santander Kundenmailing machen
          können. Es liegt in Ihrem eigenen Ermessen, mit dem Kunden Kontakt
          bzgl. einer neuen Fahrzeugfinanzierung aufzunehmen.
          <br /> Die Kategorie <b>Werbesperre</b> zeigt Ihnen jene Kunden, bei
          denen die Werbeklausel bei Vertragsabschluss nicht unterzeichnet
          wurde. Wir dürfen Ihnen die Kontaktinformationen zu diesen Kunden
          daher aus rechtlichen Gründen nicht übermitteln.
        </p>
        <p>
          <b>Status Angebot</b>
          <br />
          Wenn sie für einen Kunden noch kein Angebot für den Eindruck in Ihr
          Händlermailing eingegeben haben, wird diese Spalte mit „offen“
          angegeben. Sie haben jeweils bis zum Monatsletzten Zeit, ein Angebot
          zu speichern.
        </p>
        <p>
          Am Monatsbeginn werden die Verträge aus dem Vormonat ins Archiv
          verschoben, neue Verträge, die in 6 Monaten auslaufen werden
          hochgeladen. Sie erhalten ein E-Mail, sobald die neuen Verträge zur
          Bearbeitung zur Verfügung stehen.
        </p>
        <p>
          Mit dem Button <b>Download</b> können alle bzw. die ausgewählten
          Verträge lokal gespeichert werden.
        </p>
      </HelpItem>
      <HelpItem title="Download von Daten" image={image_datendownload}>
        <p>
          Wenn Sie Vertragsinformationen mit dem Button „Download“ lokal
          speichern möchten, erhalten Sie eine .csv-Datei. Um sie leserlich zu
          formatieren, befolgen Sie folgende Schritte:
        </p>
        <p>
          <ol>
            <li>Öffnen Sie die gewünschte .csv-Datei in Excel.</li>
            <li>
              Markieren Sie Spalte "A", klicken Sie auf den Reiter "Daten" und
              auf den Button "Text in Spalten".
            </li>
            <li>
              Der Textkonvertierungsassistent startet. Wählen Sie "Getrennt".
            </li>
            <li>
              Wählen Sie in Schritt 2 das Trennzeichen „Andere“ und geben Sie im
              Feld das Raute-Symbo # ein.
            </li>
            <li>
              In Schritt 3 können Sie das Datenformat der Spalten auswählen.
              Verwenden Sie hier die Option „Standard".
            </li>
            <li>
              Mit einem Klick auf die Schaltfläche "Fertig stellen" beenden Sie
              den Assistenten. Ihre CSV-Datei ist nun in Spalten aufgeteilt. Sie
              können die Datei jetzt als .xls speichern.
            </li>
          </ol>
        </p>
      </HelpItem>
      <HelpItem
        title="Vertragsdetails"
        image={image_angebot_erstellen_vertragsdetails}
      >
        <p>
          Hier sehen sie alle Informationen zu Kunden, Fahrzeug und dem
          Finanzierungsvertrag.
        </p>
        <p>
          Hat der Kunde eine Werbesperre, können Sie keine Kontaktinformationen
          des Kunden sehen
        </p>
      </HelpItem>
      <HelpItem
        title="Erstellung eines persönlichen Angebots – Fahrzeug"
        image={image_angebot_erstellen}
      >
        <p>
          Bei der Erstellung eines Angebots können alle wichtigen Informationen
          für ein neues, gebrauchtes oder Vorführ-Fahrzeug hinterlegt werden. Im
          Feld Sonderausstattung haben Sie Platz für detailierte Beschreibungen.
          Es ist auch möglich, ein Bild hochzuladen. Entweder haben Sie eine
          eigene Aufnahme des Fahrzeugs oder Sie nehmen ein Bild des
          betreffenden Fahrzeug des Herstellers. Wenn das der Fall ist, muss der
          Button <b>Symbolfoto</b> aktiviert werden.{" "}
          <b>Bitte beachten Sie hier sämtliche Rechtsvorschriften</b>{" "}
          (insbesondere Urheberrecht, etc.).
        </p>
        <p>
          Alle gängigen Bildformate können verwendet werden, die Dateigröße
          sollte 2,5 MB nicht übersteigen.
        </p>
        <p>
          Bitte beachten Sie, dass korrekte und vollständige
          Finanzierungsangebote für das angebotene Fahrzeug derzeit noch nicht
          möglich und auch nicht erlaubt sind. Das Angebot richtet sich an
          österreichische Kunden und ist in deutscher Sprache zu verfassen.
        </p>
        <p>
          Mit dem Button <b>Weiter</b> kommen Sie zum nächsten Schritt:{" "}
          <b>Meine-Daten</b>.
        </p>
      </HelpItem>
      <HelpItem
        title="Erstellung eines persönlichen Angebots – Kontaktdaten"
        image={image_angebot_erstellen_logo_hochladen}
      >
        <p>
          Beim Angebot werden auch Ihre Kontaktdaten angedruckt. Grundsätzlich
          sind die Händlerdaten beim Hauptmenü <b>Meine-Daten</b> hinterlegt und
          gelten für alle Angebotslegungen. Möchten Sie aber gesonderte Händler-
          oder Verkäufer-Information zu diesem speziellen Kunden oder Angebot
          hinterlegen, so kann das hier bearbeitet werden.{" "}
          <b>Die Änderungen gelten nur für dieses Angebot!</b>
        </p>
        <p>
          Mit dem Button <b>Weiter</b> gelangt man zum nächsten Schritt:{" "}
          <b>Vorschau</b>.<br />
          <i>
            (Um einen Schritt zurück zu gehen, gibt es den Button <b>Zurück</b>
            .)
          </i>
        </p>
      </HelpItem>
      <HelpItem
        title="Erstellung eines persönlichen Angebots - Vorschau"
        image={image_angebot_erstellen_uebersicht}
      >
        <p>
          Im letzten Schritt bekommen Sie eine <b>Vorschau des Angebotes.</b>
        </p>
        <p>
          Das Angebot inkl. den auf der linken Seite befindlichen Kunden- und
          Vertragsdetails kann mit dem Button <b>DOWNLOAD</b> heruntergeladen
          werden. Zuletzt muss das <b>Angebot</b> mit dem{" "}
          <b>Angebot speichern</b> Button gesichert werden.
          <br />{" "}
          <i>
            (Um einen Schritt zurück zu gehen, gibt es den Button <b>Zurück.</b>
            )
          </i>
        </p>
        Sie können bereits gespeicherte Angebote so lange bearbeiten bis diese
        am Monatsanfang archiviert werden.
      </HelpItem>
      <HelpItem title="Muster-Mailings zur Ansicht">
        <p>
          Je nach Kooperation und Fahrzeug-Marke unterscheiden sich die Mailings
          an den Kunden inhaltlich und optisch. Klicken Sie auf den Link, um ein
          Muster-PDF zu sehen:
        </p>
        <p>
          <ul>
            <li>
              <PopupLink link="https://www.autonline.at/mailings/SAN_Mailing_4seiten_kein-Restwert_OH_RZ.pdf">
                Mailing Santander, der Kunde hat keinen Restwert
              </PopupLink>
            </li>
            <li>
              <PopupLink link="https://www.autonline.at/mailings/Santander_Mailing-3-Moegl_MUT_6s_09072019.pdf">
                Mailing Santander, der Kunde hat einen Restwert
              </PopupLink>
            </li>
            <li>
              <PopupLink link="https://www.autonline.at/mailings/Santander_Mailing-3-Moegl_6s_09072019.pdf">
                Mailing Santander, dem Kunden wird eine
                Restwert-Weiterfinanzierung angeboten{" "}
              </PopupLink>
            </li>
            <li>
              <PopupLink link="https://www.autonline.at/mailings/Santander_VOLVO_Mailing_4s_090719.pdf">
                Mailing Volvo, der Kunde hat keinen Restwert
              </PopupLink>
            </li>
            <li>
              <PopupLink link="https://www.autonline.at/mailings/Santander_VOLVO_Mailing-3-Moegl_6s_09072019.pdf">
                Mailing Volvo, der Kunde hat einen Restwert
              </PopupLink>
            </li>
          </ul>
        </p>
      </HelpItem>
      <HelpItem title="Meine Daten" image={image_meine_daten}>
        <p>
          Alle relevanten Informationen zu Ihrem Unternehmen können unter diesem
          Punkt hinterlegt werden.
        </p>
        <p>
          <strong>Bei Bedarf</strong> können sie <strong>Ihre Daten</strong> für{" "}
          <strong>jedes Angebot individuell anpassen</strong>, wie z.B.:
          Verkäufer, Name und Telefonnummer. Siehe:
          <strong>
            {" "}
            Erstellung eines persönlichen Angebots – Kontaktdaten
          </strong>
        </p>

        <p>
          Zuletzt sollten Änderungen unter dem Button <strong>Speichern</strong>{" "}
          gesichert werden
        </p>
      </HelpItem>
      <HelpItem title="Archiv" image={image_archiv}>
        <p>
          Im Archiv sieht man alle Verträge, die in maximal 6 Monaten auslaufen.
          Hier besteht auch die Möglichkeit einer Detailansicht. Auch ein von
          Ihnen abgespeichertes Angebot können Sie hier nochmal einsehen. Diese
          Datensätze können jedoch nicht mehr bearbeitet werden.
        </p>
        <p>
          Mit dem Button <strong>Download</strong> können Sie alle oder
          ausgewählte archivierten Verträge lokal abspeichern.
        </p>
      </HelpItem>
    </div>
  );
};

export default HelpFrame;
