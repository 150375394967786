import React from "react";
import ParticipationWizardSteps from "./ParticipationWizardSteps";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import { denyParticipation } from "../../../backend/AktionenService";
import { useHistory } from "react-router-dom";

const ParticipationStep = () => {
  const history = useHistory();

  const { setCurrentStep, contract, readonly } = useScbFormField(
    "currentStep",
    "contract",
    "readonly"
  );

  const handleParticipationNotWanted = async () => {
    // save not ok participation
    await denyParticipation(contract.number);
    // navigate back to list
    history.push(
      contract.archived ? "/aktionen/archiv" : "/aktionen/uebersicht"
    );
  };

  const getParticipationStatus = () => {
    switch (contract.participation) {
      case "OK":
        return "Teilnahme";
      case "NOK":
        return "Keine Teilnahme";
      default:
        return "Kein Auswahl getroffen";
    }
  };
  return (
    <>
      <ParticipationWizardSteps activeStepNr="1" />
      <div className="row">
        <div className="col">
        <div className="pt-3 pb-5">
        {!readonly && (
          <p>
            Bitte bestätigen Sie hier, dass dieser Kunde an der Aktion
            teilnehmen soll.
          </p>
        )}
        <p>Derzeit ausgewählt: {`${getParticipationStatus()}`}</p>
      </div>
      <div className="row pt-3">
        {!readonly && (
          <>
            <div className="col">
              <button
                type="submit"
                className="scb_button"
                onClick={handleParticipationNotWanted}
              >
                Keine Teilnahme
              </button>
            </div>
            <div className="col"></div>
            <div className="col-auto">
              <button
                type="submit"
                className="scb_button"
                onClick={() => setCurrentStep("MeineDaten")}
              >
                Teilnahme
              </button>
            </div>
          </>
        )}
         {readonly && (
          <>
            
            <div className="col-auto">
              <button
                type="submit"
                className="scb_button"
                onClick={() => setCurrentStep("MeineDaten")}
              >
                Weiter
              </button>
            </div>
          </>
        )}
      </div>
        </div>
      </div>
     
    </>
  );
};

export default ParticipationStep;
