import React, { useEffect, useContext } from "react";

import PromotionEditOffer from "./PromotionEditOffer";
import PromotionDealerDataStep from "./PromotionDealerDataStep";
import FinancinStep from "../../../offer/steps/financing/FinancinStep";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import { loadDealerData } from "../../../backend/DealerService";
import { LoginContext } from "../../../DataStore";
import useScbForm from "../../../utils/form/ScbFormHook";
import moment from "moment";

const PromotionOfferSteps = ({ nrOfOffer }) => {
  const { selectedDealer } = useContext(LoginContext);
  const {
    currentStep,
    setCurrentStep,
    setActiveStepNr,
    nrOfOffers,
    contract,
  } = useScbFormField("currentStep", "activeStepNr", "nrOfOffers", "contract");

  const {
    [`setId${nrOfOffer ? nrOfOffer : ""}`]: setId,
    [`setCarName${nrOfOffer ? nrOfOffer : ""}`]: setCarName,
    [`setRegistrationDate${nrOfOffer ? nrOfOffer : ""}`]: setRegistrationDate,
    [`setMilage${nrOfOffer ? nrOfOffer : ""}`]: setMilage,
    [`setPrice${nrOfOffer ? nrOfOffer : ""}`]: setPrice,
    [`setDurationOfValidity${
      nrOfOffer ? nrOfOffer : ""
    }`]: setDurationOfValidity,
    [`setExtraEquipment${nrOfOffer ? nrOfOffer : ""}`]: setExtraEquipment,
    [`setOriginalPicture${nrOfOffer ? nrOfOffer : ""}`]: setOriginalPicture,
    [`setPicture${nrOfOffer ? nrOfOffer : ""}`]: setPicture,
    [`setPicturePath${nrOfOffer ? nrOfOffer : ""}`]: setPicturePath,
    setDealerName,
    setDealerStreetAndNr,
    setDealerZipCode,
    setDealerCity,
    setDealerEmail,
    setDealerWebPage,
    setDealerTelephoneNr,
    setDealerLogo,
    setDealerLogoPath,
    [`setFinancingCalculation${
      nrOfOffer ? nrOfOffer : ""
    }`]: setFinancingCalculation,
  } = useScbFormField(
    `id${nrOfOffer ? nrOfOffer : ""}`,
    `carName${nrOfOffer ? nrOfOffer : ""}`,
    `registrationDate${nrOfOffer ? nrOfOffer : ""}`,
    `milage${nrOfOffer ? nrOfOffer : ""}`,
    `price${nrOfOffer ? nrOfOffer : ""}`,
    `durationOfValidity${nrOfOffer ? nrOfOffer : ""}`,
    `extraEquipment${nrOfOffer ? nrOfOffer : ""}`,
    `originalPicture${nrOfOffer ? nrOfOffer : ""}`,
    `picture${nrOfOffer ? nrOfOffer : ""}`,
    `picturePath${nrOfOffer ? nrOfOffer : ""}`,
    `dealerName`,
    `dealerStreetAndNr`,
    `dealerZipCode`,
    `dealerCity`,
    `dealerEmail`,
    `dealerWebPage`,
    `dealerTelephoneNr`,
    `dealerLogo`,
    `dealerLogoPath`,
    `financingCalculation${nrOfOffer ? nrOfOffer : ""}`
  );
  const { validate } = useScbForm();

  const initOffer = async () => {
    if (contract && contract.offers.length > 0) {
      const offer = contract.offers[nrOfOffer - 1];
      if (offer) {
        setId(offer.id);
        setCarName(offer.carName);
        setRegistrationDate(
          offer.registrationDate
            ? moment(offer.registrationDate, "DD.MM.YYYY")
            : undefined
        );
        setMilage(offer.milage);
        setPrice(offer.price);
        setDurationOfValidity(
          offer.durationOfValidity
            ? moment(offer.durationOfValidity, "DD.MM.YYYY")
            : undefined
        );
        setExtraEquipment(offer.extraEquipment);
        setOriginalPicture(offer.originalPicture);
        setPicture(offer.picture);
        setPicturePath(offer.picturePath);
        setDealerName(offer.dealerName);
        setDealerStreetAndNr(offer.dealerStreetAndNr);
        setDealerZipCode(offer.dealerZipCode);
        setDealerCity(offer.dealerCity);
        setDealerEmail(offer.dealerEmail);
        setDealerWebPage(offer.dealerWebPage);
        setDealerTelephoneNr(offer.dealerTelephoneNr);
        setDealerLogo(offer.dealerLogo);
        setDealerLogoPath(offer.dealerLogoPath);
        setFinancingCalculation(offer.financingCalculation);
      }
    } else {
      const dealer = await loadDealerData(selectedDealer);
      setDealerName(dealer.name);
      setDealerStreetAndNr(dealer.streetAndNr);
      setDealerZipCode(dealer.zipCode);
      setDealerCity(dealer.city);
      setDealerEmail(dealer.email);
      setDealerWebPage(dealer.webPage);
      setDealerTelephoneNr(dealer.telephoneNr);
      setDealerLogo(dealer.logo);
      setDealerLogoPath(dealer.logoPath);
    }
  };
  useEffect(() => {
    initOffer();
  }, [contract]);

  return (
    <>
      {currentStep === `Offer_${nrOfOffer}` && (
        <PromotionEditOffer
          navigateToNextStep={() => {
            const errors = validate();
            if (errors.length === 0) {
              setActiveStepNr(nrOfOffer === 1 ? 2 : 4);
              setCurrentStep(`Financing_${nrOfOffer}`);
            }
          }}
          navigateToPreviousStep={() => {
            if (nrOfOffer === 2 && nrOfOffers === 2) {
              setActiveStepNr(2);
              setCurrentStep(`Financing_1`);
            }
          }}
          nrOfOffer={nrOfOffer}
        />
      )}
      {currentStep === `Financing_${nrOfOffer}` && (
        <FinancinStep
          navigateToNextStep={() => {
            setActiveStepNr(nrOfOffer === 1 ? 3 : 5);
            setCurrentStep(nrOfOffer === 1 ? nrOfOffers == 2 ? `Offer_2` : `MeineDaten` : `MeineDaten`);
          }}
          navigateToPreviousStep={() => {
            setActiveStepNr(nrOfOffer === 1 ? 1 : 3);
            setCurrentStep(`Offer_${nrOfOffer}`);
          }}
          nrOfOffer={nrOfOffer}
        />
      )}
      {currentStep === `MeineDaten` && nrOfOffer === nrOfOffers && (
        <PromotionDealerDataStep
          navigateToPreviousStep={() => {
            setActiveStepNr(nrOfOffer === 1 ? 2 : 4);
            setCurrentStep(`Financing_${nrOfOffer}`);
          }}
          nrOfOffer={nrOfOffer}
        />
      )}
    </>
  );
};

export default PromotionOfferSteps;
