export const maxValidator = (maxValue, errorMessage) => {
  return (value) => {
    return value && value > maxValue ? errorMessage : null;
  };
};
export const minValidator = (minValue, errorMessage) => {
  return (value) => {
    return value && value < minValue ? errorMessage : null;
  };
};
export const maxValidatorWithAdditionalData = (maxValueName, errorMessage) => {
  return (value, additionalData) => {
    return value &&
      additionalData &&
      additionalData[maxValueName] != null &&
      additionalData[maxValueName] != undefined &&
      value > additionalData[maxValueName]
      ? errorMessage
      : null;
  };
};
export const callbackValidator = (callBack) => {
  return callBack;
};

export const trueValidator = (errorMessage) => {
  return (value) => {
    return value === true ? null : errorMessage;
  };
};

export const notEmptyValidator = (errorMessage="Darf nicht leer sein") => {
  return (value) => {
    return value ? null : errorMessage;
  };
};

export const amountFormatValidator = (errorMessage) => {
  return regexValidator(/^[0-9]+(,[0-9]{1,2})?$/, errorMessage);
};
export const regexValidator = (regex, errorMessage) => {
  return (value) => {
    const retValue = value && !regex.test(value) ? errorMessage : null;
    return retValue;
  };
};

export const maxLengthValidator = (maxLength, errorMessage) => {
  return (value) => (value && value.length > maxLength ? errorMessage : null);
};
