import useScbForm from "./ScbFormHook";
import { capital } from "../Utils";

const useScbFormField = (...fieldNames) => {
  const { getValue, setValue } = useScbForm();
  const normalizedFieldnames = fieldNames.map((fieldname) => {
    return { fieldname, normalizedFieldname: fieldname.replace(".", "_") };
  });

  const returnObjects = normalizedFieldnames.flatMap((field) => {
    return {
      [field.normalizedFieldname]: getValue(field.fieldname),
      [`set${capital(field.normalizedFieldname)}`]: (value) =>
        setValue(field.fieldname, value),
    };
  });
  let returnObject = {};
  returnObjects.forEach((d) => {
    returnObject = Object.assign(returnObject, d);
  });
  return returnObject;
};

export default useScbFormField;
