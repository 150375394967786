import React, { useEffect } from "react";
import ValidationInputField from "../../utils/components/ValidationInputField";
import DatePickerField from "../../utils/components/DatePickerField";
import ValidationNumericField from "../../utils/components/ValidationNumericField";
import StyledTextArea from "../../utils/components/StyledTextArea";
import ImageUploadPanel from "../../utils/components/ImageUploadPanel";
import { addDays } from "date-fns/esm";
import useScbForm from "../../utils/form/ScbFormHook";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import { notEmptyValidator } from "../../utils/form/ScbFormValidators";

const EditOfferPanel = ({ nrOfOffer }) => {
  
  const { addValidators, getErrors } = useScbForm();

  const {
    [`carName${nrOfOffer ? nrOfOffer : ""}`]: carName,
    [`setCarName${nrOfOffer ? nrOfOffer : ""}`]: setCarName,
    [`registrationDate${nrOfOffer ? nrOfOffer : ""}`]: registrationDate,
    [`milage${nrOfOffer ? nrOfOffer : ""}`]: milage,
    [`price${nrOfOffer ? nrOfOffer : ""}`]: price,
    [`durationOfValidity${nrOfOffer ? nrOfOffer : ""}`]: durationOfValidity,
    [`extraEquipment${nrOfOffer ? nrOfOffer : ""}`]: extraEquipment,
    [`originalPicture${nrOfOffer ? nrOfOffer : ""}`]: originalPicture,
    [`picture${nrOfOffer ? nrOfOffer : ""}`]: picture,
    [`picturePath${nrOfOffer ? nrOfOffer : ""}`]: picturePath,
    [`setRegistrationDate${nrOfOffer ? nrOfOffer : ""}`]: setRegistrationDate,
    [`setMilage${nrOfOffer ? nrOfOffer : ""}`]: setMilage,
    [`setPrice${nrOfOffer ? nrOfOffer : ""}`]: setPrice,
    [`setDurationOfValidity${
      nrOfOffer ? nrOfOffer : ""
    }`]: setDurationOfValidity,
    [`setExtraEquipment${nrOfOffer ? nrOfOffer : ""}`]: setExtraEquipment,
    [`setOriginalPicture${nrOfOffer ? nrOfOffer : ""}`]: setOriginalPicture,
    [`setPicture${nrOfOffer ? nrOfOffer : ""}`]: setPicture,
    [`setPicturePath${nrOfOffer ? nrOfOffer : ""}`]: setPicturePath,
    readonly,
    contract,
    category
  } = useScbFormField(
    `carName${nrOfOffer ? nrOfOffer : ""}`,
    `registrationDate${nrOfOffer ? nrOfOffer : ""}`,
    `milage${nrOfOffer ? nrOfOffer : ""}`,
    `price${nrOfOffer ? nrOfOffer : ""}`,
    `durationOfValidity${nrOfOffer ? nrOfOffer : ""}`,
    `extraEquipment${nrOfOffer ? nrOfOffer : ""}`,
    `originalPicture${nrOfOffer ? nrOfOffer : ""}`,
    `picture${nrOfOffer ? nrOfOffer : ""}`,
    `picturePath${nrOfOffer ? nrOfOffer : ""}`,
    "readonly",
    "contract",
    "category"
  );
  useEffect(()=>{
    if(category === 1) {
        addValidators(
          `carName${nrOfOffer ? nrOfOffer : ""}`,
          notEmptyValidator()
        );
        addValidators(
          `registrationDate${nrOfOffer ? nrOfOffer : ""}`,
          notEmptyValidator()
        );
        addValidators(
          `price${nrOfOffer ? nrOfOffer : ""}`,
          notEmptyValidator()
        );
        addValidators(
          `durationOfValidity${nrOfOffer ? nrOfOffer : ""}`,
          notEmptyValidator()
        );
    }
  },[contract,category])
  return (
    <>
      {category === 1 ? (
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col">
                <ValidationInputField
                  validationErrors={getErrors(
                    `carName${nrOfOffer ? nrOfOffer : ""}`
                  )}
                  name="carName"
                  label="Fahrzeugname"
                  value={carName ?? ""}
                  onChange={setCarName}
                  required="true"
                  maxLength="29"
                  readonly={readonly}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <DatePickerField
                  name="registrationDate"
                  label="Erstzulassung"
                  value={registrationDate}
                  onChange={setRegistrationDate}
                  readonly={readonly}
                  maxDate={Date.now()}
                  validationErrors={getErrors(
                    `registrationDate${nrOfOffer ? nrOfOffer : ""}`
                  )}
                  required
                />
              </div>
              <div className="col-6">
                <ValidationNumericField
                  name="milage"
                  label="Kilometer-Stand"
                  value={milage}
                  onChange={setMilage}
                  validationErrors={getErrors(
                    `milage${nrOfOffer ? nrOfOffer : ""}`
                  )}
                  readonly={readonly}
                  decimalPlaces={0}
                  suffix=" km"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <ValidationNumericField
                  validationErrors={getErrors(
                    `price${nrOfOffer ? nrOfOffer : ""}`
                  )}
                  name="price"
                  label="Kaufpreis"
                  value={price}
                  onChange={setPrice}
                  maxLength="29"
                  readonly={readonly}
                  prefix=" €"
                  required
                  fixedDecimalScale
                />
              </div>
              <div className="col-6">
                <DatePickerField
                  name="durationOfValidity"
                  label="Angebot gültig bis"
                  value={durationOfValidity}
                  onChange={setDurationOfValidity}
                  readonly={readonly}
                  minDate={addDays(new Date(), 42)}
                  validationErrors={getErrors(
                    `durationOfValidity${nrOfOffer ? nrOfOffer : ""}`
                  )}
                  required
                />
              </div>
            </div>
            <div className="row flex-grow-1">
              <div className="col h-100" styles={{ height: "100px" }}>
                <StyledTextArea
                  name="extraEquipment"
                  label="Ausstattung:"
                  nrOfRows="8"
                  nrOfCols="50"
                  placeholder="Ausstattung und weitere Anmerkungen (max. 130 Zeichen)"
                  value={extraEquipment ?? ""}
                  onChange={setExtraEquipment}
                  maxLength="130"
                  readonly={readonly}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <ImageUploadPanel
              uploadButtonLabel="Fahrzeug-Bild hochladen"
              imagePath={picturePath}
              image={picture}
              imageChanged={(picture, picturePath) => {
                setPicture(picture);
                setPicturePath(picturePath);
              }}
              readonly={readonly}
              imageTypeName="Fahrzeug-Bild"
            />
            <div className="row no-gutters pt-1">
              <div className="col">
                <input
                  type="radio"
                  id="sample"
                  name="samplePicture"
                  checked={!originalPicture}
                  onChange={(e) => {
                    setOriginalPicture(!e.target.checked);
                  }}
                  disabled={readonly}
                />
                <label className="pl-3 mr-3 " htmlFor="sample">
                  Symbolfoto
                </label>
                <input
                  type="radio"
                  id="orignal"
                  name="originalPicture"
                  checked={originalPicture ?? false}
                  onChange={(e) => {
                    setOriginalPicture(e.target.checked);
                  }}
                  disabled={readonly}
                />
                <label className="pl-3" htmlFor="orignal">
                  Originalfoto
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-3 pb-5">
          <h4>
            {category === 2
              ? "Bei diesem Vertrag ist kein persönliches Angebot im Mailing möglich."
              : "Bei diesem Vertrag ist kein persönliches Angebot im Mailing möglich. Der Kunde wünscht keine Werbung."}
          </h4>
        </div>
      )}
    </>
  );
};

export default EditOfferPanel;
