import { getFromBackend } from "../utils/ServiceUtils";

export const loadAllMarken = async (objektArt) => {
  return await getFromBackend(`/eurotax/makes?objektcode=${objektArt}`);
};

export const loadModelsForMarke = async (makeNationalCode, objektArt) => {
  return await getFromBackend(
    `/eurotax/models?makeNationalCode=${makeNationalCode}&objektcode=${objektArt}`
  );
};
export const loadTypesForModel = async (modelNationalCode, objektArt) => {
  return await getFromBackend(
    `/eurotax/types?modelNationalCode=${modelNationalCode}&objektcode=${objektArt}`
  );
};
