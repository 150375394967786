import React from "react";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import "../css/edit_offer.css";
const CustomerData = () => {
  const { contract, category } = useScbFormField("contract","category");

  const {
    title,
    firstname,
    lastname,
    streetAndNr,
    zipCode,
    city,
    email,
    telephoneNr,
    mobilephoneNr
  } = contract.customer;

  return (
    <div className="customer-data-panel">
      <div className="row  no-gutters">
        <span className="info-title">Kundendaten:</span>
      </div>
      <div className="row  no-gutters">
        {title} {firstname} &nbsp;
        <span style={{ textDecoration: "underline" }}>{` ${lastname}`}</span>
      </div>
      {category !== 3 ? (
        <div className="">
          <div className="row  no-gutters">{streetAndNr}</div>
          <div className="row  no-gutters">
            {zipCode} {city}
          </div>
          <div className="row  no-gutters">{email}</div>
          <div className="row  no-gutters">{telephoneNr}</div>
          <div className="row  no-gutters">{mobilephoneNr}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomerData;
