import React, { useState } from "react";
const ValidationInputField = ({
  name,
  label,
  value,
  placeholder = "-",
  onChange,
  validationErrors = [],
  required = true,
  maxLength = "100",
  readonly = false,
  email = false,
  web = false,
  phone = false,
}) => {
  return (
    <div className="pb-2 validation-input-field">
      <div className="row no-gutters">
        <label className="label-input">
          {label}
          {required ? <span className="required-input">*</span> : ""}:
        </label>
      </div>
      <div className="row no-gutters d-flex flex-grow-1">
        <div className="col d-flex flex-grow-1">
          {email && (
            <div className="col-auto prefix">
              <div className="small-font">@</div>
            </div>
          )}
          {web && (
            <div className="col-auto prefix">
              <div className="small-font">www</div>
            </div>
          )}
          {phone && (
            <div className="col-auto prefix">
              <i className="fa fa-phone" aria-hidden="true"></i>
            </div>
          )}
          <input
            type="text"
            name={name}
            maxLength={maxLength}
            className={`${
              email || web || phone ? "edit-field-with-prefix" : "edit-field"
            } ${validationErrors.length > 0 && "validation-error"}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={readonly}
          />
        </div>
      </div>
      {validationErrors &&
        validationErrors.map((validationError,idx) => (
          <div key={idx} className="edit-field-validation-text">{validationError}</div>
        ))}
    </div>
  );
};

export default ValidationInputField;
