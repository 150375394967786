import React, { useState, useEffect, useContext } from "react";
import { ContractListItemHelper } from "../backend/domain";
import ContractListTableHeader from "./ContractListTableHeader";
import ContractListTablePagination from "./ContractListTablePagination";
import ContractListHeader from "./ContractListHeader";
import ContractListTableFooter from "./ContractListTableFooter";
import {
  loadContractsList,
  loadCSVLineForContract,
} from "../backend/ContractService";
import ContractRows from "./ContractRows";
import { LoginContext } from "../DataStore";
import { downloadCSVFile } from "../utils/Utils";
import "../assets/css/components.css";
import moment from "moment";
import LoadingOverlay from "../components/LoadingOverlay";
export const csvDownloadHeader =
  "Partnernummer Vermittler#Partnernummer des Kunden#Titel#Vorname#Nachname#Adresse#PLZ#Ort#Geburtsdatum#Telefonnummer#Mobilnummer#Email#Branding des Vertrags#Gruppenzuteilung#Geschäftsfallnummer#Vertragsnummer#Start der Finanzierung#Vertragsende#Produktbezeichnung#Restwert#Rate#Laufzeit in Monaten#Kaufpreis#Anzahlung#Depot#Sollzinssatz#Effektivzinssatz#fix/variabel#Leasingfähigkeit#Fahrgestellnummer#Erstzulassung#NatCode#Type#Nachname des Verkäufers#Vorname des Verkäufers#Fahrzeugname#Erstzulassung#Kilometerstand#Kaufpreis#Angebot gültig bis#Sonderausstattung #Bild#Bildtyp#Händlername#Adresse#PLZ#Ort#Email#Telefonnummer#Händlerlogo#Erstellungsdatum\n";
const ContractList = ({ archive = false }) => {
  const { selectedDealer } = useContext(LoginContext);

  const [overlayActive, setOverlayActive] = useState(false);
  const [overlayText, setOverlayText] = useState("Daten werden geladen...");

  // contracts list
  const [contracts, setContracts] = useState([]);
  const [originalContracts, setOriginalContracts] = useState([]);
  const [contractsForDownload, setContractsForDownload] = useState([]);

  // sorting
  const [currentSortKey, setCurrentSortKey] = useState("name");
  const [currentSortDirection, setCurrentSortDirection] = useState("ASC");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // filter
  const [filter, setFilter] = useState({
    name: null,
    contractNumber: null,
    endDate: null,
    offerStatus: null,
    advertStatus: null,
  });

  // navigate to another page
  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  // get just the contracts for current page
  const getContractsForCurrentPage = () => {
    return contracts.slice(
      pageSize * (currentPage - 1),
      pageSize * currentPage
    );
  };

  const sortBy = (sortKey) => {
    // if same sort key than just switch direction
    var sortDirection;
    if (sortKey === currentSortKey) {
      sortDirection = currentSortDirection === "ASC" ? "DESC" : "ASC";
    } else {
      // switch sort key and set direction by default auf asc
      sortDirection = "ASC";
    }
    setCurrentSortKey(sortKey);
    setCurrentSortDirection(sortDirection);
    var comparator = ContractListItemHelper.getSortComparator(
      sortKey,
      sortDirection
    );
    const sortedContracts = contracts.sort(comparator);
    setContracts(sortedContracts);
    setOriginalContracts(contracts);
  };
  const handleFilterEndDateChanged = (date) => {
    filter.endDate = date;
    filterAndSortedContractsList();
  };
  const filterAndSortedContractsList = () => {
    var filteredContracts = originalContracts;
    // filter by name if available
    if (filter.name) {
      filteredContracts = filteredContracts.filter((contract) =>
        ContractListItemHelper.getCustomerFullName(contract)
          .trim()
          .toLowerCase()
          .includes(filter.name.trim().toLowerCase())
      );
    }
    if (filter.salesPersonName) {
      filteredContracts = filteredContracts.filter((contract) =>
        contract.salesPersonName
          .trim()
          .toLowerCase()
          .includes(filter.salesPersonName.trim().toLowerCase())
      );
    }
    if (filter.contractNumber) {
      filteredContracts = filteredContracts.filter((contract) =>
        contract.number.toString().startsWith(filter.contractNumber)
      );
    }
    if (filter.product) {
      filteredContracts = filteredContracts.filter((contract) =>
        contract.productName
          .toLowerCase()
          .includes(filter.product.toLowerCase())
      );
    }
    if (filter.offerStatus) {
      switch (filter.offerStatus) {
        case "offen":
          filteredContracts = filteredContracts.filter(
            (contract) =>
              !contract.offerAvailable && !contract.blockedForAdvertising
          );
          break;
        case "gespeichert":
          filteredContracts = filteredContracts.filter(
            (contract) =>
              contract.offerAvailable && !contract.blockedForAdvertising
          );
          break;
      }
    }
    //Values: alle,angebot,info,advBlock
    if (filter.category !== "alle") {
      switch (filter.category) {
        case "angebot":
          filteredContracts = filteredContracts.filter(
            (contract) =>
              !contract.blockedForAdvertising && contract.offerPossible
          );
          break;
        case "info":
          filteredContracts = filteredContracts.filter(
            (contract) =>
              !contract.blockedForAdvertising && !contract.offerPossible
          );
          break;
        case "advBlock":
          filteredContracts = filteredContracts.filter(
            (contract) => contract.blockedForAdvertising
          );
          break;
      }
    }
    if (filter.endDate) {
      filteredContracts = filteredContracts.filter(
        (contract) =>
          moment(contract.endDate, "DD.MM.YYYY").toDate() >= filter.endDate
      );
    }
    setContracts(filteredContracts);
  };
  const handleFilterChanged = (e) => {
    switch (e.target.name) {
      case "nameFilter":
        filter.name = e.target.value;
        break;
      case "contractNrFilter":
        filter.contractNumber = e.target.value;
        break;
      case "salesPersonNameFilter":
        filter.salesPersonName = e.target.value;
        break;
      case "residualValueFilter":
        filter.residualValue = e.target.value;
        break;
      case "filterOfferStatus":
        filter.offerStatus = e.target.value;
        break;
      case "categoryFilter":
        filter.category = e.target.value;
        break;
      case "productFilter":
        filter.product = e.target.value;
        break;
    }
    filterAndSortedContractsList();
  };
  // calculate if all the contracts are selected for download
  const isContractsForDownloadChecked = () => {
    if (contracts.length == 0) return false;

    var allSelected = true;

    for (
      var i = pageSize * (currentPage - 1);
      i < contracts.length && i < pageSize * currentPage;
      i++
    ) {
      // add all page to the selected list
      var numberStr = "" + contracts[i].number;
      if (allSelected && !contractsForDownload.includes(numberStr)) {
        allSelected = false;
      }
    }
    return allSelected;
  };
  const handleDownloadChange = (e) => {
    var selectedArray = [...contractsForDownload];
    if (e.target.name === "downloadAll") {
      if (e.target.checked) {
        for (
          var i = pageSize * (currentPage - 1);
          i < pageSize * currentPage && i < contracts.length;
          i++
        ) {
          // add all page to the selected list
          var numberStr = "" + contracts[i].number;
          if (!selectedArray.includes(numberStr)) {
            selectedArray.push(numberStr);
          }
        }
      } else {
        // remove all from selection
        selectedArray = [];
      }
    } else {
      if (e.target.checked) {
        if (!selectedArray.includes(e.target.value)) {
          selectedArray.push(e.target.value);
        }
      } else {
        var index = selectedArray.indexOf(e.target.value);
        if (index >= 0) {
          selectedArray.splice(index, 1);
        }
      }
    }
    setContractsForDownload(selectedArray);
  };
  useEffect(() => {
    // load the contracts into "contracts" and sort it by name
    if (selectedDealer !== null && selectedDealer !== undefined) {
      setOverlayText("Es wird nach Verträgen gesucht.");
      setOverlayActive(true);
      loadContractsList(selectedDealer, archive)
        .then((contracts) => {
          setContracts(
            contracts.sort(
              ContractListItemHelper.getSortComparator(
                currentSortKey,
                currentSortDirection
              )
            )
          );
          setOriginalContracts(contracts);
          setOverlayActive(false);
        })
        .catch((err) => {
          setOverlayActive(false);
          // show error in the screan
        });
    }
  }, [selectedDealer]);

  // generate 1 csv line for a contract
  const generateCSVLine = async (contractNr) => {
    // if it was selected than create a csv line
    return await loadCSVLineForContract(contractNr);
  };
  const doDownloadContracts = async () => {
    // add header line
    var csv = csvDownloadHeader;
    // add one row for each
    for (let i = 0; i < contractsForDownload.length; i++) {
      csv += await generateCSVLine(contractsForDownload[i]);
    }
    downloadCSVFile("scb_vertraege.csv", csv);
  };
  // generates the csv file and downloads it
  const downloadSelectedContracts = () => {
    setOverlayActive(true);
    setOverlayText("Datei mit Verträge wird vorbereitet!");
    doDownloadContracts()
      .then(() => {
        setOverlayActive(false);
      })
      .catch((error) => {
        setOverlayActive(false);
      });
  };
  return (
    <LoadingOverlay active={overlayActive} text={overlayText}>
      <div className="pt-3">
        <ContractListHeader archive={archive} />
        <div className="">
          <table className="table table-sm mt-3 table-contracts">
            <ContractListTableHeader
              currentSortKey={currentSortKey}
              currentSortDirection={currentSortDirection}
              sortBy={sortBy}
              handleFilterChanged={handleFilterChanged}
              handleEndDateFilterChanged={handleFilterEndDateChanged}
              dateFilter={filter.endDate}
              handleDownloadAllChange={handleDownloadChange}
              contractsForDownloadChecked={isContractsForDownloadChecked()}
            />

            <ContractRows
              contractsForPage={getContractsForCurrentPage()}
              contractsForDownload={contractsForDownload}
              handleDownloadChange={handleDownloadChange}
              archive={archive}
            />
            <tfoot>
              <tr>
                <td
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  colSpan="8"
                >
                  <ContractListTableFooter
                    numberOfElements={contracts.length}
                    pageSize={pageSize}
                    pageNumber={currentPage}
                  />
                  <ContractListTablePagination
                    currentPage={currentPage}
                    pageCount={Math.ceil(contracts.length / pageSize)}
                    navigateToPage={navigateToPage}
                    downloadClicked={downloadSelectedContracts}
                    contractsForDownload={contractsForDownload}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default ContractList;
