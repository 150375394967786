import {
  getFromBackend,
  postToBackend,
  getFromBackendRaw,
} from "../utils/ServiceUtils";

// loads all contracts from server
export const loadContractsList = async (dealerNumber, archived) => {
  return await getFromBackend(
    `/contractList?dealerNumber=${dealerNumber}&archived=${archived}`
  );
};

export const loadContractService = async (contractNr) => {
  return await getFromBackend(`/contract?number=${contractNr}`);
};
export const loadCSVLineForContract = async (contractNr) => {
  return await getFromBackend(`/contractCSV?number=${contractNr}`);
};
export const saveContractOffer = async (contractNr, offer) => {
  return postToBackend("/offer", { contractNr, offer });
};

export const exportOffers = async () => {
  return getFromBackendRaw("/offer/export");
};
export const exportPromotionOffers = async (promotionId) => {
  return getFromBackendRaw(
    `/promotion/offer/export?promotionId=${promotionId}`
  );
};

