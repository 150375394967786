import React from "react";
import useScbFormField from "../../utils/form/ScbFormFieldHook";

const OfferPreviewNoOffer = () => {
  const {
    carName,
    registrationDate,
    milage,
    price,
    durationOfValidity,
    extraEquipment,
    originalPicture,
    picture,
    dealerName,
    dealerStreetAndNr,
    dealerZipCode,
    dealerCity,
    dealerEmail,
    dealerWebPage,
    dealerTelephoneNr,
    dealerLogo,
    dealerLogoPath,
    category,
  } = useScbFormField(
    "carName",
    "registrationDate",
    "milage",
    "price",
    "durationOfValidity",
    "extraEquipment",
    "originalPicture",
    "picture",
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo",
    "dealerLogoPath",
    "category"
  );
  return (
    <>
      <div className="col-8 info-text">
        <span className="align-middle">
          {`Gerade jetzt bietet der Markt viele interessante, neue Modelle
                für Sie. Oder wäre vielleicht ein gebrauchtes Fahrzeug für Sie
                interessant? Vereinbaren Sie doch einfach einen Termin bei Ihrem
                Händler und lassen Sie sich individuell beraten.`}
        </span>
      </div>
      <div className="col">
        <div className="row">
          <div className="col">
            <img
              className="preview-dealer-logo"
              src={dealerLogo ? dealerLogo : null}
              alt=""
            />
          </div>
        </div>
        <div className="row pt-1">
          <div className="col-auto ">
            <div style={{ fontWeight: "bold" }}>{dealerName}</div>
            <div>{dealerStreetAndNr}</div>
            <div>{`${dealerZipCode}, ${dealerCity}`}</div>
            <div>{dealerTelephoneNr}</div>
            <div>{dealerEmail}</div>
            <div>{dealerWebPage}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferPreviewNoOffer;
