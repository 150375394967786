import { formatFullName } from "../utils/FormatUtils";
import {
  sortDatesAsc,
  sortDatesDesc,
  sortBooleanAsc,
  sortBooleanDesc,
  sortNumbersAsc,
  sortNumbersDesc,
} from "../utils/SortUtils";

export class ContractListItemHelper {
  static getCustomerFullName(contract) {
    return formatFullName(
      contract.customerFirstName !== null ? contract.customerFirstName : "",
      contract.customerLastName
    );
  }
  static getSortComparator(sortKey, sortDirection) {
    switch (sortKey) {
      case "aktion":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByPromotionNameASC
          : ContractListItemHelper.compareByPromotionNameDESC;
      case "rueckmeldungBis":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByRueckmeldungBisASC
          : ContractListItemHelper.compareByRueckmeldungBisDESC;
      case "name":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByNameASC
          : ContractListItemHelper.compareByNameDESC;
      case "contractNr":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByContractNrASC
          : ContractListItemHelper.compareByContractNrDESC;
      case "product":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByProductASC
          : ContractListItemHelper.compareByProductDESC;
      case "salesPersonName":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareBySalesPersonNameASC
          : ContractListItemHelper.compareBySalesPersonNameDESC;
      case "endDate":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByEndDateASC
          : ContractListItemHelper.compareByEndDateDESC;
      case "offerAvailable":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByOfferExistASC
          : ContractListItemHelper.compareByOfferExistDESC;
      case "blockedForAdvertising":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByBlockedForAdvertisingASC
          : ContractListItemHelper.compareByBlockedForAdvertisingDESC;
      case "residualValue":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByResidualValueASC
          : ContractListItemHelper.compareByResidualValueDESC;
      case "category":
        return sortDirection === "ASC"
          ? ContractListItemHelper.compareByCategoryASC
          : ContractListItemHelper.compareByCategoryDESC;

      default:
        return ContractListItemHelper.compareByContractNrASC;
    }
  }
  // sort comparators
  // end date
  static compareByEndDateASC(c1, c2) {
    return sortDatesAsc(c1.endDate, c2.endDate);
  }
  static compareByEndDateDESC(c1, c2) {
    return sortDatesDesc(c1.endDate, c2.endDate);
  }

  static compareByRueckmeldungBisASC(c1, c2) {
    return sortDatesAsc(c1.promotion.validUntil, c2.promotion.validUntil);
  }
  static compareByRueckmeldungBisDESC(c1, c2) {
    return sortDatesDesc(c1.promotion.validUntil, c2.promotion.validUntil);
  }

  // blockedForAdvertising
  static compareByBlockedForAdvertisingASC(c1, c2) {
    return sortBooleanAsc(c1.blockedForAdvertising, c2.blockedForAdvertising);
  }
  static compareByBlockedForAdvertisingDESC(c1, c2) {
    return sortBooleanDesc(c1.blockedForAdvertising, c2.blockedForAdvertising);
  }
  // offerAvailable
  static compareByOfferExistASC(c1, c2) {
    return sortBooleanAsc(c1.offerAvailable, c2.offerAvailable);
  }
  static compareByOfferExistDESC(c1, c2) {
    return sortBooleanDesc(c1.offerAvailable, c2.offerAvailable);
  }
  // category
  static compareByCategoryASC(c1, c2) {
    return sortNumbersAsc(getCategoryRank(c1), getCategoryRank(c2));
  }
  static compareByCategoryDESC(c1, c2) {
    return ContractListItemHelper.compareByCategoryASC(c1, c2) * -1;
  }
  // name
  // name
  static compareByPromotionNameASC(c1, c2) {
    return c1.promotion.name.localeCompare(c2.promotion.name);
  }
  static compareByPromotionNameDESC(c1, c2) {
    return ContractListItemHelper.compareByPromotionNameASC(c1, c2) * -1;
  }

  static compareByNameASC(c1, c2) {
    return ContractListItemHelper.getCustomerFullName(c1).localeCompare(
      ContractListItemHelper.getCustomerFullName(c2)
    );
  }
  static compareByNameDESC(c1, c2) {
    return ContractListItemHelper.compareByNameASC(c1, c2) * -1;
  }
  static compareBySalesPersonNameASC(c1, c2) {
    return c1.salesPersonName.localeCompare(c2.salesPersonName);
  }
  static compareBySalesPersonNameDESC(c1, c2) {
    return ContractListItemHelper.compareBySalesPersonNameASC(c1, c2) * -1;
  }

  static compareByProductASC(c1, c2) {
    return c1.productName.localeCompare(c2.productName);
  }
  static compareByProductDESC(c1, c2) {
    return ContractListItemHelper.compareByProductASC(c1, c2) * -1;
  }

  // contract nr
  static compareByContractNrDESC(c1, c2) {
    return ContractListItemHelper.compareByContractNrASC(c1, c2) * -1;
  }
  static compareByContractNrASC(c1, c2) {
    return c1.number > c2.number ? 1 : c1.number === c2.number ? 0 : -1;
  }
  // residualValue
  static compareByResidualValueDESC(c1, c2) {
    return sortNumbersDesc(c1.residualValue, c2.residualValue);
  }
  static compareByResidualValueASC(c1, c2) {
    return sortNumbersAsc(c1.residualValue, c2.residualValue);
  }
}
function getCategoryRank(contract) {
  return contract.offerPossible && contract.blockedForAdvertising
    ? 3
    : !contract.offerPossible && contract.blockedForAdvertising
    ? 2
    : 1;
}
export class ContractListItem {
  constructor(
    number,
    endDate,
    residualValue,
    customerFirstName,
    customerLastName,
    blockedForAdvertising,
    offerAvailable = false,
    salesPersonName,
    offerPossible
  ) {
    this.number = number;
    this.endDate = endDate;
    this.residualValue = residualValue;
    this.customerFirstName = customerFirstName;
    this.customerLastName = customerLastName;
    this.blockedForAdvertising = blockedForAdvertising;
    this.offerAvailable = offerAvailable;
    this.salesPersonName = salesPersonName;
    this.offerPossible = offerPossible;
    this.category = this.blockedForAdvertising ? 3 : this.offerPossible ? 1 : 2;
  }
}

// full contract data
export class Contract {
  constructor(
    number /* Vertragsnr */,
    endDate /* Vertragsende */,
    residualValue /* Restwert */,
    duration /* Laufzeit */,
    purchasePrice /* Kaufpreis */,
    downPayment /* Anzahlung */,
    installment /* Rate */,
    salesPersonName,
    offerPossible,
    product,
    customer /* Marktpartnerdaten */,
    car /* KFZ Objektdaten */,
    offer /* Angebot vorhanden */,
    startDate /*Vertragsbeginn*/,
    depot /* Depot*/,
    interest /* Sollzinssatz */,
    interestType,
    effectiveInterest /* Effektivzinssatz */,
    applicationNr /* GF Nummer*/
  ) {
    this.number = number;
    this.endDate = endDate;
    this.residualValue = residualValue;
    this.duration = duration;
    this.purchasePrice = purchasePrice;
    this.downPayment = downPayment;
    this.installment = installment;
    this.salesPersonName = salesPersonName;
    this.offerPossible = offerPossible;
    this.product = product;
    this.customer = customer;
    this.car = car;
    this.offer = offer;
    this.startDate = startDate;
    this.depot = depot;
    this.interest = interest;
    this.interestType = interestType;
    this.effectiveInterest = effectiveInterest;
    this.applicationNr = applicationNr;
  }
}
// car information
export class Car {
  constructor(
    type /* Marke + Modell */,
    nationalCode /* Nationalcode */,
    chassisNr /* Fahrgestellnummer */,
    registrationDate,
    leaseable
  ) {
    this.type = type;
    this.nationalCode = nationalCode;
    this.chassisNr = chassisNr;
    this.registrationDate = registrationDate;
    this.leaseable = leaseable;
  }
}
// customer extended data
export class Customer {
  constructor(
    title,
    firstname,
    lastname,
    streetAndNr,
    zipCode,
    city,
    email,
    telephoneNr,
    mobilephoneNr
  ) {
    this.title = title;
    this.firstname = firstname;
    this.lastname = lastname;
    this.streetAndNr = streetAndNr;
    this.zipCode = zipCode;
    this.city = city;
    this.email = email;
    this.telephoneNr = telephoneNr;
    this.mobilephoneNr = mobilephoneNr;
  }
  getFullName() {
    return formatFullName(this.firstname, this.lastname);
  }
}
// product information
export class Product {
  constructor(name) {
    this.name = name;
  }
}
export class Dealer {
  constructor(
    number,
    name,
    streetAndNr,
    zipCode,
    city,
    email,
    telephoneNr,
    webPage,
    logo,
    logoPath
  ) {
    this.number = number;
    this.name = name;
    this.streetAndNr = streetAndNr;
    this.zipCode = zipCode;
    this.city = city;
    this.email = email;
    this.telephoneNr = telephoneNr;
    this.webPage = webPage;
    this.logo = logo;
    this.logoPath = logoPath;
  }
}
export class Offer {
  constructor(
    carName,
    registrationDate,
    milage,
    price,
    durationOfValidity,
    extraEquipment,
    originalPicture,
    picture,
    picturePath,
    previousContract,
    dealerNumber,
    dealerName,
    dealerStreetAndNr,
    dealerZipCode,
    dealerCity,
    dealerEmail,
    dealerTelephoneNr,
    dealerLogo,
    dealerLogoPath
  ) {
    this.carName = carName;
    this.registrationDate = registrationDate;
    this.milage = milage;
    this.price = price;
    this.durationOfValidity = durationOfValidity;
    this.extraEquipment = extraEquipment;
    this.originalPicture = originalPicture;
    this.picture = picture;
    this.picturePath = picturePath;
    this.previousContract = previousContract;
    this.dealerNumber = dealerNumber;
    this.dealerName = dealerName;
    this.dealerStreetAndNr = dealerStreetAndNr;
    this.dealerZipCode = dealerZipCode;
    this.dealerCity = dealerCity;
    this.dealerEmail = dealerEmail;
    this.dealerTelephoneNr = dealerTelephoneNr;
    this.dealerLogo = dealerLogo;
    this.dealerLogoPath = dealerLogoPath;
  }
}
export class DivisionHead {
  constructor(number, firstname, lastname, email, telephoneNr) {
    this.number = number;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.telephoneNr = telephoneNr;
  }
}
export class UserProfile {
  constructor(user, agbAcceptedOn) {
    this.user = user;
    this.agbAcceptedOn = agbAcceptedOn;
  }
}
