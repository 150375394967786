import React from "react";
import "../css/edit_offer.css";
import {
  formatAmount,
  getLastDayOdPreviousMonth,
  getLastDayOdPreviousQuarter,
  getCondition,
} from "../../utils/FormatUtils";
import { PRODUCT_TYPE_OL } from "../../utils/Constants";
import useScbFormField from "../../utils/form/ScbFormFieldHook";

const ContractData = () => {
  const { contract, category } = useScbFormField("contract","category");

  const {
    number /* Vertragsnummer */,
    product /*Produkt*/,
    startDate /*Vertragsbeginn*/,
    endDate /* Vertragsende */,
    residualValue /* Restwert */,
    purchasePrice /* Kaufpreis */,
    downPayment /* Anzahlung */,
    installment /* Rate */,
    duration /* Laufzeit */,
    depot /* Depot*/,
    interest /* Sollzinssatz */,
    effectiveInterest /* Effektivzinssatz */,
    interestType,
    condition,
    unpaidInterestAmount,
  } = contract;

  return (
    <div className="contract-data-panel">
      <div className="row  no-gutters pt-1">
        <span className="info-title">Vertragsdaten:</span>
      </div>
      <div className="row">
        <div className="col">
          <ContractDataItem label="Vertragsnummer:" value={number} splitRows />
        </div>
        <div className="col">
          <ContractDataItem label="Produkt:" value={product.name} splitRows />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ContractDataItem label="Zustand:" value={getCondition(condition)} />
        </div>
      </div>
      <div className="row">
        {category !== 3 && (
          <div className="col">
            <ContractDataItem
              label="Vertragsbeginn:"
              value={startDate}
              date
              splitRows
            />
          </div>
        )}
        <div className="col">
          <ContractDataItem
            label="Vertragsende:"
            value={endDate}
            date
            splitRows
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ContractDataItem label="Kaufpreis:" value={purchasePrice} price />
        </div>
        <div className="col">
          <ContractDataItem label="Anzahlung:" value={downPayment} price />
        </div>
      </div>
      <div className="row">
        {category !== 3 && (
          <div className="col">
            <ContractDataItem label="Depot:" value={depot} price />
          </div>
        )}
        <div className="col">
          <ContractDataItem label="Laufzeit:" value={duration} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ContractDataItem label="Rate:" value={installment} price />
        </div>
        {product.type !== PRODUCT_TYPE_OL ? (
          <div className="col">
            <ContractDataItem label="Restwert:" value={residualValue ?? ""} price />
          </div>
        ) : ""}
      </div>
      {category !== 3 && product.type !== PRODUCT_TYPE_OL && (
        <div className="row">
          <div className="col">
            <ContractDataItem
              label="Sollzins.:"
              value={`${formatAmount(interest)} % ${interestType ?? ""}`}
            />
          </div>
          <div className="col">
            <ContractDataItem
              label="Eff-Zins.:"
              value={`${formatAmount(effectiveInterest)} % ${interestType ?? ""}`}
            />
          </div>
        </div>
      )}
      <div className="row no-gutters">
        {product.type === "LOAN" && unpaidInterestAmount && (
          <ContractDataItem
              label={`Offener Saldo per ${getLastDayOdPreviousMonth()} zzgl. anfallender Zinsen seit ${getLastDayOdPreviousQuarter()}:`}
              value={`${formatAmount(unpaidInterestAmount)}`}
            />
        )}
        {product.type === "LEASING" && unpaidInterestAmount && (
          <ContractDataItem
              label={`Offener Saldo per ${getLastDayOdPreviousMonth()}:`}
              value={`${formatAmount(unpaidInterestAmount)}`}
            />
        )}
      </div>
    </div>
  );
};

export const ContractDataItem = ({
  label,
  value,
  price = false,
  date = false,
  splitRows = false,
}) => {
  const getFormatedText = () => {
    if (value === null || value === undefined || value === 0) return "";
    if (price) return formatAmount(value);
    if (date) {
      return value;
    }
    return value;
  };

  return splitRows ? (
    <div className="">
      <div className="row no-gutters">
        <div className="col-auto">
          <label className="label_input">{label}</label>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col">
          <span className="">{getFormatedText()}</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="row no-gutters">
      <div className="col-auto">
        <label className="label_input">{label}</label>
      </div>
      <div className="col">
        <span className="pl-1">{getFormatedText()}</span>
      </div>
    </div>
  );
};
export default ContractData;
