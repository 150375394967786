import React from "react";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import moment from "moment";

const DatePickerField = ({
  name,
  label,
  value,
  onChange,
  readonly = false,
  minDate = null,
  maxDate = null,
  required = false,
  validationErrors = [],
}) => {
  const getSelectedValue = () => {
    return value ? moment(value, "DD.MM.YYYY").toDate() : value;
  };
  return (
    <div className="date-piker-field">
      {label ? (
        <div className="row no-gutters">
          <label htmlFor={name} className="label-input">
            {label}
            {required ? <span className="required-input">*</span> : ""}:
          </label>
        </div>
      ) : (
        ""
      )}

      <div
        className={`row no-gutters ${
          validationErrors.length > 0 && "validation-error"
        }`}
      >
        <div className={`col`}>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            placeholderText="TT.MM.YYYY"
            selected={getSelectedValue()}
            locale={de}
            disabledKeyboardNavigation
            onChange={(date) => onChange(date)}
            isClearable={!readonly}
            disabled={readonly}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            shouldCloseOnSelect
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <div className="col-auto postfix">
          <i className="fa fa-calendar" aria-hidden="true" />
        </div>
      </div>
      {validationErrors &&
        validationErrors.map((validationError, idx) => (
          <div key={idx} className="edit-field-validation-text">
            {validationError}
          </div>
        ))}
    </div>
  );
};

export default DatePickerField;
