import React, { useState, useContext, useEffect } from "react";
import {
  uploadGBLDataService,
  uploadDealerDataService,
  uploadPromotionsService,
  uploadContractsService,
} from "../backend/AdminService";
import InfoPanel from "../utils/InfoPanel";
import { exportOffers, exportPromotionOffers } from "../backend/ContractService";
import { downloadZIPFile } from "../utils/Utils";
import { LoginContext } from "../DataStore";
import AdminMailingsConfigPanel from "./AdminMailingsConfigPanel";
import {
  withLoadingOverlay,
  LoadingOverlayContext,
} from "../components/LoadingOverlayContext";
import SelectWithLabel from "../offer/steps/financing/SelectWithLabel";
import { loadAllPromotions } from "../backend/PromotionsService";

const AdminFrame = () => {
  const [infoMessage, setInfoMessage] = useState();
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [promotions, setPromotions] = useState([]);

  const { loginSession } = useContext(LoginContext);
  const { setBlocking, setOverlayText } = useContext(LoadingOverlayContext);
  const handleInfoDismiss = () => {
    setInfoMessage();
  };
  const prepareMessage = (message, result) => {
    var returnMessage = message;
    if (result && result.length > 0) {
      returnMessage += " -- Fehler:";
      result.forEach((error) => (returnMessage += " ## " + error + "\n"));
    }
    return returnMessage;
  };
  const loadPromotions = async () => {
    setBlocking(true);
    try {
      const promos = await loadAllPromotions();
      setPromotions(
        promos?.map((p) => {
          return { value: p.id, text: p.name };
        }) ?? []
      );
    } catch (err) {
      setInfoMessage({
        level: "ERROR",
        message: `Ein Fehler ist beim ladon vom Aktionen aufgetretten.`,
      });
    } finally {
      setBlocking(false);
    }
  };
  useEffect(() => {
    loadPromotions();
  }, []);
  const uploadGBL = (data) => {
    setBlocking(true);
    setOverlayText("Gebietsleiter daten werden importiert!");
    uploadGBLDataService(data, loginSession.token)
      .then((result) => {
        setBlocking(false);
        setInfoMessage({
          level: "INFO",
          message: prepareMessage(
            "Gebietsleiterdaten erfolgreich importiert!",
            result
          ),
        });
      })
      .catch((error) => {
        setBlocking(false);
        setInfoMessage({
          level: "ERROR",
          message: `Fehler beim GBL import: ${error.response.data.message}`,
        });
      });
  };
  const uploadDealer = (data) => {
    setBlocking(true);
    setOverlayText("Händlerdaten werden importiert!");
    uploadDealerDataService(data, loginSession.token)
      .then((result) => {
        setBlocking(false);
        setInfoMessage({
          level: "INFO",
          message: prepareMessage(
            "Händlerdaten erfolgreich importiert!",
            result
          ),
        });
      })
      .catch((error) => {
        setBlocking(false);
        setInfoMessage({
          level: "ERROR",
          message: `Fehler beim Händler import: ${error.response.data.message}`,
        });
      });
  };
  const uploadContracts = (data) => {
    setBlocking(true);
    setOverlayText("Verträge werden importiert!");
    uploadContractsService(data, loginSession.token)
      .then((result) => {
        setBlocking(false);
        setInfoMessage({
          level: "INFO",
          message: prepareMessage("Verträge erfolgreich importiert!", result),
        });
      })
      .catch((error) => {
        setBlocking(false);
        setInfoMessage({
          level: "ERROR",
          message: `Fehler beim Vertragimport: ${error.response.data.message}`,
        });
      });
  };
  const uploadPromotions = (data) => {
    setBlocking(true);
    setOverlayText("Aktionen werden importiert!");
    uploadPromotionsService(data, loginSession.token)
      .then((result) => {
        setBlocking(false);
        setInfoMessage({
          level: "INFO",
          message: prepareMessage("Aktionen erfolgreich importiert!", result),
        });
      })
      .catch((error) => {
        setBlocking(false);
        setInfoMessage({
          level: "ERROR",
          message: `Fehler beim Aktionenimport: ${error.response.data.message}`,
        });
      });
  };
  const exportOffersClicked = () => {
    setBlocking(true);
    setOverlayText("Angebote werden exportiert. Bitte warten!");
    exportOffers()
      .then((response) => {
        const filename = response.headers["content-disposition"].split(
          "filename="
        )[1];
        if (response.data && response.data !== null) {
          downloadZIPFile(filename, response.data);
          setBlocking(false);
          setInfoMessage({
            level: "INFO",
            message: "Angebote erfolgreich exportiert!",
          });
        } else {
          setBlocking(false);
          setInfoMessage({
            level: "INFO",
            message: "Keine Angebote zu exportieren!",
          });
        }
      })
      .catch((error) => {
        setBlocking(false);
        setInfoMessage({
          level: "ERROR",
          message: `Fehler beim exportieren`,
        });
      });
  };

  const exportAktionenClicked = async () => {
    setBlocking(true);
    setOverlayText("Aktion wird exportiert. Bitte warten!");
    try {
      const response = await exportPromotionOffers(selectedPromotion);
      const filename = response.headers["content-disposition"].split(
        "filename="
      )[1];
      if (response.data && response.data !== null) {
        downloadZIPFile(filename, response.data);
        setBlocking(false);
        setInfoMessage({
          level: "INFO",
          message: "Aktionsangebote erfolgreich exportiert!",
        });
      } else {
        setBlocking(false);
        setInfoMessage({
          level: "INFO",
          message: "Keine Angebote zu exportieren!",
        });
      }
    } catch (error) {
      console.error(error)
      setInfoMessage({
        level: "ERROR",
        message: `Fehler beim exportieren von Aktionsangebote.`,
      });
    } finally {
      setBlocking(false);
    }
  };
  return (
    <>
      <InfoPanel info={infoMessage} handleDismiss={handleInfoDismiss} />
      <div className="card mt-3">
        <div className="card-header" id="headingDataUpload">
          <h5 className="mb-0">Daten hochladen</h5>
        </div>
        <div className="card-body">
          <FileUploadComponent
            uploadLabel="GBL-Daten aktualisieren:"
            name="uploadGBLData"
            uploadFile={uploadGBL}
          />
          <FileUploadComponent
            uploadLabel="Händlerdaten aktualisieren:"
            name="uploadDealerData"
            uploadFile={uploadDealer}
          />
          <FileUploadComponent
            uploadLabel="Verträge importieren:"
            name="uploadContracts"
            uploadFile={uploadContracts}
          />
          <FileUploadComponent
            uploadLabel="Aktionen importieren:"
            name="uploadPromotione"
            uploadFile={uploadPromotions}
          />
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header" id="headingDataUpload">
          <h5 className="mb-0">Angebote herunterladen</h5>
        </div>
        <div className="card-body">
          <button className="scb_button" onClick={exportOffersClicked}>
            Herunterladen
          </button>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header" id="headingDataUpload">
          <h5 className="mb-0">Aktionsangebote herunterladen</h5>
        </div>
        <div className="card-body">
          <SelectWithLabel
            id="admin.download.promotions.list"
            options={promotions}
            value={selectedPromotion}
            placeholder={"Bitte ein Aktion auswählen"}
            label="Aktion:"
            onChange={(e) => setSelectedPromotion(e.target.value)}
            labelClass={"col-1"}
            selectClass={"col-4"}
          />
          <div className="row pt-3">
            <div className="col">
              <button
                className="scb_button"
                onClick={exportAktionenClicked}
                disabled={!selectedPromotion}
              >
                Herunterladen
              </button>
            </div>
          </div>
        </div>
      </div>
      <AdminMailingsConfigPanel />
    </>
  );
};

const FileUploadComponent = ({ name, uploadLabel, uploadFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const handleselectedFile = (event) => {
    var file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    setSelectedFile(formData);
    setSelectedFileName(file.name);
  };
  const handleUpload = () => {
    uploadFile(selectedFile);
    setSelectedFile(null);
    setSelectedFileName(null);
  };
  return (
    <div className="row pt-1">
      <div className="col-4">{uploadLabel}</div>

      {selectedFileName !== null ? (
        <div className="col-auto">
          <button onClick={handleUpload}>Upload</button>
          {` Datei: ${selectedFileName}`}
        </div>
      ) : (
        <div className="col-auto">
          <label htmlFor={name} className="upload-button">
            Datei auswählen
          </label>
          <input
            className="default-upload-button"
            type="file"
            name={name}
            id={name}
            accept=".csv"
            onChange={handleselectedFile}
          />
        </div>
      )}
    </div>
  );
};
export default withLoadingOverlay(AdminFrame);
