import React from "react";
import "./css/contract_list_table.css";

const ContractListTablePagination = ({
  currentPage,
  pageCount,
  navigateToPage,
  downloadClicked,
  contractsForDownload
}) => {
  const generatePreviousLink = () => {
    return (
      <li className="page-item">
        <button
          className="table_page_button"
          disabled={currentPage === 1}
          onClick={e => navigateToPage(currentPage - 1)}
        >
          &laquo;
        </button>
      </li>
    );
  };
  const generateNextLink = () => {
    return (
      <li className="page-item ">
        <button
          className="table_page_button"
          disabled={currentPage >= pageCount}
          onClick={e => navigateToPage(currentPage + 1)}
        >
          &raquo;
        </button>
      </li>
    );
  };
  const generatePageLinks = () => {
    const pageLinks = [];
    for (var i = 1; i <= pageCount; i++) {
      pageLinks.push(
        <li key={i} className="page-item ">
          <button
            className={
              i === currentPage
                ? "table_page_button_selected"
                : "table_page_button"
            }
            value={i}
            onClick={e => navigateToPage(parseInt(e.target.value), 10)}
          >
            {i}
          </button>
        </li>
      );
    }
    return pageLinks;
  };

  return (
    <div className="row no-gutters pt-2">
      <div className="col-auto">
        {contractsForDownload.length > 0 ? (
          <button className="scb_button" onClick={downloadClicked}>
            Download
          </button>
        ) : (
          <button disabled className="scb_button" onClick={downloadClicked}>
            Download
          </button>
        )}
      </div>
      <div className="col">
        <div className="row no-gutters justify-content-center">
          <nav aria-label="Contracts Pagination">
            <ul className="pagination">
              {generatePreviousLink()}
              {generatePageLinks()}
              {generateNextLink()}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ContractListTablePagination;
