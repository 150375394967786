import React, { useEffect, useState } from "react";
import {
  loadAllMarken,
  loadModelsForMarke,
  loadTypesForModel,
} from "../../../backend/EurotaxService";
import useScbFormField from "../../../utils/form/ScbFormFieldHook";
import useScbForm from "../../../utils/form/ScbFormHook";
import SelectWithLabel from "./SelectWithLabel";
import moment from "moment";

const ObjektArten = [
  {
    value: "PKW",
    text: "PKW / klein LKW (steuerr. PKW)",
  },
  {
    value: "MOTORRAD",
    text: "Motorrad",
  },
  {
    value: "LCV",
    text: "LKW bis 3,5t",
  },
];
const FinancingEurotaxPanel = ({
  calcData,
  setCalcData,
  nrOfOffer,
  handleTypeChange,
}) => {
  const { setBlocking, addFormError } = useScbForm();
  const {
    [`registrationDate${nrOfOffer ? nrOfOffer : ""}`]: registrationDate,
  } = useScbFormField(`registrationDate${nrOfOffer ? nrOfOffer : ""}`);

  const [marken, setMarken] = useState([]);
  const [models, setModels] = useState([]);
  const [types, setTypes] = useState([]);

  const loadAllMarkenData = async () => {
    setBlocking(true);
    try {
      const allMarken = await loadAllMarken(calcData?.objektart);
      if (allMarken) {
        setMarken(
          allMarken
            .map((marke) => {
              return { value: marke.nationalCode, text: marke.name };
            })
            .sort(function (a, b) {
              if (a.text < b.text) {
                return -1;
              }
              if (a.text > b.text) {
                return 1;
              }
              return 0;
            })
        );
      }
    } catch (err) {
      addFormError("Eurotax Marken wurden nicht erfolgreich geladen!");
    } finally {
      setBlocking(false);
    }
  };

  const loadModelsData = async () => {
    setBlocking(true);
    try {
      const modelsForMarke = await loadModelsForMarke(
        calcData?.selectedMarke,
        calcData?.objektart
      );
      if (modelsForMarke) {
        const modelsForMarkeFiltered = modelsForMarke
          .filter(
            (model) =>
              !model.importBegin ||
              registrationDate >= new Date(model.importBegin)
          )
          .filter((model) => {
            if (!model.importEnd) return true;
            const maxImportYear = moment(model.importEnd)
              .day(1)
              .add(7, "months")
              .toDate();
            return registrationDate < maxImportYear;
          });
        setModels(
          modelsForMarkeFiltered.map((model) => {
            return { value: model.nationalCode, text: model.name };
          })
        );
      }
    } catch (err) {
      console.error(err);
      addFormError("Eurotax Modele wurden nicht erfolgreich geladen!");
    } finally {
      setBlocking(false);
    }
  };
  const loadTypesData = async () => {
    setBlocking(true);
    try {
      const typesForModel = await loadTypesForModel(
        calcData?.selectedModel,
        calcData?.objektart
      );

      if (typesForModel) {
        const typesForModelFiltered = typesForModel
          .filter(
            (type) =>
              !type.importBegin ||
              registrationDate >= new Date(type.importBegin)
          )
          .filter((type) => {
            if (!type.importEnd) return true;
            const maxImportYear = moment(type.importEnd)
              .day(1)
              .add(7, "months")
              .toDate();
            return registrationDate < maxImportYear;
          });
        setTypes(
          typesForModelFiltered.map((type) => {
            return { value: type.nationalCode, text: type.name };
          })
        );
      }
    } catch (err) {
      addFormError("Eurotax Typen wurden nicht erfolgreich geladen!");
    } finally {
      setBlocking(false);
    }
  };

  const handleChangeObjektart = (objektart) => {
    setCalcData({
      ...calcData,
      objektart,
      selectedMarke: undefined,
      selectedMarkeName: undefined,
      selectedModel: undefined,
      selectedModelName: undefined,
      selectedEurotaxType: undefined,
      selectedEurotaxTypeName: undefined,
    });
  };
  const handleChangeMarke = (e) => {
    setCalcData({
      ...calcData,
      selectedMarke: e.target.value,
      selectedMarkeName: e.target.selectedOptions[0]?.text,
      selectedModel: undefined,
      selectedModelName: undefined,
      selectedEurotaxType: undefined,
      selectedEurotaxTypeName: undefined,
    });
  };
  const handleChangeModel = (e) => {
    setCalcData({
      ...calcData,
      selectedModel: e.target.value,
      selectedModelName: e.target.selectedOptions[0]?.text,
      selectedEurotaxType: undefined,
      selectedEurotaxTypeName: undefined,
    });
  };
  const handleChangeType = async (e) => {
    setCalcData(() => {
      return {
        ...calcData,
        selectedEurotaxType: e.target.value,
        selectedEurotaxTypeName: e.target.selectedOptions[0]?.text,
      };
    });
    await handleTypeChange(e.target.value);
  };

  const initData = async () => {
    if (calcData?.objektart) await loadAllMarkenData();
    if (calcData?.selectedMarke) await loadModelsData();
    else setModels([]);
    if (calcData?.selectedModel) await loadTypesData();
    else setTypes([]);
  };
  useEffect(() => {
    initData();
  }, [calcData?.selectedMarke, calcData?.selectedModel, calcData?.objektart]);

  return (
    <div>
      <SelectWithLabel
        id="eurotax.objektart.select"
        options={ObjektArten}
        placeholder="Bitte ein Objektart auswählen"
        value={calcData?.objektart}
        label="Objektart"
        onChange={(e) => handleChangeObjektart(e.target.value)}
      />
      <SelectWithLabel
        id="eurotax.make.select"
        options={marken}
        value={calcData?.selectedMarke}
        placeholder="Bitte die Marke auswählen"
        label="Marke"
        onChange={(e) => handleChangeMarke(e)}
        disabled={marken.length === 0}
      />
      <SelectWithLabel
        id="eurotax.model.select"
        options={models}
        value={calcData?.selectedModel}
        placeholder={
          calcData?.selectedMarke
            ? "Bitte ein Modell auswählen"
            : "Zuerst eine Marke auswählen"
        }
        label="Modell"
        onChange={(e) => handleChangeModel(e)}
        disabled={!calcData?.selectedMarke}
      />
      <SelectWithLabel
        id="eurotax.type.select"
        options={types}
        value={calcData?.selectedEurotaxType}
        placeholder={
          calcData?.selectedModel
            ? "Bitte ein Typ auswählen"
            : "Zuerst ein Modell auswählen"
        }
        label="Typ"
        onChange={(e) => handleChangeType(e)}
        disabled={!calcData?.selectedModel}
      />
    </div>
  );
};

export default FinancingEurotaxPanel;
