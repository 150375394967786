import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "../../components/LoadingOverlay";

export const ScbFormContext = React.createContext();

const ScbFormProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);
  const [infos, setInfos] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successLabel, setSuccessLabel] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [formData, setFormData] = useState({});
  const [fieldValidationErrors, setFieldValidationErrors] = useState([]);
  const [fields, setFields] = useState([]);
  const [submitErrorCallback, setSubmitErrorCallback] = useState(() => {});
  const [submitCallback, setSubmitCallback] = useState(() => {});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [overlayText,setOverlayText] = useState("Daten werden geladen");
  const addInfo = (text, light = false, closable = false) => {
    setInfos((oldInfos) => [...oldInfos, { text, light, closable }]);
  };
  const addError = (error, values, transalatable=true) => {
    setErrors((oldErrors) => [...oldErrors, { error, values, transalatable }]);
    setIsSuccess(false);
  };
  const resetInfos = () => {
    setInfos([]);
  };
  const resetErrors = () => {
    setErrors([]);
  };
  const markSuccess = () => {
    setIsSuccess(true);
    resetErrors();
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      addError,
      markSuccess,
    })
  );

  return (
    <ScbFormContext.Provider
      value={{
        isSubmitted,
        setIsSubmitted,
        submitCallback,
        setSubmitCallback,
        submitErrorCallback,
        setSubmitErrorCallback,
        fields,
        setFields,
        fieldValidationErrors,
        setFieldValidationErrors,
        addError,
        resetErrors,
        markSuccess,
        setSuccessLabel,
        setBlocking,
        addInfo,
        resetInfos,
        formData,
        setFormData,
        setIsSuccess,
      }}
    >
      <LoadingOverlay active={blocking} spinner text={overlayText}>
        {isSuccess ? (
          <Success labelIdSuccess={successLabel} onClose={() => setIsSuccess(false)} />
        ) : (
          <Errors errors={errors} onClose={resetErrors} />
        )}
        <Infos infos={infos} onClose={resetInfos} />
        {childrenWithProps}
      </LoadingOverlay>
    </ScbFormContext.Provider>
  );
};
const Success = ({ labelIdSuccess = "Empty.Placeholder", onClose }) => {
  return (
    <div className="pt-3">
      <div className={`alert alert-success alert-success-san`}>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        {labelIdSuccess}
      </div>
    </div>
  );
};

Success.propTypes = {
  labelIdSuccess: PropTypes.string,
};
const Infos = ({ infos = [], onClose }) =>
  infos.length > 0 && (
    <div className="pt-3">
      <div className="alert alert-info alert-info-san alert-dismissible fade show">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        {infos.map((e, idx) => (
          <div key={idx}>
            {e.info}
          </div>
        ))}
      </div>
    </div>
  );

const Errors = ({ errors = [], onClose }) =>
  errors.length > 0 && (
    <div className="pt-3">
      <div className="alert alert-danger alert-danger-san alert-dismissible fade show">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        {errors.map((e, idx) => (
          <div key={idx}>
            {
              e.error
            }
          </div>
        ))}
      </div>
    </div>
  );

Errors.propTypes = {
  errors: PropTypes.array,
  errorsWithValues: PropTypes.array,
};

export const panelWithErrorMessageChildrenPropTypes = {
  setErrors: PropTypes.func,
  setIsSuccess: PropTypes.func,
};

export const panelWithErrorMessageChildrenDefaultPropTypes = {
  setErrors: () => {},
  setIsSuccess: () => {},
};

ScbFormProvider.propTypes = {
  labelIdSuccess: PropTypes.string,
  children: PropTypes.node,
};

export const withScbForm = (WrappedElement) => (
  propsForWrapper,
  propsForElement
) => (
  <ScbFormProvider {...propsForWrapper}>
    <WrappedElement {...propsForElement} />
  </ScbFormProvider>
);

export default ScbFormProvider;
