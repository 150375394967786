import React from "react";
import "../css/edit_offer.css";
import { ContractDataItem } from "./ContractData";
import useScbFormField from "../../utils/form/ScbFormFieldHook";

const CarData = () => {
  const { contract, category } = useScbFormField("contract","category");

  const { car /* KFZ Objektdaten */ } = contract;
  const {
    type /* Marke + Modell */,
    nationalCode /* Nationalcode */,
    chassisNr /* Fahrgestellnummer */,
    registrationDate,
    leaseable
  } = car;
  return (
    <div className="contract-data-panel">
      <div className="row  no-gutters pt-1">
        <span className="info-title">Fahrzeugdaten:</span>
      </div>

      <ContractDataItem label="Fahrzeug:" value={`${type}`} />
      {category !== 3 && (
        <ContractDataItem label="NAT Code:" value={nationalCode} />
      )}
      <ContractDataItem label="Fgst.-Nr:" value={chassisNr} />
      {category !== 3 && (
        <div>
          <ContractDataItem label="Erstzulassung:" value={registrationDate} />
          <ContractDataItem
            label={`Leasingfähig für: `}
            value={
              !leaseable || leaseable.length === 0
                ? ""
                : `${leaseable} Monate`
            }
          />
        </div>
      )}
    </div>
  );
};
export default CarData;
