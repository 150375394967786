import React from "react";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import { formatDate } from "../../utils/FormatUtils";
import FinancingResultPreviewPanel from "./financing/FinancingResultPreviewPanel";

const OfferPreviewWithOfferAndFinancing = () => {
    const {
        financingCalculation
      } = useScbFormField(
        `financingCalculation`,
      );

  const {
    carName,
    registrationDate,
    milage,
    price,
    durationOfValidity,
    extraEquipment,
    originalPicture,
    picture,
    dealerName,
    dealerStreetAndNr,
    dealerZipCode,
    dealerCity,
    dealerEmail,
    dealerWebPage,
    dealerTelephoneNr,
    dealerLogo
  } = useScbFormField(
    "carName",
    "registrationDate",
    "milage",
    "price",
    "durationOfValidity",
    "extraEquipment",
    "originalPicture",
    "picture",
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo"
  );
  return (
    <>
      <div className="col-6">
        <div className="row">
          <div className="col-auto">
            <img className="preview-car-photo" src={picture} alt="" />
            <div>{originalPicture ? "Originalfoto" : "Symbolfoto"}</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="preview-title">{`${carName}`}</span>
          </div>
        </div>
        <div className="row  pt-3">
          <div className="col">
            {registrationDate === null || registrationDate === undefined ? (
              ""
            ) : (
              <span className="preview-line">{`Erstzulassung: ${formatDate(
                registrationDate
              )}`}</span>
            )}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            {milage === null || milage === undefined ? (
              ""
            ) : (
              <span className="preview-line">{`Kilometerstand: ${
                milage ? milage.toLocaleString("de-DE") : ""
              } km`}</span>
            )}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <span className="preview-line">{`Kaufpreis: ${
              price ? price.toLocaleString("de-DE") : ""
            } EUR`}</span>
          </div>
        </div>
        <div className="row ">
          <div className="col">
            {extraEquipment === null || extraEquipment === undefined ? (
              ""
            ) : (
              <span className="preview-line">{`Ausstattung: ${extraEquipment}`}</span>
            )}
          </div>
        </div>
        <div className="row ">
          <div className="col">
            {durationOfValidity === null || durationOfValidity === undefined ? (
              ""
            ) : (
              <span className="preview-line">{`Angebot gültig bis: ${formatDate(
                durationOfValidity
              )}`}</span>
            )}
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <img
              className="preview-dealer-logo"
              src={dealerLogo ? dealerLogo : null}
              alt=""
            />
          </div>
        </div>
        <div className="row pt-1">
          <div className="col-auto ">
            <div style={{ fontWeight: "bold" }}>{dealerName}</div>
            <div>{`${dealerStreetAndNr}, ${dealerZipCode}, ${dealerCity}`}</div>
            <div>{dealerTelephoneNr}</div>
            <div>{dealerEmail}</div>
            <div>{dealerWebPage}</div>
          </div>
        </div>
      </div>
      <div className="col">
        <FinancingResultPreviewPanel calcResult={financingCalculation}/>
      </div>
    </>
  );
};

export default OfferPreviewWithOfferAndFinancing;
