import React from "react";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import { formatDate } from "../../utils/FormatUtils";
import FinancingResultPreviewPanel from "./financing/FinancingResultPreviewPanel";

const OfferPreviewWithOfferWithoutFinancing = () => {
    const {
        financingCalculation
      } = useScbFormField(
        `financingCalculation`,
      );

  const {
    carName,
    registrationDate,
    milage,
    price,
    durationOfValidity,
    extraEquipment,
    originalPicture,
    picture,
    dealerName,
    dealerStreetAndNr,
    dealerZipCode,
    dealerCity,
    dealerEmail,
    dealerWebPage,
    dealerTelephoneNr,
    dealerLogo
  } = useScbFormField(
    "carName",
    "registrationDate",
    "milage",
    "price",
    "durationOfValidity",
    "extraEquipment",
    "originalPicture",
    "picture",
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo"
  );
  return (
    <>
     <div className="col">
        <div className="row">
          <div className="col-auto">
            <img className="preview-car-photo" src={picture} alt="" />
            <div>{originalPicture ? "Originalfoto" : "Symbolfoto"}</div>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <span className="preview-title">{`${carName}`}</span>
              </div>
            </div>
            <div className="row  pt-3">
              <div className="col">
                {registrationDate === null || registrationDate === undefined ? (
                  ""
                ) : (
                  <span className="preview-line">{`Erstzulassung: ${formatDate(
                    registrationDate
                  )}`}</span>
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col">
                {milage === null || milage === undefined ? (
                  ""
                ) : (
                  <span className="preview-line">{`Kilometerstand: ${
                    milage ? milage.toLocaleString("de-DE") : ""
                  } km`}</span>
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <span className="preview-line">{`Kaufpreis: ${
                  price ? price.toLocaleString("de-DE") : ""
                } EUR`}</span>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                {extraEquipment === null || extraEquipment === undefined ? (
                  ""
                ) : (
                  <span className="preview-line">{`Ausstattung: ${extraEquipment}`}</span>
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col">
                {durationOfValidity === null ||
                durationOfValidity === undefined ? (
                  ""
                ) : (
                  <span className="preview-line">{`Angebot gültig bis: ${formatDate(
                    durationOfValidity
                  )}`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    <div className="col-4">
          <div className="row no-gutters">
            <div className="col ">
              <img
                className="preview-dealer-logo float-right"
                src={dealerLogo ? dealerLogo : null}
                alt=""
              />
            </div>
          </div>
          <div className="row no-gutters justify-content-end pt-1">
            <div className="col-auto ">
              <div
                className="align-self-end"
                align="right"
                style={{ fontWeight: "bold" }}
              >
                {dealerName}
              </div>
              <div className="align-self-end" align="right">
                {dealerStreetAndNr}
              </div>
              <div className="align-self-end" align="right">
                {`${dealerZipCode}, ${dealerCity}`}
              </div>
              <div className="align-self-end" align="right">
                {dealerTelephoneNr}
              </div>
              <div className="align-self-end" align="right">
                {dealerEmail}
              </div>
              <div className="align-self-end" align="right">
                {dealerWebPage}
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default OfferPreviewWithOfferWithoutFinancing;
