import { postToBackend, getFromBackend } from "../utils/ServiceUtils";

export const uploadGBLDataService = async (data) => {
  return await postToBackend("/upload/gbl", data);
};
export const uploadDealerDataService = async (data) => {
  return await postToBackend("/upload/dealer", data);
};
export const uploadContractsService = async (data) => {
  return await postToBackend("/upload/contracts", data);
};
export const uploadPromotionsService = async (data) => {
  return await postToBackend("/upload/promotions", data);
};
export const loadMailingConfigurations = async () => {
  return await getFromBackend("/mailing/configurations");
};

export const deleteMailingConfiguration = async (id) => {
  return await postToBackend(`/mailing/configuration/delete?id=${id}`);
};

export const saveMailingConfiguration = async (
  id,
  mailingId,
  mailingName,
  mailingUrl
) => {
  return await postToBackend("/mailing/configuration", {
    id,
    mailingId,
    description: mailingName,
    url: mailingUrl,
  });
};
