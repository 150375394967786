import { getFromBackend, postToBackend } from "../utils/ServiceUtils";

export const doFinancingCalculation = async (
  calcData,
  pos_account_type,
  dealerCustomerNr
) => {
  const result = await postToBackend(
    `/financing/calculation?pos_account_type=${pos_account_type}&dealer_customer_nr=${dealerCustomerNr}`,
    calcData
  );
  return result;
};

export const getBackgroundData = async (pos_account_type, dealerCustomerNr) => {
  return await getFromBackend(
    `/financing/backgroundData?pos_account_type=${pos_account_type}&dealer_customer_nr=${dealerCustomerNr}`
  );
};

