import React from "react";

const PopupLink = ({ link, children }) => {
  return (
    <a href="#" onClick={() => window.open(link)}>
      {children}
    </a>
  );
};

export default PopupLink;
