import React from "react";
import "./css/info_panel.css";
import error_img from "./img/error_icon.png";

const InfoPanel = ({ info, handleDismiss }) => {
  const getClassForLavel = () => {
    switch (info.level) {
      case "INFO":
        return "info-panel-info";
      case "ERROR":
        return "info-panel-error";
    }
  };
  const hasMessage = () => {
    return !(
      info === null ||
      info === undefined ||
      info.message === undefined ||
      info.message === null ||
      info.message.length === 0
    );
  };
  return hasMessage() ? (
    <div className=" row ">
      <div className="col-auto info-panel">
        <div className={getClassForLavel() + " "}>
          {info.message}
          <button
            className="ml-1"
            type="button"
            style={{ border: "solid 1px black", borderRadius: "50%" }}
            onClick={handleDismiss}
          >
            X
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default InfoPanel;
