import React, { useState, useImperativeHandle, forwardRef } from "react";


const NewValidationInputField = forwardRef(
  (
    {
      handleHasErrors,
      name,
      label,
      defaultValue,
      placeholder = "-",
      valueChanged,
      required = "true",
      maxLength = "100",
      validationFunction = null,
      readonly = false,
    },
    ref
  ) => {
    const [validationError, setValidationError] = useState();

    const validateValue = (targetName, value) => {
      // validate and check result
      const validationResult = localValidate(value);
      const validationError =
        typeof validationResult === "string" ? validationResult : null;
      handleHasErrors(targetName, validationError !== null);
      setValidationError(validationError);
      return validationError;
    };

    useImperativeHandle(ref, () => ({
      isValueValid(value) {
        return validateValue(name, value) === null;
      },
    }));

    const localValueChanged = (value, event) => {
      event.persist();
      valueChanged(value, event);
      validateValue(event.target.name, value);
    };
    const onFocusLost = (e) => {
      validateValue(e.target.name, e.target.value);
    };
    const localValidate = (value) => {
      // check if required
      if (required == "true" && (value === null || value.length === 0))
        return "Darf nicht leer sein!";
      // if validationFunc passed than call it
      if (validationFunction !== null) return validationFunction(value);
      // ellse all ok!
      return true;
    };
    return (
      <div className="pb-2">
        <div className="row no-gutters">
          <label className="label-input">{label}:</label>
        </div>
        <div className="row no-gutters d-flex flex-grow-1">
          <div className="col d-flex flex-grow-1">
            <input
              type="text"
              name={name}
              maxLength={maxLength}
              className="edit-field-validation d-flex flex-grow-1"
              placeholder={placeholder}
              value={defaultValue}
              onChange={(e) => localValueChanged(e.target.value, e)}
              onBlur={onFocusLost}
              disabled={readonly}
            />
          </div>
        </div>
        <div className="edit-field-validation-text">
          {validationError === null || validationError === undefined
            ? ""
            : validationError}
        </div>
      </div>
    );
  }
);

export default NewValidationInputField;
