import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../DataStore";
import { DivisionHead } from "../backend/domain";
import { loadDevisionHead } from "../backend/DealerService";
import PopupLink from "../utils/components/PopupLink";

const ContactFrame = () => {
  const {
    loginSession,
    selectedDealer,
   } = useContext(LoginContext);

  const [divisionhead, setDivisionHead] = useState(new DivisionHead());

  useEffect(() => {
    // load GBL data for dealer
    if (selectedDealer !== null && selectedDealer !== undefined)
      loadDevisionHead(selectedDealer, loginSession.token)
        .then(dh => setDivisionHead(dh))
        .catch(error => {});
  }, [selectedDealer]);
  
  return (
    <div>
      <div className="row no-gutters pt-4 title">
        <p>Kontakt</p>
      </div>
      <div
        className="row no-gutters"
        style={{ fontSize: "16px", fontStyle: "bold" }}
      >
        <p>
          Bei Fragen zu bestehenden Finanzierungen wenden Sie sich an unser
          Dealer Service:{" "}
        </p>
      </div>
      <div className="row no-gutters" style={{ fontSize: "14px" }}>
        Tel.: 05 0203 1350
      </div>
      <div className="row no-gutters" style={{ fontSize: "14px" }}>
        Fax: 05 0203 1896
      </div>
      <div className="row no-gutters" style={{ fontSize: "14px" }}>
        E-Mail: dealerservice@santanderconsumer.at
      </div>
      <div className="row no-gutters pt-2" style={{ fontSize: "14px" }}>
        <p>Mo – Fr 8.00 – 17.00 Uhr</p>
      </div>
      {loginSession.isGBL ? (
        ""
      ) : (
        <div>
          <div className="row no-gutters">
            <p style={{ fontSize: "16px", fontStyle: "bold" }}>
              Selbstverständlich steht Ihnen Ihr Gebietsleiter der Santander
              Consumer Bank auch jederzeit zur Verfügung:{" "}
            </p>
          </div>
          <div className="row no-gutters" style={{ fontSize: "14px" }}>
            {`${divisionhead.firstname} ${divisionhead.lastname}`}
          </div>
          <div className="row no-gutters" style={{ fontSize: "14px" }}>
            {`Tel.: ${divisionhead.telephoneNr}`}
          </div>
          <div className="row no-gutters" style={{ fontSize: "14px" }}>
            {" "}
            {`E-Mail: ${divisionhead.email}`}
          </div>
        </div>
      )}
      <div className="row no-gutters pt-3">
        <PopupLink link="https://www.autonline.at/impressum-offenlegung">
          Impressum
        </PopupLink>
      </div>
    </div>
  );
};

export default ContactFrame;
