import React, { useState } from "react";


import App from "./App";
import LoadingOverlay from "./components/LoadingOverlay";

const AppLoader = () => {
  const [overlayActive, setOverlayActive] = useState(true);

  return (
    <LoadingOverlay active={overlayActive} text="Sie werden eingeloggt">
      {overlayActive && <div style={{ height: "100vh", padding: "0px" }}></div>}
      <App setOverlayActive={setOverlayActive} />
    </LoadingOverlay>
  );
};

export default AppLoader;
