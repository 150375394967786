import React, { useEffect, useContext, useState } from "react";
import { LoadingOverlayContext } from "../components/LoadingOverlayContext";
import AdminMailingConfigNewPanel from "./AdminMailingConfigNewPanel";
import { loadMailingConfigurations, deleteMailingConfiguration } from "../backend/AdminService";
const AdminMailingsConfigPanel = () => {
  const { setBlocking, setOverlayText } = useContext(LoadingOverlayContext);

  const [mailings, setMailings] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [selectedMailing, setSelectedMailing] = useState();

  const handleDelete = (m) => {
    const r = window.confirm(`Sind Sie sicher das sie das Mailing mit ID: "${m.mailingId}" löschen wollen?`); 
    if(r == true){ 
      deleteMailing(m)
    }

  };

  const deleteMailing = async (m)=> {
    setOverlayText("Mailing-Konfiguration wird gelöscht")
    setBlocking(true);
    try {
      await deleteMailingConfiguration(m.id);
      await loadMailings();
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(()=>{})
      setBlocking(false);
    }
  }

  const loadMailings = async () => {
    setBlocking(true);
    try {
      //load mailing configurations
      const mailingConfigurations = await loadMailingConfigurations();
      if (mailingConfigurations) setMailings(mailingConfigurations);
      setSelectedMailing(null);
    } finally {
      setBlocking(false);
    }
  };

  useEffect(() => {
    loadMailings();
  }, []);

  return (
    <div className="card mt-3">
      <div className="card-header" id="headingDataUpload">
        <h5 className="mb-0">
          {`Mailing Konfiguration  `}
          {!showNew && (
            <button
              className="pl-3 scb_button"
              type="button"
              onClick={() => {
                setSelectedMailing(null);
                setShowNew(true);
              }}
            >
              Neue Konfiguration
            </button>
          )}
        </h5>
      </div>
      <div className="card-body">
        {showNew && (
          <AdminMailingConfigNewPanel
            mailing={selectedMailing}
            callbackEditFinished={async () => {
              await loadMailings();
              setShowNew(false);
            }}
            callbackEditCanceled={() => {
              setShowNew(false);
            }}
          />
        )}
        <table className="table table-sm mt-3 table-contracts">
          <thead>
            <tr className="table_header">
              <th scope="col">Id</th>
              <th scope="col">Text Anzeige</th>
              <th scope="col">Url</th>
              <th scope="col">Aktionen</th>
            </tr>
          </thead>
          <tbody className="table-hover">
            {mailings.map((mailing, idx) => (
              <tr key={idx} className="table_row">
                <td>{mailing.mailingId}</td>
                <td>{mailing.description}</td>
                <td>{mailing.url}</td>
                <td>
                  <div className="row">
                    <div className="col-6">
                      <button
                        className="scb_button_small pl-3 pt-1"
                        type="button"
                        onClick={() => {
                          setSelectedMailing(mailing);
                          setShowNew(true);
                        }}
                      >
                        Editieren
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="pl-3 pt-1 scb_button_small"
                        type="button"
                        onClick={() => handleDelete(mailing)}
                      >
                        Löschen
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminMailingsConfigPanel;
