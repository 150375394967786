import React from "react";
import "../css/offer_preview.css";
import OfferWizardSteps from "../OfferWizardSteps";
import { formatDate } from "../../utils/FormatUtils";
import { isValueSet } from "../../utils/Utils";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import useScbForm from "../../utils/form/ScbFormHook";
import OfferPreviewNoOffer from "./OfferPreviewNoOffer";
import OfferPreviewWithOfferWithoutFinancing from './OfferPreviewWithOfferWithoutFinancing'
import OfferPreviewWithOfferAndFinancing from './OfferPreviewWithOfferAndFinancing'

const OfferPreviewStep = ({
  downloadOffer,
  navigateToPreviousStep,
  readonly,
}) => {
  const { submitForm } = useScbForm(
    "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal."
  );

  const {
    financingCalculation,
    carName,
    category,
  } = useScbFormField(
    `financingCalculation`,
    "carName",
    "category"
  );

  return (
    <div className="preview-panel pl-3">
      <div className="row">
        {category == 1 && isValueSet([carName]) ? (
          financingCalculation ? (
            <OfferPreviewWithOfferAndFinancing />
          ) : (
            <OfferPreviewWithOfferWithoutFinancing />
          )
        ) : (
          <OfferPreviewNoOffer />
        )}
      </div>
      <div className="row pt-3 justify-content-between bottom-row">
        <div className="col-auto">
          <button className="scb_button" onClick={navigateToPreviousStep}>
            Zurück
          </button>
        </div>
        <div
          className={readonly ? "col d-flex justify-content-end" : "col-auto"}
        >
          <button className="scb_button" onClick={downloadOffer}>
            Download
          </button>
        </div>
        {!readonly && (
          <div className="col-auto">
            <button className="scb_button" onClick={submitForm}>
              Speichern
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferPreviewStep;
