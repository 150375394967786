import React from "react";
import {NumericFormat} from "react-number-format";

const AmountEditField = ({
  id,
  prefix,
  postfix,
  value,
  placeholder,
  maxLength,
  decimalPlaces = 2,
  onChange,
  disabled,
  fixedDecimalScale,
  onBlur,
  onFocus,
  validationErrors = [],
}) => {
  const localValueChanged = (values) => {
    if (onChange) onChange(values.floatValue);
  };
  return (
    <div className="amount-field">
      <div
        className={`row ${validationErrors.length > 0 && "validation-error"}`}
      >
        {prefix && (
          <div className="col-auto">
            <span className="prefix">{prefix}</span>
          </div>
        )}
        <div className="col">
          <NumericFormat
            id={id}
            onBlur={onBlur}
            onFocus={onFocus}
            thousandSeparator={"."}
            decimalSeparator={","}
            allowNegative="false"
            decimalScale={decimalPlaces}
            defaultValue={0}
            maxLength={maxLength}
            className={
              prefix
                ? postfix
                  ? `myfield-prefix-postfix`
                  : `myfield-prefix`
                : postfix
                ? "myfield-postfix"
                : "myfield"
            }
            onValueChange={(values) => localValueChanged(values)}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            fixedDecimalScale={fixedDecimalScale}
          />
        </div>
        {postfix && (
          <div className="col-auto">
            <span className="postfix">{postfix}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AmountEditField;
