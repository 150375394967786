import React, { useEffect, useContext } from "react";
import EditDealerDataPanel from "../../dealerdata/EditDealerDataPanel";
import { loadDealerData } from "../../backend/DealerService";
import useScbFormField from "../../utils/form/ScbFormFieldHook";
import { LoginContext } from "../../DataStore";

const DealerDataStep = ({
  navigateToNextStep,
  navigateToPreviousStep,
}) => {
  
  const { selectedDealer } = useContext(LoginContext);

  const {
    readonly,
    id,
    setDealerNr,
    setDealerName,
    setDealerStreetAndNr,
    setDealerZipCode,
    setDealerCity,
    setDealerEmail,
    setDealerWebPage,
    setDealerTelephoneNr,
    setDealerLogo,
    setDealerLogoPath,
  } = useScbFormField(
    "readonly",
    "id",
    "dealerNr",
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo",
    "dealerLogoPath"
  );

  const loadDealer = async () => {
    if (selectedDealer && !id) {
      const dealer = await loadDealerData(selectedDealer);
      setDealerNr(dealer.number);
      setDealerStreetAndNr(dealer.streetAndNr);
      setDealerZipCode(dealer.zipCode);
      setDealerCity(dealer.city);
      setDealerEmail(dealer.email);
      setDealerWebPage(dealer.webPage);
      setDealerTelephoneNr(dealer.telephoneNr);
      setDealerLogo(dealer.logo);
      setDealerLogoPath(dealer.logoPath);
      setDealerName(dealer.name);
    }
  };

  useEffect(()=>{
    loadDealer()
  },[selectedDealer])
  
  return (
    <div className="pl-3">
      <div
        className="row no-gutters pt-2 pb-2"
        style={{ color: "gray", fontSize: "10px" }}
      >
        Ihre hier durchgeführten Änderungen werden individuell für dieses
        Angebot in das Kundenmailing eingedruckt. Ihre Autohaus-Stammdaten
        werden dadurch nicht verändert. Klicken Sie auf „Meine Daten“ im Menü am
        oberen Bildschirmrand, um die voreingestellten Daten Ihres Autohauses
        dauerhaft zu ändern.
      </div>
      <EditDealerDataPanel
        readonly={readonly}
      />
      <div className="row pt-3 bottom-row">
        <div className="col">
          <button
            type="submit"
            className="scb_button"
            onClick={navigateToPreviousStep}
          >
            Zurück
          </button>
        </div>
        <div className="col"></div>
        <div className="col-auto">
          <button
            type="submit"
            className="scb_button"
            onClick={navigateToNextStep}
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default DealerDataStep;
