import React from "react";
import ContractListTableRow from "./ContractListTableRow";

const ContractRows = ({
  contractsForPage,
  contractsForDownload,
  handleDownloadChange,
  archive
}) => {
  return contractsForPage.length > 0 ? (
    <tbody className="table-hover">
      {contractsForPage.map((value, index) => (
        <ContractListTableRow
          contract={value}
          key={index}
          contractsForDownload={contractsForDownload}
          handleSelectForDownload={handleDownloadChange}
          archive={archive}
        />
      ))}
    </tbody>
  ) : (
    <tbody className="table-hover">
      <tr className="table_row">
        <td colSpan="7">
          <p className="pt-3">
            Die Suche war leider erfolglos. Bitte kontrollieren Sie Ihre
            Eingabe. Überprüfen Sie auch das Archiv nach bereits abgespeicherten
            Geschäftsfällen.
          </p>
        </td>
      </tr>
    </tbody>
  );
};

export default ContractRows;
