import React, { useContext } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import "../css/navigation.css";
import logo from "../img/logo.png";
import { LoginContext } from "../../DataStore";
import StyledButton from "../../utils/components/StyledButton";
import DealersNavItem from "./DealersNavItem";

const NavigationMenu = ({ isAdmin }) => {

  const history = useHistory();

  const {
    loginSession,
    setLoginSession,
    setSelectedDealer,
   } = useContext(LoginContext);

  return (
    <div className="row no-gutters navigation_bar">
      <div className="col-auto pl-3 pr-4 ">
        <img className="scb-logo pb-1 pt-1" src={logo} alt="" />
      </div>
      <NavigationItem
        label="Vertragsende"
        linkTo="/vertragsende/uebersicht"
        activeLocations={["/vertragsende/uebersicht", "/vertragsende/archiv"]}
      />
      <NavigationItem
        label="Aktionen"
        linkTo="/aktionen/uebersicht"
        activeLocations={["/aktionen/uebersicht", "/aktionen/archiv"]}
      />
      <NavigationItem
        label="Meine Daten"
        linkTo="/meinedaten"
        activeLocations={["/meinedaten"]}
      />
      <NavigationItem
        label="Hilfe"
        linkTo="/hilfe"
        activeLocations={["/hilfe"]}
      />
      <NavigationItem
        label="Kontakt"
        linkTo="/kontakt"
        activeLocations={["/kontakt"]}
      />
      {isAdmin ? (
        <NavigationItem
          label="Administration"
          linkTo="/admin"
          activeLocations={["/admin"]}
        />
      ) : (
        ""
      )}
      <div className="col justify-content-end">
        <DealersNavItem />
      </div>
      <div className="col-auto pt-3 pl-3 justify-content-end">
        <StyledButton
          onClick={(e) => {
            history.replace("/")
            setLoginSession(null);
            setSelectedDealer(null);
            loginSession.logout();
          }}
          label="Logout"
        />
      </div>
    </div>
  );
};

const NavigationItem = ({ label, linkTo, activeLocations }) => {
  return (
    <div
      className="col-auto"
      style={{ paddingLeft: "7px", paddingRight: "7px" }}
    >
      <NavLink
        exact
        isActive={(match, location) => {
          return activeLocations.includes(location.pathname);
        }}
        activeClassName="nav-item-active"
        className="navigation_item"
        to={linkTo}
      >
        {label}
      </NavLink>
    </div>
  );
};

export default withRouter(NavigationMenu);
