import React, { useContext, useEffect } from "react";
import EditDealerDataPanel from "./EditDealerDataPanel";
import "./css/dealerdata_frame.css";
import { saveDealerData, loadDealerData } from "../backend/DealerService.js";
import { LoginContext } from "../DataStore";
import { withScbForm } from "../utils/form/ScbFormProvider";
import useScbForm from "../utils/form/ScbFormHook";
import useScbFormField from "../utils/form/ScbFormFieldHook";

const DealerDataFrame = () => {
  const { selectedDealer } = useContext(LoginContext);
  const { setBlocking, addFormError } = useScbForm();
  const {
    setDealerNr,
    setDealerName,
    setDealerStreetAndNr,
    setDealerZipCode,
    setDealerCity,
    setDealerEmail,
    setDealerWebPage,
    setDealerTelephoneNr,
    setDealerLogo,
    setDealerLogoPath,
  } = useScbFormField(
    "dealerNr",
    "dealerName",
    "dealerStreetAndNr",
    "dealerZipCode",
    "dealerCity",
    "dealerEmail",
    "dealerWebPage",
    "dealerTelephoneNr",
    "dealerLogo",
    "dealerLogoPath"
  );

  const { configureForm, submitForm, setSuccessLabel } = useScbForm(
    "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal."
  );

  const handleSaveDealerData = async (formData) => {
    setBlocking(true);
    try {
      const dealer = {
        number: formData.dealerNr,
        name: formData.dealerName,
        streetAndNr: formData.dealerStreetAndNr,
        zipCode: formData.dealerZipCode,
        city: formData.dealerCity,
        email: formData.dealerEmail,
        webPage: formData.dealerWebPage,
        telephoneNr: formData.dealerTelephoneNr,
        logo: formData.dealerLogo,
        logoPath: formData.dealerLogoPath,
      };
      await saveDealerData(dealer);
    } catch (err) {
      addFormError("Systemfehler. Bitte versuchen Sie später noch einmal.");
    } finally {
      setBlocking(false);
    }
  };

  const loadDealer = async () => {
    setBlocking(true);
    try {
      if (selectedDealer) {
        const dealer = await loadDealerData(selectedDealer);
        setDealerNr(dealer.number);
        setDealerName(dealer.name);
        setDealerStreetAndNr(dealer.streetAndNr);
        setDealerZipCode(dealer.zipCode);
        setDealerCity(dealer.city);
        setDealerEmail(dealer.email);
        setDealerWebPage(dealer.webPage);
        setDealerTelephoneNr(dealer.telephoneNr);
        setDealerLogo(dealer.logo);
        setDealerLogoPath(dealer.logoPath);
      }
    } catch (err) {
      addFormError("Systemfehler. Bitte versuchen Sie später noch einmal.");
    } finally {
      setBlocking(false);
    }
  };

  useEffect(() => {
    configureForm(handleSaveDealerData);
    setSuccessLabel("Daten erfolgreich gespeichert");
    loadDealer();
  }, [selectedDealer]);

  return (
    <div>
      <div className="row no-gutters pt-4 title">
        <h4>Meine Daten</h4>
      </div>
      <EditDealerDataPanel />
      <div className="row justify-content-start mt-4">
        <div className="col-4">
          <button type="submit" className="scb_button" onClick={submitForm}>
            Speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default withScbForm(DealerDataFrame);
